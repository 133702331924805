import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Extra } from '../../../graphql/types'
import { ExtraItem } from '../sectionExtrasAshore/ExtrasAshore'

export default function ExtrasOnboard({ isInbound = false, extras }: { isInbound?: boolean; extras: Extra[] }) {
  const { t } = useTranslation()

  const departure = useMemo(
    () => (isInbound ? t('component.extras.common.arrival') : t('component.extras.common.departure')),
    [isInbound, t],
  )

  if (extras === undefined || extras.length === 0) {
    return null
  }

  return (
    <article data-cy="extras-onboard">
      <h4 className='font-semibold tracking-wide'>{departure}</h4>

      <ul className='flex flex-col gap-5 mt-5'>
        {extras.map((extra, index) => {
          const code = extra.code
          const subCode = extra.subCode
          const keyIndex = index
          if (!code) return null

          return <ExtraItem key={keyIndex} code={code} subCode={subCode || undefined} quantity={extra.quantity} />
        })}
      </ul>
    </article>
  )
}
