import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fareCodes, NATID_Nationality } from '@fjordline/booking-draft'
import {
  AlertBanner,
  Button,
  Check,
  Column,
  Feedback,
  FormActionsWrapper,
  FormElementWrapper,
  Label,
  NationalitySelect,
  Row,
  Select,
  Spinner,
  Switch,
} from '@fjordline/styles-v3'
import Input from '@fjordline/styles-v3/dist/components/Input'
import { useFormik } from 'formik'
import i18n from 'i18next'
import * as yup from 'yup'

import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { Passenger } from '../../../../graphql/types'
import useCountryMember, { nordicCountryCodes } from '../../../../hooks/CountryMember'
import { getErrorMessage, getInputState } from '../../../fjordClub/editMemberShip/functions'
import { useGenderOptions } from '../../../../hooks/useGenderOptions'

import useCreateDynamicDates from './editPassengerForm/birthdayLists/useCreateDynamicDates'
import { editPassengerSchema } from './editPassengerForm/editPassengerSchema'
import NotEditableBanner from './editPassengerForm/NotEditableBanner'
import { IEditPassenger } from './edit.traveler.types'
import UpdatePassengers from './UpdatePassengers'
import useFetchProductCodeInfo from '../../../../sanity/productCodes/useFetchProductCodeInfo'

interface PassengerProps {
  bookingCode: string | undefined
  isInFuture?: boolean
  passenger?: Passenger
}

const EditPassengerForm: React.FC<PassengerProps> = ({ bookingCode, isInFuture = false, passenger }) => {
  const { t } = useTranslation()
  const currentLocale = i18n.language
  const [submitFailed, setSubmitFailed] = useState(false)
  const { flBooking, flBookingLoading } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const existingPassengers = flBooking?.outbound?.passengers?.filter((e) => passenger?.rowNumber !== e.rowNumber) || []
  const { updatePassengers, loadingUpdatePassengers, error, success } = UpdatePassengers()
  const { genders } = useGenderOptions()

  const { data: productInfo, isLoading } = useFetchProductCodeInfo({
    productCode: flBooking?.outbound?.ticket?.ticketCode || '',
  })
  const isPassportMandatory = productInfo?.[0]?.mandatoryPassportId || false

  const [birthMonthError, setBirthMonthError] = useState(false)
  const [birthDayError, setBirthDayError] = useState(false)
  const [birthYearError, setBirthYearError] = useState(false)
  const translations = useMemo(() => {
    return {
      addFailed: t('error.customer.travelFriends.addFailed'),
      allFieldsRequired: t('label.allFieldsRequired'),
      firstName: t('label.personalia.firstName'),
      lastName: t('label.personalia.lastName'),
      dateOfBirth: t('label.personalia.dateOfBirth'),
      F: t('label.gender.F'),
      M: t('label.gender.M'),
      nationality: t('label.address.nationality'),
      passportNumber: t('label.traveler.passportNumber'),
      failure: t('error.notify.failure'),
      saveChanges: t('label.button.saveChanges'),
      cancel: t('label.button.cancel'),
    }
  }, [t])

  const onSubmit = (values: IEditPassenger, actions) => {
    if (birthDayError || birthMonthError || birthYearError) return

    const payload = [
      ...existingPassengers,
      {
        ...values,
      },
    ]

    if (!birthDayError && !birthMonthError && !birthYearError) {
      actions.validateForm()
      actions.setSubmitting(false)
      updatePassengers(payload)
    }
  }

  const editPassenger = useMemo<IEditPassenger>(() => {
    return {
      birthYear: passenger?.birthYear || 1980,
      birthMonth: passenger?.birthMonth || 0,
      birthDay: passenger?.birthDay || 1,
      customerCode: passenger?.customerCode || null,
      disabledPerson: passenger?.disabledPerson || false,
      firstName: passenger?.firstName || '',
      fareCode: passenger?.fareCode || '',
      gender: passenger?.gender || 'FEMALE',
      id: {
        idType: passenger?.id?.idType || 'PASSPORT',
        idNumber: passenger?.id?.idNumber || '',
      },
      lastName: passenger?.lastName || '',
      nationality: passenger?.nationality || '',
      rowNumber: passenger?.rowNumber || 0,
    }
  }, [passenger])

  const passengerValidationSchema = useMemo<yup.Schema<IEditPassenger>>(() => editPassengerSchema(t), [t])

  const { values, handleSubmit, errors, touched, isSubmitting, handleBlur, handleChange, setValues } =
    useFormik<IEditPassenger>({
      initialValues: { ...editPassenger },
      validationSchema: passengerValidationSchema,
      onSubmit,
    })

  useEffect(() => {
    if (flBookingLoading) return
    if (isLoading) return

    if (
      !isPassportMandatory &&
      !nordicCountryCodes.includes(values.nationality.toLowerCase()) &&
      values?.id?.idNumber === 'NORDIC'
    ) {
      setValues({
        ...values,
        id: {
          idType: values?.id?.idType,
          idNumber: '',
        },
      })
    }
  }, [flBookingLoading, isPassportMandatory, setValues, values, isLoading])

  useEffect(() => {
    if (flBookingLoading) return
    if (isLoading) return

    if (
      !isPassportMandatory &&
      nordicCountryCodes.includes(values.nationality.toLowerCase()) &&
      values?.id?.idNumber !== 'NORDIC'
    ) {
      setValues({
        ...values,
        id: {
          idType: values?.id?.idType,
          idNumber: 'NORDIC',
        },
      })
    }
  }, [flBookingLoading, isPassportMandatory, setValues, values, isLoading])

  const { isCountryNordic } = useCountryMember(values.nationality)
  const isMember = useMemo<boolean>(() => passenger?.customerCode?.length !== undefined, [passenger])
  //Checks for nationality is valid for National ID
  const isNATIDValid = useMemo(
    () => Object.prototype.hasOwnProperty.call(NATID_Nationality, values.nationality?.toUpperCase()),
    [values.nationality],
  )

  const idTypes = [
    { id: 'PASSPORT', title: 'Pass' },
    {
      id: 'NATIONAL_ID',
      title: 'National ID',
    },
  ]

  const { dynamicDaysList, dynamicMonthsList, dynamicYearList } = useCreateDynamicDates(
    passenger?.fareCode as unknown as fareCodes,
    values,
  )

  useEffect(() => {
    if (flBookingLoading) return

    if (values.birthMonth > dynamicMonthsList[dynamicMonthsList.length - 1]?.id) {
      setBirthMonthError(true)
    } else if (values.birthMonth < dynamicMonthsList[0]?.id) {
      setBirthMonthError(true)
    }

    if (
      birthMonthError &&
      values.birthMonth < dynamicMonthsList[dynamicMonthsList.length - 1]?.id &&
      values.birthMonth > dynamicMonthsList[0]?.id
    ) {
      setBirthMonthError(false)
    }

    if (values.birthDay > dynamicDaysList[dynamicDaysList.length - 1]?.id) {
      setBirthDayError(true)
    } else if (values.birthDay < dynamicDaysList[0]?.id) {
      setBirthDayError(true)
    }

    if (values.birthYear > dynamicYearList[dynamicYearList.length - 1]?.id) {
      setBirthYearError(true)
    } else if (values.birthYear < dynamicYearList[0]?.id) {
      setBirthYearError(true)
    }
  }, [
    birthMonthError,
    dynamicDaysList,
    dynamicMonthsList,
    dynamicYearList,
    flBookingLoading,
    values.birthDay,
    values.birthMonth,
    values.birthYear,
  ])

  useEffect(() => {
    if (
      !flBookingLoading &&
      values.firstName === '' &&
      values.lastName === '' &&
      values.birthYear === 1980 &&
      values.nationality === '' &&
      values.fareCode === ''
    ) {
      setValues(editPassenger)
    }
  }, [
    editPassenger,
    flBookingLoading,
    setValues,
    values.birthYear,
    values.fareCode,
    values.firstName,
    values.lastName,
    values.nationality,
  ])

  return (
    <>
      {submitFailed && (
        <FormElementWrapper>
          <AlertBanner type="error" onClose={() => setSubmitFailed(false)}>
            {translations.addFailed}
          </AlertBanner>
        </FormElementWrapper>
      )}
      {!isInFuture ||
        (!isMember && (
          <FormElementWrapper>
            <Label size="medium" label={translations.allFieldsRequired}>
              {}
            </Label>
          </FormElementWrapper>
        ))}
      <form onSubmit={handleSubmit} autoComplete="off">
        <FormElementWrapper>
          <NotEditableBanner isVisible={isMember} />
          <Input
            data-cy="edit-passenger-firstName-input"
            disabled={!isInFuture || isMember || isSubmitting}
            errorMessage={getErrorMessage({ error: errors.firstName, touched: touched.firstName })}
            id="firstName"
            label={translations.firstName}
            onChange={handleChange}
            placeholder={translations.firstName}
            readOnly={!isInFuture || isMember}
            state={getInputState({ error: errors.firstName, touched: touched.firstName })}
            value={values.firstName}
          />
        </FormElementWrapper>
        <FormElementWrapper>
          <Input
            data-cy="edit-passenger-lastName-input"
            disabled={!isInFuture || isMember || isSubmitting}
            errorMessage={getErrorMessage({ error: errors.lastName, touched: touched.lastName })}
            id="lastName"
            label={translations.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={translations.lastName}
            readOnly={!isInFuture || isMember}
            state={getInputState({ error: errors.lastName, touched: touched.lastName })}
            value={values.lastName}
          />
        </FormElementWrapper>
        <FormElementWrapper>
          <Row>
            <Column large={4} medium={4} small={12}>
              <Select
                disabled={!isInFuture || isMember || isSubmitting}
                data-cy="select-birthYear"
                options={dynamicYearList}
                name="birthYear"
                id="birthYear"
                errorMessage={getErrorMessage({ error: errors.birthYear, touched: touched.birthYear })}
                value={values.birthYear}
                optionTitleFn={(option) => option.title}
                state={getInputState({ error: errors.birthYear, touched: touched.birthYear })}
                optionValueFn={(option) => option?.id}
                onChange={(e) => {
                  if (parseInt(e.target.value) > 0)
                    setValues({
                      ...values,
                      birthYear: parseInt(e.target.value),
                    })
                  setBirthYearError(false)
                }}
                label={t('label.date.year')}
                placeholder={t('label.date.year')}
              />
              {birthYearError ? (
                <Feedback variant="notification" type="error" showIcon={false}>
                  {t('validation.isRequired')}{' '}
                </Feedback>
              ) : null}
            </Column>
            <Column large={4} medium={4} small={12}>
              <Select
                disabled={!isInFuture || isMember || isSubmitting}
                data-cy="select-birthMonth"
                options={dynamicMonthsList}
                value={values.birthMonth}
                errorMessage={getErrorMessage({ error: errors.birthMonth, touched: touched.birthMonth })}
                state={getInputState({ error: errors.birthMonth, touched: touched.birthMonth })}
                name="birthMonth"
                id="birthMonth"
                optionTitleFn={(option) => option.title}
                optionValueFn={(option) => option?.id}
                onChange={(e) => {
                  if (parseInt(e.target.value) > 0)
                    setValues({
                      ...values,
                      birthMonth: parseInt(e.target.value),
                    })
                  setBirthMonthError(false)
                }}
                label={t('label.date.month')}
                placeholder={t('label.date.month')}
              />
              {birthMonthError ? (
                <Feedback variant="notification" type="error" showIcon={false}>
                  {t('validation.isRequired')}{' '}
                </Feedback>
              ) : null}
            </Column>
            <Column large={4} medium={4} small={12}>
              <Select
                disabled={!isInFuture || isMember || isSubmitting}
                data-cy="select-birthDay"
                options={dynamicDaysList}
                value={values.birthDay}
                errorMessage={getErrorMessage({ error: errors.birthDay, touched: touched.birthDay })}
                state={getInputState({ error: errors.birthDay, touched: touched.birthDay })}
                name="birthDay"
                id="birthDay"
                optionTitleFn={(option) => option.title}
                optionValueFn={(option) => option?.id}
                label={t('label.date.day')}
                onChange={(e) => {
                  if (parseInt(e.target.value) > 0)
                    setValues({
                      ...values,
                      birthDay: parseInt(e.target.value),
                    })
                  setBirthDayError(false)
                }}
                placeholder={t('label.date.day')}
              />
              {birthDayError ? (
                <Feedback variant="notification" type="error" showIcon={false}>
                  {t('validation.isRequired')}{' '}
                </Feedback>
              ) : null}
            </Column>
          </Row>
        </FormElementWrapper>
        <FormElementWrapper>
          <Select
            disabled={!isInFuture || isMember || isSubmitting}
            options={genders}
            optionTitleFn={(option) => option.label}
            optionValueFn={(option) => option.value}
            value={values.gender}
            onChange={(e) => {
              setValues({
                ...values,
                gender: e.target.value,
              })
            }}
            data-cy="select-gender"
            name="gender"
            label={t('label.personalia.gender')}
          />
        </FormElementWrapper>

        <FormElementWrapper>
          <NationalitySelect
            disabled={!isInFuture || isMember || isSubmitting}
            errorMessage={getErrorMessage({ error: errors.nationality, touched: touched.nationality })}
            label={t('label.address.nationality')}
            onChange={(e) => {
              setValues({
                ...values,
                nationality: e.target.value,
              })
            }}
            placeholder={t('placeholder.address.nationality')}
            required
            locale={currentLocale as 'en' | 'da' | 'de' | 'nb' | 'nl'}
            value={values.nationality.toUpperCase() || ''}
          />
        </FormElementWrapper>
        {isCountryNordic && !isPassportMandatory ? null : (
          <FormElementWrapper>
            <Row>
              <Column large={3} medium={3} small={12}>
                {' '}
                <Select
                  label={'Id type'}
                  options={isNATIDValid ? idTypes : idTypes.filter((e) => e?.id !== 'NATIONAL_ID')}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      id: {
                        idNumber: values?.id.idNumber,
                        idType: e.target.value,
                      },
                    })
                  }}
                  required
                  name="idType"
                  optionValueFn={(option) => option?.id}
                  optionTitleFn={(option) => option?.title}
                  value={values?.id?.idType}
                  data-cy="select-idType"
                />
              </Column>
              <Column large={9} medium={9} small={12}>
                {' '}
                <Input
                  disabled={isSubmitting}
                  errorMessage={getErrorMessage({ error: errors?.id?.idNumber, touched: touched?.id?.idNumber })}
                  id="passportNumber"
                  label={translations.passportNumber}
                  onChange={(e) => {
                    setValues({
                      ...values,
                      id: {
                        idType: values?.id.idType,
                        idNumber: e.target.value,
                      },
                    })
                  }}
                  placeholder={translations.passportNumber}
                  required={!isCountryNordic}
                  value={values?.id.idNumber}
                  readOnly={!isInFuture}
                  data-cy="edit-passenger-passportNumber-input"
                />
              </Column>
            </Row>
          </FormElementWrapper>
        )}

        <FormElementWrapper>
          <Switch
            disabled={!isInFuture || isSubmitting}
            checked={values.disabledPerson}
            label={t('label.traveler.disabledPerson')}
            name="disabledPerson"
            onChange={() => setValues({ ...values, disabledPerson: !values.disabledPerson })}
            data-cy="edit-passenger-disabledPerson-switch"
          />
        </FormElementWrapper>

        <FormActionsWrapper>
          {isInFuture ? (
            <Button
              type="submit"
              size="medium"
              disabled={isSubmitting || loadingUpdatePassengers || birthDayError || birthMonthError || birthYearError}
              icon={isSubmitting || loadingUpdatePassengers ? Spinner : Check}
              dataCy="edit-passenger-save-button"
            >
              {translations.saveChanges}
            </Button>
          ) : null}
        </FormActionsWrapper>
      </form>
      {success ? (
        <Feedback type="success" variant="notification">
          {t('component.editPassengers.updated')}
        </Feedback>
      ) : null}
      {error ? (
        <Feedback type="error" variant="notification">
          {' '}
          {t('component.editVehicles.error')}
        </Feedback>
      ) : null}
    </>
  )
}

export default EditPassengerForm
