import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Button,
  Check,
  Feedback,
  FormActionsWrapper,
  FormElementWrapper,
  Input,
  Spinner,
} from '@fjordline/styles-v3'

import CustomBackButton from '../../../components/CustomBackbutton'
import { BookingNavLink } from '../../../routes/navLinkFunctions'

import UpdateVehicles from './editVehicle/UpdateVehicles'
import { useGetBookingQuery } from '../../../graphql'

const EditVehicle: React.FC = () => {
  useMemo(() => window.scrollTo(0, 0), [])

  const { bookingCode, rowNumber } = useParams()
  const { t } = useTranslation()
  const [hideErrorOrSuccessOnChange, setHideErrorOrSuccessOnChange] = React.useState<boolean>(false)
  const { data: flBooking, loading: loadingBooking } = useGetBookingQuery({
    variables: {
      bookingCode: bookingCode || '',
    },
  })

  const { updateVehicles, loadingUpdateVehicles, error, success } = UpdateVehicles()

  const loading = loadingBooking || loadingUpdateVehicles

  const outboundVehiclesWithId = flBooking?.booking?.outbound?.vehicles?.map((e, index) => {
    return {
      ...e,
      id:
        e.regNum && e.regNum.length > 0 && e.regNum !== 'TBA'
          ? e.regNum
          : `${e.code}-${e.hasTrailer}-${e.length}-${e.regNum}-${index}`,
    }
  })

  const inboundVehiclesWithId = flBooking?.booking?.inbound?.vehicles?.map((e, index) => {
    return {
      ...e,
      id:
        e.regNum && e.regNum.length > 0 && e.regNum !== 'TBA'
          ? e.regNum
          : `${e.code}-${e.hasTrailer}-${e.length}-${e.regNum}-${index}`,
    }
  })

  const allVehiclesOutbound =
    flBooking && outboundVehiclesWithId
      ? (outboundVehiclesWithId?.filter((veh) => veh.rowNumber.toString() !== rowNumber) ?? [])
      : []

  const outboundVehicle = useMemo(
    () => outboundVehiclesWithId?.find((e) => e.rowNumber?.toString() === rowNumber),
    [outboundVehiclesWithId, rowNumber],
  )

  const allVehiclesInbound = flBooking?.booking
    ? (inboundVehiclesWithId?.filter((e) => e.id !== outboundVehicle?.id) ?? [])
    : []

  const inboundVehicle = useMemo(
    () => inboundVehiclesWithId?.find((ibVehicle) => ibVehicle.id === outboundVehicle?.id),
    [inboundVehiclesWithId, outboundVehicle?.id],
  )

  useEffect(() => {
    setRegNum(outboundVehicle?.regNum as string)
  }, [outboundVehicle?.regNum])
  const [regNum, setRegNum] = React.useState<string>(outboundVehicle?.regNum as string)
  const vehicleWithNewOutbound = {
    ...outboundVehicle,
    regNum: regNum,
  }

  const vehicleWithNewInbound = {
    ...inboundVehicle,
    regNum: regNum,
  }

  const payloadOutbound = [...allVehiclesOutbound, vehicleWithNewOutbound]
  const payloadInbound = [...allVehiclesInbound, vehicleWithNewInbound]

  const payload = {
    outboundVehicles: payloadOutbound,
    inboundVehicles: payloadInbound,
  }

  useEffect(() => {
    if (regNum === 'TBA') {
      setRegNum('')
    }
  }, [regNum])

  const isAlphaNumeric = /^([0-9A-Za-zÀ-ý]+[- ]?)+$/

  useEffect(() => {
    if (hideErrorOrSuccessOnChange === true && loading) {
      setHideErrorOrSuccessOnChange(false)
    }
  }, [hideErrorOrSuccessOnChange, loading])

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>
      <h1> {t('label.vehicle.edit')}</h1>
      {outboundVehicle ? (
        <Input
          data-cy="edit-vehicle-regnum-input"
          style={{ marginBottom: '1rem' }}
          onChange={(e) => {
            setRegNum(e.target.value)
            setHideErrorOrSuccessOnChange(true)
          }}
          label={t('label.vehicle.regNum')}
          value={regNum}
          disabled={loadingUpdateVehicles}
        />
      ) : null}{' '}
      <FormActionsWrapper>
        <Button
          dataCy="edit-vehicle-save-button"
          type="submit"
          size="medium"
          icon={loadingUpdateVehicles ? Spinner : Check}
          disabled={loadingUpdateVehicles || regNum?.length === 0 || !isAlphaNumeric.test(regNum)}
          onClick={() => {
            updateVehicles(payload)
          }}
        >
          {loadingUpdateVehicles ? t('error.notify.loading') : t('label.button.save')}
        </Button>
      </FormActionsWrapper>
      {!isAlphaNumeric.test(regNum) ? (
        <Feedback type="error" variant="notification" dataCy="feedback-wrongFormat">
          {t('validation.isRegnumber')}
        </Feedback>
      ) : null}
      {!hideErrorOrSuccessOnChange && success ? (
        <Feedback type="success" variant="notification" dataCy="feedback-success">
          {t('component.editVehicles.updated')}
        </Feedback>
      ) : null}
      {!hideErrorOrSuccessOnChange && error ? (
        <Feedback type="error" variant="notification" dataCy="feedback-error">
          {' '}
          {t('component.editVehicles.error')}
        </Feedback>
      ) : null}
    </div>
  )
}

export default EditVehicle
