import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { currency } from '@fjordline/booking-draft'
import
  {
    Button,
    Container,
    Feedback,
    FormActionsWrapper,
    Modal,
    Paragraph,
    ShoppingCart,
  } from '@fjordline/styles-v3'
import { v4 as uuidv4 } from 'uuid'

import { MealsDetailsFragment, Specification } from '../graphql/types'
import useCartRollback from '../pages/basket/useCartRollback'

import { removeCabinAction } from './availabilityItemsProvider/actions'
import
  {
    AvailabilityItemsOperationsType,
    AvailabilityItemsValuesType,
    cabinToAdd,
    CartsByBookingCodes,
    extrasToAdd,
    mealsToAddType,
  } from './availabilityItemsProvider/types'
import { useWebSocketContext } from './myPageStateProvider/websocketProvider/websocketContext'
import { useWebSocketOperationsContext } from './myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import { ContextChildren } from './genericTypes'
import { extractBookingNumber } from './WebsocketProvider'

export const AvailabilityItemsContext = createContext({})
AvailabilityItemsContext.displayName = ' AvailabilityItemsContext'

export const AvailabilityItemsContextOperations = createContext({})
AvailabilityItemsContextOperations.displayName = ' AvailabilityItemsOperations'

export const useAvailabilityItems = () => useContext(AvailabilityItemsContext) as AvailabilityItemsValuesType
export const useAvailabilityItemsOperations = () =>
  useContext(AvailabilityItemsContextOperations) as AvailabilityItemsOperationsType

const AvailabilityItemsProvider: React.FunctionComponent<React.PropsWithChildren<ContextChildren>> = ({
  children,
}: React.PropsWithChildren<ContextChildren>) =>
{
  const [ addToCartState, setAddToCartState ] = useState<CartsByBookingCodes>(
    JSON.parse(sessionStorage.getItem('AddToCartState') || '{}'),
  )
  const [ showModal, setShowModal ] = useState<{ showModal: boolean; isOutbound: boolean }>({
    showModal: false,
    isOutbound: false,
  })
  const { updateCart } = useWebSocketOperationsContext()
  const { cartData, initCart } = useWebSocketContext()
  const location = useLocation()
  const [ addingItems, setAddingItems ] = useState(false)
  const bookingNumber = extractBookingNumber(location)
  const bookingListWithBookingNumberPath = `/bookingList/${bookingNumber}`
  const { t } = useTranslation()
  const [ checkoutLoading, setCheckoutLoading ] = useState(false)
  const [ rollbackLoading, setRollbackLoading ] = useState<boolean>(false)

  const addItemPages = useMemo(() => [ 'addMeals', 'addExtrasOnboard', 'addExtrasAshore', 'cabins' ], [])

  useEffect(() =>
  {
    if (!addItemPages.some((page) => location.pathname.includes(page))) {
      if (
        (!addingItems &&
          cartData &&
          cartData?.bookingCarts &&
          Object.entries(cartData?.bookingCarts).length < Object.entries(addToCartState).length) ||
        (cartData &&
          cartData?.bookingCarts &&
          (location.pathname === '/bookingList' || bookingListWithBookingNumberPath === location.pathname) &&
          !addingItems)
      ) {
        setAddToCartState(cartData?.bookingCarts)
        sessionStorage.setItem('AddToCartState', JSON.stringify(cartData?.bookingCarts))
      }
    }
  }, [ addItemPages, addingItems, bookingListWithBookingNumberPath, cartData, location.pathname, addToCartState ])

  useEffect(() =>
  {
    if (initCart && initCart?.bookingCarts) {
      sessionStorage.setItem('AddToCartState', JSON.stringify(initCart?.bookingCarts))
      setAddToCartState(initCart?.bookingCarts)
    }
  }, [ cartData?.id, initCart ])

  const removeCabin = useCallback(
    (addToCartState, id, isOutbound, bookingCode) =>
    {
      return removeCabinAction({ addToCartState, id, isOutbound, bookingCode, cartData }, setAddToCartState, updateCart)
    },
    [ cartData, updateCart ],
  )

  const resetCabins = useCallback(
    (isOutbound: boolean, bookingCode: string) =>
    {
      setAddingItems(true)
      const updatedState: CartsByBookingCodes = {
        ...addToCartState,
        [ bookingCode ]: {
          ...addToCartState[ bookingCode ],
          [ isOutbound ? 'outbound' : 'inbound' ]: {
            ...addToCartState[ bookingCode ]?.[ isOutbound ? 'outbound' : 'inbound' ],
            cabins: [],
          },
        },
      }

      setAddToCartState(updatedState)
      sessionStorage.setItem('AddToCartState', JSON.stringify(updatedState))

      updateCart({
        id: cartData?.id ?? uuidv4(),
        timestamp: new Date().getTime(),
        bookingCarts: {
          ...updatedState,
        },
      })
      setTimeout(() =>
      {
        setAddingItems(false)
      }, 200)
    },
    [ addToCartState, cartData, updateCart ],
  )

  //Add cabin to addToCartState
  const addCabinsToCart = useCallback(
    (item, value: number, isOutbound: boolean, bookingCode: string) =>
    {
      setAddingItems(true)
      const toAdd: cabinToAdd[] = isOutbound
        ? addToCartState[ bookingCode ]?.outbound?.cabins || []
        : addToCartState[ bookingCode ]?.inbound?.cabins || []
      const existingItemIndex = toAdd.findIndex((cabin) => cabin?.id === item?.id)
      if (existingItemIndex !== -1) {
        if (value === 0) {
          toAdd.splice(existingItemIndex, 1)
        } else {
          toAdd[ existingItemIndex ].quantityInCabin = value
        }
      } else {
        toAdd.push({
          code: item.code || '',
          quantityInCabin: value,
          rowNumber: value <= 0 ? item.rowNumber : undefined,
          groupCode: value <= 0 ? item.groupCode : undefined,
          price: {
            value:
              value <= 0
                ? -item.price
                : item.specification?.options.find((e) => e.maxQuantity >= value)?.optionPrice?.value || 0,
            currency: item.price?.currency || item.specification?.options?.[ 0 ]?.optionPrice.currency || currency.NOK,
            available: item.price?.available || item.specification?.options?.[ 0 ]?.optionPrice.available,
          },

          id: uuidv4(),
          subCode: 'P',
        })
      }

      const updatedState: CartsByBookingCodes = {
        ...addToCartState,
        [ bookingCode ]: {
          ...addToCartState[ bookingCode ],
          [ isOutbound ? 'outbound' : 'inbound' ]: {
            ...addToCartState[ bookingCode ]?.[ isOutbound ? 'outbound' : 'inbound' ],
            cabins: [ ...toAdd ],
          },
        },
      }

      setAddToCartState(updatedState)
      sessionStorage.setItem('AddToCartState', JSON.stringify(updatedState))

      setShowModal({ showModal: false, isOutbound })
      updateCart({
        id: cartData?.id ?? uuidv4(),
        timestamp: new Date().getTime(),
        bookingCarts: {
          ...updatedState,
        },
      })
      setTimeout(() =>
      {
        setAddingItems(false)
      }, 200)
    },
    [ addToCartState, cartData, updateCart ],
  )

  //Add meals to addToCartState
  const addMealsToCart = useCallback(
    (meal: MealsDetailsFragment, value: number, spec: Specification, bookingCode: string, isOutbound: boolean) =>
    {
      setAddingItems(true)
      const mealsToAdd: mealsToAddType[] = isOutbound
        ? addToCartState[ bookingCode ]?.outbound?.meals || []
        : addToCartState[ bookingCode ]?.inbound?.meals || []

      const existingMealIndex = mealsToAdd.findIndex(
        (m) =>
          m.code === meal.code &&
          m.subCode === spec.specificationCode &&
          new Date(m.startTime).getTime() === new Date(meal.startTime as string).getTime(),
      )

      if (existingMealIndex !== -1) {
        // Meal already exists
        if (value === 0) {
          //remove with filter
          mealsToAdd.splice(existingMealIndex, 1)
        } else {
          // Update the quantity
          mealsToAdd[ existingMealIndex ].quantity = value
        }
      } else {
        // Meal doesn't exist, add a new meal
        mealsToAdd.push({
          code: meal.code,
          legCode: meal.legCode,
          quantity: value,
          startTime: new Date(meal.startTime as string),
          subCode: spec.specificationCode || '',
          itemType: meal.itemType || '',
          hidePrice: spec.hidePrice || false,
          price: {
            value: spec.specificationPrice.value || 0,
            currency: spec.specificationPrice.currency || '',
            available: spec.specificationPrice.available || false,
          },
        })
      }

      const updatedState: CartsByBookingCodes = {
        ...addToCartState,
        [ bookingCode ]: {
          ...addToCartState[ bookingCode ],
          [ isOutbound ? 'outbound' : 'inbound' ]: {
            ...addToCartState[ bookingCode ]?.[ isOutbound ? 'outbound' : 'inbound' ],

            meals: mealsToAdd.filter((e) => e.quantity > 0),
          },
        },
      }

      setAddToCartState(updatedState)

      sessionStorage.setItem('AddToCartState', JSON.stringify(updatedState))

      updateCart({
        id: cartData?.id ?? uuidv4(),
        timestamp: new Date().getTime(),
        bookingCarts: {
          ...updatedState,
        },
      })
      setTimeout(() =>
      {
        setAddingItems(false)
      }, 200)
    },
    [ addToCartState, cartData, updateCart ],
  )

  //Add extras to addToCartState
  const addExtrasToCart = useCallback(
    (item, value: number, spec: Specification, bookingCode: string, isOutbound: boolean, isOnboard: boolean) =>
    {
      setAddingItems(true)
      let toAdd: extrasToAdd[] = []

      const key = isOnboard ? 'extrasOnboard' : 'extrasAshore'

      if (isOnboard) {
        toAdd = isOutbound
          ? addToCartState[ bookingCode ]?.outbound?.extrasOnboard || []
          : addToCartState[ bookingCode ]?.inbound?.extrasOnboard || []
      } else {
        toAdd = isOutbound
          ? addToCartState[ bookingCode ]?.outbound?.extrasAshore || []
          : addToCartState[ bookingCode ]?.inbound?.extrasAshore || []
      }

      const existingItem = toAdd.findIndex((m) => m.code === item.code && m.subCode === spec.specificationCode)

      if (existingItem !== -1) {
        // Meal already exists
        if (value === 0) {
          //remove with filter
          toAdd.splice(existingItem, 1)
        } else {
          // Update the quantity
          toAdd[ existingItem ].quantity = value
        }
      } else {
        // Meal doesn't exist, add a new item
        toAdd.push({
          code: item.code,
          quantity: value,
          subCode: spec.specificationCode || '',
          hidePrice: spec.hidePrice || false,
          price: {
            value: spec.specificationPrice.value || 0,
            currency: spec.specificationPrice.currency || '',
            available: spec.specificationPrice.available || false,
          },
        })
      }

      const updatedState: CartsByBookingCodes = {
        ...addToCartState,
        [ bookingCode ]: {
          ...addToCartState[ bookingCode ],

          [ isOutbound ? 'outbound' : 'inbound' ]: {
            ...addToCartState[ bookingCode ]?.[ isOutbound ? 'outbound' : 'inbound' ],
            [ key ]: toAdd,
          },
        },
      }
      setAddToCartState(updatedState)
      sessionStorage.setItem('AddToCartState', JSON.stringify(updatedState))

      updateCart({
        id: cartData?.id ?? uuidv4(),
        timestamp: new Date().getTime(),
        bookingCarts: {
          ...updatedState,
        },
      })
      setTimeout(() =>
      {
        setAddingItems(false)
      }, 200)
    },
    [ addToCartState, cartData, updateCart ],
  )

  //Clear
  if (!location.pathname.includes('basket')) {
    sessionStorage.removeItem('sendGTMPurchaseData')
  }
  const [ showUnpaidCartModal, setShowUnpaidCartModal ] = useState(false)
  const { rollbackError } = useCartRollback()
  const navigate = useNavigate()
  // const hasPaymentConfig = cartData?.bookingCarts?.[bookingNumber || '']?.bookingResult?.paymentConfiguration
  //   ? true
  //   : false

  const hasActivePayment =
    Object.entries(addToCartState)
      .map((a) =>
      {
        if (a[ 1 ].bookingResult === undefined) return null
        return {
          bookingCode: a[ 0 ],
          bookingResult: a[ 1 ]?.bookingResult,
        }
      })
      .find((e) => e?.bookingResult !== undefined) || null

  useEffect(() =>
  {
    if (
      bookingNumber &&
      hasActivePayment &&
      location.pathname !== `/bookingList/${bookingNumber}/basket` &&
      location.pathname !== `/bookingList/${bookingNumber}` &&
      addItemPages.some((page) => location.pathname.includes(page))
    ) {
      navigate(`/bookingList/${bookingNumber}/basket`)
      setShowUnpaidCartModal(true)
    }
  }, [ addItemPages, bookingNumber, hasActivePayment, location.pathname, navigate ])

  const AvailabilityItemsValues: AvailabilityItemsValuesType = useMemo(
    () => ({ showModal, addToCartState, checkoutLoading, rollbackLoading, hasActivePayment }),
    [ showModal, addToCartState, checkoutLoading, rollbackLoading, hasActivePayment ],
  )

  const AvailabilityItemsOperations: AvailabilityItemsOperationsType = useMemo(
    () => ({
      setShowModal,
      addCabinsToCart,
      removeCabin,
      addMealsToCart,
      addExtrasToCart,
      resetCabins,
      setAddToCartState,
      setCheckoutLoading,
      setRollbackLoading,
    }),
    [ addCabinsToCart, addExtrasToCart, addMealsToCart, removeCabin, resetCabins ],
  )

  return (
    <AvailabilityItemsContext.Provider value={AvailabilityItemsValues}>
      <AvailabilityItemsContextOperations.Provider value={AvailabilityItemsOperations}>
        {/* <GlobalStyles /> */}
        {hasActivePayment && showUnpaidCartModal ? (
          <Modal
            label=""
            hideHeader
            onRequestClose={() =>
            {
              navigate(`/bookingList/${hasActivePayment.bookingCode}/basket`)
              setShowUnpaidCartModal(false)
            }}
          >
            <Container>
              <h2>{t('component.extras.basket.alreadyReservedInCart')}</h2>
              <Paragraph>
                {t('component.extras.basket.alreadyReservedInCart_description')}{' '}
                <b
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() =>
                  {
                    navigate(`/bookingList/${hasActivePayment.bookingCode}`)
                    setShowUnpaidCartModal(false)
                  }}
                >
                  ({hasActivePayment.bookingCode})
                </b>
              </Paragraph>
              <FormActionsWrapper>
                <Button
                  theme="ghost"
                  leadingIcon={ShoppingCart}
                  disabled={rollbackLoading}
                  onClick={() =>
                  {
                    navigate(`/bookingList/${hasActivePayment.bookingCode}/basket`)
                    setShowUnpaidCartModal(false)
                  }}
                >
                  {t('component.extras.basket.seeCart')}
                </Button>
              </FormActionsWrapper>
              {rollbackError ? (
                <div>
                  <Feedback type="error" variant="notification">
                    {t('error.basket.rollback')}
                  </Feedback>
                </div>
              ) : null}
            </Container>
          </Modal>
        ) : (
          children
        )}
      </AvailabilityItemsContextOperations.Provider>
    </AvailabilityItemsContext.Provider>
  )
}

export default AvailabilityItemsProvider
