import styled from 'styled-components'

export const StyledFieldset = styled.fieldset`
  border: solid 1px #d1d5db;
  border-radius: 5px;
  padding: 0.35em 0.75em 0.625em;
`

export const StyledLegend = styled.legend`
  padding: 0 1rem;
  font-size: 2rem;
  font-weight: 500;

  svg {
    font-size: 3rem;
  }
`

export const SectionTitle = styled.h2`
  padding: 1rem;
  margin: 0;
  font-size: 1.8rem;
  font-weight: 500;
`

export const StyledLink = styled.a`
  color: var(--fl-ocean);
  font-weight: 500;
  text-decoration: underline;
`
