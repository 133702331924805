import React, { JSX } from 'react'

import { Cabin } from '../../../graphql/types'
import useFetchCabins from '../../../sanity/cabins/useFetchCabins'
import { useTranslation } from 'react-i18next'
import UseGetBookingDetails from '../../../graphql/customerHooks/UseGetBookingDetails'
import { extractBookingNumber } from '../../../providers/WebsocketProvider'
import { useLocation } from 'react-router-dom'

type ReducedCabins = {
  [code: string]: {
    code: string
    quantity: number
  }
}

export default function Cabins({ cabins, isOutbound }: { cabins: Cabin[]; isOutbound: boolean }): JSX.Element | null {
  const reducedCabins: ReducedCabins = cabins?.reduce((accumulator: ReducedCabins, cabin: Cabin) => {
    if (accumulator?.[cabin.code || '']) {
      accumulator[cabin.code || ''].quantity += 1
    } else {
      accumulator[cabin.code || ''] = { code: cabin.code || '', quantity: 1 }
    }
    return accumulator
  }, {})

  const result = Object.values(reducedCabins)

  if (cabins.length === 0) {
    return null
  }

  return (
    <ul className='flex flex-col gap-2 mt-2'>
      {result?.map((cabin, index) => {
        const key = index + 1
        return <CabinNames data-cy={`${cabin.code}-${key}`} key={key} cabin={cabin} isOutbound={isOutbound} />
      })}
    </ul>
  )
}

type PropCabinNames = {
  cabin
  isOutbound: boolean
}

const CabinNames: React.FC<PropCabinNames> = ({ cabin, isOutbound }) => {
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const vesselCode = isOutbound ? flBooking?.outbound?.vesselCode : flBooking?.inbound?.vesselCode
  const { cabinsFromSanity } = useFetchCabins(cabin.code, vesselCode || undefined)
  const { i18n } = useTranslation()
  if (!cabin || !cabin.code) {
    return null
  }

  return (
    <li key={cabin.code}>{`${cabin.quantity} x ${cabinsFromSanity?.cabinName?.[i18n.language] || cabin.code}`}</li>
  )
}
