import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Boat,
  Button,
  Checkbox,
  Column,
  Divider,
  Feedback,
  FormElementWrapper,
  Modal,
  Paragraph,
  Row,
  ShoppingCart,
  Spinner,
  Trash,
  TravelInformation,
  useToastDispatcher,
} from '@fjordline/styles-v3'
import styled from 'styled-components'

import CustomBackButton from '../components/CustomBackbutton'
import { ButtonFull } from '../components/globalStyles'
import { ENVIRONMENT } from '../config'
import UseGetBookingDetails from '../graphql/customerHooks/UseGetBookingDetails'
import { useTimeZonedDateFormatter } from '../hooks/DateFormat'
import { BookingNavLink } from '../routes/navLinkFunctions'
import { useAvailabilityItems } from '../providers/AvailabilityItemsProvider'
import { CartsByBookingCodes } from '../providers/availabilityItemsProvider/types'
import { useWebSocketContext } from '../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../providers/WebsocketProvider'

import Cabins from './basket/Cabins'
import ExtrasAshore from './basket/ExtrasAshore'
import ExtrasOnboard from './basket/ExtrasOnboard'
import { hasBasketItems } from './basket/hasBasketItems'
import Meals from './basket/Meals'
import useCartCheckout from './basket/useCartCheckout'
import useCartRollback from './basket/useCartRollback'
import AddExtrasModalButton from './booking/AddExtrasModalButton'
import { findSumTotalBasket } from './bookingList/BookingCards'
import RemoveCabinsContainer from './basket/cabins/RemoveCabinsContainer'
import { AdyenPayment } from './basket/AdyenPayment'
import useQueryParams from './receipt/useQueryParams'
import { BookingResult } from '@fjordline/booking-draft'
import { isPastDeadline } from '../utils/isPastDeadline'
import ModifyBookingDeadlineTimer from '../components/ModifyBooingDeadlineTimer'

const Basket: React.FC = () => {
  useMemo(() => window.scrollTo(0, 0), [])
  const { t, i18n } = useTranslation()
  const { dispatchToast } = useToastDispatcher()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { cartData } = useWebSocketContext()
  const { checkoutCart, checkoutError } = useCartCheckout()
  const { rollbackCart, rollbackError } = useCartRollback()
  const addToCartState: CartsByBookingCodes = useAvailabilityItems().addToCartState
  const { checkoutLoading, rollbackLoading } = useAvailabilityItems()
  const navigate = useNavigate()
  const { queryParams } = useQueryParams()

  const adyenPaymentRefused = queryParams.get('refused') === 'true'

  const sessionStorage_addToCartState = sessionStorage.getItem('AddToCartState')
  const addToCartStateFromSessionStorage = sessionStorage_addToCartState
    ? JSON.parse(sessionStorage_addToCartState)
    : {}
  const addToCartStateFromSessionStorage_bookingCode = addToCartStateFromSessionStorage[bookingCode ?? '']
  const paymentConfigSessionStorage = addToCartStateFromSessionStorage_bookingCode?.bookingResult?.paymentConfiguration

  const hasPaymentConfiguration =
    addToCartState[bookingCode ?? '']?.bookingResult?.paymentConfiguration ||
    cartData?.bookingCarts?.[bookingCode || '']?.bookingResult?.paymentConfiguration ||
    paymentConfigSessionStorage
      ? true
      : false

  const bookingResult: BookingResult | undefined =
    cartData?.bookingCarts?.[bookingCode || '']?.bookingResult ||
    addToCartState[bookingCode || '']?.bookingResult ||
    addToCartStateFromSessionStorage_bookingCode?.bookingResult

  const [termsAccepted, setTermsAccepted] = React.useState(
    sessionStorage.getItem('termsAccepted') === 'true' || hasPaymentConfiguration,
  )
  useEffect(() => {
    if (hasPaymentConfiguration && !termsAccepted) {
      sessionStorage.setItem('termsAccepted', 'true')
      setTermsAccepted(true)
    }
  }, [hasPaymentConfiguration, termsAccepted])

  const d = useTimeZonedDateFormatter()
  const formattedOutboundDate = useMemo(
    () => (flBooking?.outbound?.departureDate ? d(flBooking?.outbound?.departureDate, 'do MMMM yyyy - p') : ''),
    [flBooking?.outbound?.departureDate, d],
  )
  const formattedInboundDate = useMemo(
    () => (flBooking?.inbound?.departureDate ? d(flBooking?.inbound?.departureDate, 'do MMMM yyyy - p') : ''),
    [flBooking?.inbound?.departureDate, d],
  )

  const { hasAnyOutbound, hasAnyInbound } = hasBasketItems({
    addToCartState: addToCartState,
    bookingCode: bookingCode || '',
  })

  const [showRollbackButton, setShowRollbackButton] = React.useState(false)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (hasPaymentConfiguration) {
      timer = setTimeout(() => {
        setShowRollbackButton(true)
      }, 5000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [hasPaymentConfiguration])

  const [checkIfPastDeadline, setCheckIfPastDeadline] = React.useState(false)

  useEffect(() => {
    if (flBooking?.modificationDeadline) {
      setCheckIfPastDeadline(isPastDeadline({ modifyBookingDeadline: flBooking?.modificationDeadline }))
    }
  }, [flBooking?.modificationDeadline]),
    [flBooking?.modificationDeadline]

  //Check if is past deadline for this port from GQL.
  if (checkIfPastDeadline) {
    return (
      <Modal label="" onRequestClose={() => null} hideHeader>
        <div style={{ padding: '2rem' }}>
          <h3>{t('modificationDeadline.deadlineIsPastModal')}</h3>
          <Button onClick={() => navigate('/bookingList/' + bookingCode)}>{t('label.button.bookingOverview')}</Button>
        </div>
      </Modal>
    )
  }
  //If Refused by Adyen
  if (adyenPaymentRefused) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          marginTop: '2rem',
        }}
      >
        <h2> {t('error.basket.refused_headline')}</h2>
        <Feedback type="warning" variant="notification">
          {t('error.basket.refused')}
        </Feedback>
        <a
          rel="noreferrer"
          href={`https://www.fjordline.com/${i18n.language}/p/kundeservice/hjelp-og-kontakt`}
          target="_blank"
        >
          <Paragraph style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{t('error.basket.help')}</Paragraph>
        </a>
        <Button
          style={{ marginTop: '2rem', maxWidth: '200px' }}
          onClick={() => {
            navigate(`/bookingList/${bookingCode}/basket`)
          }}
        >
          {t('component.extras.basket.retry')}
        </Button>
      </div>
    )
  }

  return (
    <div style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>
      <SVGWrapper>
        <ShoppingCart /> <h2>{t('label.booking.basketInfo.yourBasket')}</h2>
      </SVGWrapper>

      <TravelInformation
        borderWrap
        outbound={{
          date: formattedOutboundDate,
          from: flBooking?.outbound?.departurePortInfo?.portName || '',
          to: flBooking?.outbound?.arrivalPortInfo?.portName || '',
        }}
        inbound={
          flBooking?.inbound
            ? {
                date: formattedInboundDate,
                from: flBooking?.inbound?.departurePortInfo?.portName || '',
                to: flBooking?.inbound?.arrivalPortInfo?.portName || '',
              }
            : undefined
        }
      />
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <ModifyBookingDeadlineTimer modifyBookingDeadline={flBooking?.modificationDeadline} />
      </div>
      {hasAnyOutbound ? (
        <>
          {' '}
          <h3 style={{ fontWeight: 'bolder', marginTop: '1rem' }}>
            {t('component.extras.basket.added')} {t('component.boardingCards.departure')}
          </h3>
          <Meals isOutbound={true} />
          <ExtrasOnboard isOutbound={true} />
          <ExtrasAshore isOutbound={true} />
          <Cabins isOutbound={true} />
        </>
      ) : null}

      {hasAnyInbound ? (
        <>
          <h3 style={{ fontWeight: 'bolder', marginTop: '1rem' }}>
            {t('component.extras.basket.added')} {t('component.boardingCards.arrival')}
          </h3>
          <Meals isOutbound={false} />
          <ExtrasAshore isOutbound={false} />
          <ExtrasOnboard isOutbound={false} />
          <Cabins isOutbound={false} />
        </>
      ) : null}

      <RemoveCabinsContainer cartData={cartData} bookingCode={bookingCode || ''} />

      {(flBooking && hasAnyOutbound) || (flBooking && hasAnyInbound) ? (
        <Row style={{ marginTop: '0.5rem' }} align="center" direction="row" justify="space-between">
          <Column large={5.5} medium={5.5} small={8}>
            <h2> {t('component.extras.basket.totalToPay')}</h2>
          </Column>
          <Column large={6} medium={6} small={4}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <h2>
                {flBooking?.currency}{' '}
                {hasPaymentConfiguration && bookingResult?.bookingBalance
                  ? bookingResult?.bookingBalance
                  : findSumTotalBasket(bookingCode || '', addToCartState)}
              </h2>
            </div>
          </Column>
        </Row>
      ) : null}

      {!hasAnyOutbound && !hasAnyInbound ? (
        <div style={{ marginTop: '1rem' }}> {t('component.extras.basket.empty')}</div>
      ) : null}

      <Divider style={{ marginBottom: '2rem' }} />

      <Row>
        <Column large={0.5} medium={0.5} small={1.5}>
          <Checkbox
            data-cy="checkbox-terms-accepted"
            onChange={(e) => {
              setTermsAccepted(e.target.checked)
              if (e.target.checked) {
                sessionStorage.setItem('termsAccepted', 'true')
              } else {
                sessionStorage.removeItem('termsAccepted')
              }
            }}
            label=""
            checked={termsAccepted}
          />
        </Column>
        <Column large={11.5} medium={11.5} small={10}>
          <a
            style={{ fontWeight: '500', textDecoration: 'underline' }}
            rel="noreferrer"
            href={`https://www.fjordline.com/${i18n.language}/p/reise-og-kjopsvilkar`}
            target="_blank"
          >
            {t('component.extras.basket.conditions.ingress')} {t('component.extras.basket.conditions.terms')}
          </a>
        </Column>
      </Row>
      {hasPaymentConfiguration ? (
        <div style={{ marginTop: '1rem' }}>
          <Feedback type="info" variant="notification" dataCy="feedback-already-reserved-in-cart">
            {t('component.extras.basket.alreadyReservedInCart_description')} <br />
            <span style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }} onClick={rollbackCart}>
              {rollbackLoading ? '' : t('component.extras.basket.emptyCart')}
            </span>
          </Feedback>
        </div>
      ) : null}

      <Row style={{ marginTop: '1rem' }}>
        {hasPaymentConfiguration ? null : (
          <Column large={6} medium={6} small={12}>
            <FormElementWrapper>
              <ButtonFull style={{ marginBottom: '-1rem' }}>
                <Button
                  dataCy="btn-go-to-payment"
                  onClick={() => {
                    //If user have payment url -> Go directly to payment

                    if (termsAccepted) {
                      sessionStorage.setItem('termsAccepted', 'true')
                      checkoutCart()
                      //Else show toast that terms must be accepted
                    } else {
                      dispatchToast({
                        message: t('component.extras.basket.conditions.force'),
                        timeout: 5000,
                      })
                    }
                  }}
                  disabled={checkoutLoading || (!hasAnyInbound && !hasAnyOutbound)}
                  size="medium"
                  leadingIcon={checkoutLoading ? Spinner : Boat}
                >
                  {t('component.extras.basket.goToPayment')}
                </Button>
              </ButtonFull>
            </FormElementWrapper>
          </Column>
        )}
        {bookingResult ? (
          <>
            <div style={{ marginTop: '1rem' }} /> <AdyenPayment bookingResult={bookingResult} />{' '}
          </>
        ) : null}
        {hasPaymentConfiguration && bookingResult && parseInt(bookingResult?.bookingBalance) > 0 ? (
          <Column large={6} medium={6} small={12}>
            <FormElementWrapper>
              <ButtonFull style={{ marginBottom: '-1rem' }}>
                <Button
                  dataCy="btn-rollback-cart"
                  onClick={rollbackCart}
                  disabled={!showRollbackButton || rollbackLoading || (!hasAnyInbound && !hasAnyOutbound)}
                  size="medium"
                  leadingIcon={rollbackLoading || !showRollbackButton ? Spinner : Trash}
                  theme="ghost"
                >
                  {!showRollbackButton ? t('component.general.loading') : t('component.extras.basket.emptyCart')}
                </Button>
              </ButtonFull>
            </FormElementWrapper>
          </Column>
        ) : null}

        {checkoutError ? (
          <div style={{ width: '100%', marginTop: '1rem' }}>
            <Feedback type="error" variant="notification">
              {ENVIRONMENT === 'DEV' && checkoutError.response?.status ? (
                checkoutError.response?.status
              ) : (
                <>
                  {t('error.basket.generic')} {t('error.basket.or')}{' '}
                  <a
                    onClick={() => {
                      window.open(
                        `https://www.fjordline.com/${i18n.language}/p/kundeservice/hjelp-og-kontakt`,
                        '_blank',
                      )
                    }}
                    style={{ fontWeight: '500', textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    {t('error.basket.contactCustomerService')}
                  </a>
                </>
              )}
              {checkoutError.response?.statusText}
              {ENVIRONMENT === 'DEV' &&
              checkoutError.response?.data &&
              JSON.stringify(checkoutError.response?.data).length > 0
                ? JSON.stringify(checkoutError.response?.data)
                : null}
            </Feedback>
          </div>
        ) : null}
        {rollbackError ? (
          <div style={{ width: '100%', marginTop: '1rem' }}>
            <Feedback type="error" variant="notification">
              {t('error.basket.rollback')}
            </Feedback>
          </div>
        ) : null}

        {hasPaymentConfiguration || flBooking?.upgradeOptions?.canBuyBookingItems === false ? null : (
          <Column large={6} medium={6} small={12}>
            <AddExtrasModalButton bookingCode={bookingCode || ''} backTargetTitle="" isActiveOrFuture={true} />
          </Column>
        )}
      </Row>
    </div>
  )
}

export default Basket

const SVGWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;

  svg {
    height: 35px;
    margin-bottom: 1.5rem;
  }
`

export type cabinInfoArrayType = {
  name: string
  code: string
  image: string
}
