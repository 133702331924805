import { TFunction } from 'i18next'

import { Meal } from '../../../graphql/types'

import { MealInfoGroupedByDateAndStartTime, MealSpec, MealSpecGrieg } from './getBookingObjects.types'

export default function groupMealsByDateAndStartTime({
  meals,
  t,
}: {
  meals: Meal[]
  t: TFunction<string>
}): MealInfoGroupedByDateAndStartTime | undefined {
  if (meals.length == 0) {
    return undefined
  }

  const groupedMeals: MealInfoGroupedByDateAndStartTime = {}

  meals
    .toSorted((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
    .forEach((meal) => {
      const date = meal.startTime.split('T')[0]
      // Only start time string cannot be uses as a valid Date later, thus use the whole string for start time
      const startTime = meal.startTime // .split('T')[1]
      const subCode = meal.subCode || 'default'
      const mealNameFromCode = meal.code || undefined
      const quantity = meal.quantity
      const rowNumber = meal.rowNumber

      if (!groupedMeals[date]) {
        groupedMeals[date] = {}
      }

      if (!groupedMeals[date][startTime]) {
        groupedMeals[date][startTime] = {
          mealNameFromCode,
          totalQuantity: meal.quantity,
          participants: {},
        }
      } else {
        groupedMeals[date][startTime] = {
          ...groupedMeals[date][startTime],
          totalQuantity: groupedMeals[date][startTime].totalQuantity + meal.quantity,
        }
      }

      groupedMeals[date][startTime].participants = {
        ...groupedMeals[date][startTime].participants,
        [subCode]: {
          quantity,
          rowNumber,
          translatedInfo: translateMealSubCode({ subCode, quantity, t }),
        },
      }
    })

  return groupedMeals
}

function translateMealSubCode({
  subCode,
  quantity,
  t,
}: {
  subCode: string
  quantity: number
  t: TFunction<string>
}): string {
  switch (subCode) {
    case MealSpec.ADL:
      return quantity > 1 ? t('component.extras.meal.adults') : t('component.extras.meal.adult')
    case MealSpec.CHD:
      return t('component.extras.meal.child')
    case MealSpec.BABY:
      return t('component.extras.meal.baby')
    case MealSpec.INF:
      return t('component.extras.meal.infant')
    case MealSpecGrieg.SIXCOUR:
      return t('component.extras.meal.sixcour')
    case MealSpecGrieg.FIVECOUR:
      return t('component.extras.meal.fivecour')
    case MealSpecGrieg.FOURCOUR:
      return t('component.extras.meal.fourcour')
    case MealSpecGrieg.THREECOUR:
      return t('component.extras.meal.threecour')
  }

  return ''
}
