import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Hotel2 } from '@fjordline/styles-v3'

import { useTimeZonedDateFormatter } from '../../../hooks/DateFormat'
import { MealInfoGroupedByDateAndStartTime } from '../getBookingObjects/getBookingObjects.types'

import getMealSeatingInfo from './meal/getMealSeatingInfo'
import { MealLabel } from './meal/meal.styles'
import useFetchMeals from '../../../sanity/meals/useFetchMeals'
import moment from 'moment/min/moment-with-locales'

export default function Meals({
  isInbound = false,
  meals,
}: {
  isInbound?: boolean
  meals: MealInfoGroupedByDateAndStartTime | undefined
}): JSX.Element | null {
  const { t, i18n } = useTranslation()
  const departure = useMemo(
    () => (isInbound ? t('component.extras.common.arrival') : t('component.extras.common.departure')),
    [isInbound, t],
  )

  if (meals === undefined) {
    return null
  }

  return (
    <section data-cy="meals" className='flex flex-col gap-5'>
      {Object.keys(meals).map((mealDate) => {
        const seatings = meals[mealDate]

        const formattedDate = moment(mealDate).locale(i18n.language).format('dddd. DD MMMM YYYY')

        return (
          <article key={mealDate} className='border-b-1 pb-5 last:pb-0 border-gray-200 last:border-b-0'>
            <h4 className='font-semibold tracking-wide'>{`${departure} ${formattedDate}`}</h4>
            {Object.keys(seatings).map((startTime, index) => {
              const key = index + startTime
              return <MealItem key={key} startTime={startTime} seatings={seatings} />
            })}
          </article>
        )
      })}
    </section>
  )
}

type MealItemProps = {
  startTime: string
  seatings: MealInfoGroupedByDateAndStartTime[string]
}

const MealItem: React.FC<MealItemProps> = ({ startTime, seatings }: MealItemProps) => {
  const d = useTimeZonedDateFormatter()
  const seating = seatings[startTime]
  const formattedStartTime = d(startTime, 'HH:mm')
  const mealSeatingInfo = getMealSeatingInfo(seating.participants)
  const { data: mealFromSanity } = useFetchMeals({ code: seating.mealNameFromCode ?? '' })
  const { i18n } = useTranslation()
  return (
    <ul key={formattedStartTime} className='flex flex-col gap-2 mt-2'>
      <li data-cy={seating.mealNameFromCode} className='flex flex-row gap-3 items-center'>
        <Hotel2 width={20} height={20} />
        {`${formattedStartTime} - ${
          mealFromSanity?.mealName?.[i18n.language] ?? seatings[startTime].mealNameFromCode
        }`}
      </li>
      <li className='flex flex-row gap-3 items-center'>
        <Group width={20} height={20} /> {mealSeatingInfo}
      </li>
    </ul>
  )
}
