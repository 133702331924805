import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from '@fjordline/styles-v3'
import { ReportIssueForm } from '../../components/ReportIssue/Form'
import { FaChevronDown } from 'react-icons/fa'

import { SectionTitle, StyledFieldset, StyledLegend } from './Support.styled'
import { SupportFAQ } from './SupportFAQ'
import { SupportContact } from './SupportContact'
import { LuMessageCircleWarning } from 'react-icons/lu'
import { scrollTo } from './scrollTo'

export const Main = styled.div`
  --border-color: #d1d5db;
  --fl-seashell: #fff9f5;
  --fl-seashell-100: #ffe6d5;
  --fl-red: #d7002b;
  --fl-night: #0e0e11;
  --fl-ocean: #407f7f;
  --fl-ocean-50: #f4f9f9;
  --fl-ocean-100: #daedeb;
  --fl-glow: #ffe393;
  --fl-seashell-50: #fff4ed;

  color: #0e0e11;
  line-height: 1.7em;
`

const QuickMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  padding: 1em 0;

  @media (min-width: 768px) {
    gap: 1em;
  }
`

const ScrollButton = styled(Button)`
  @media (max-width: 767px) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
`

const Grid = styled.div`
  display: grid;
  gap: 2em;
  grid-template-columns: 1fr;
  grid-template-areas:
    'col2'
    'col1';

  @media (min-width: 768px) {
    grid-template-columns: 60% 40%;
    grid-template-areas: 'col1 col2';
    margin-right: 2em;
  }
  margin-top: 2em;
`

const Column = styled.div<{ $gridArea: string }>`
  grid-area: ${(props) => props.$gridArea};
  position: relative;
`

const SectionReport = styled(StyledFieldset)`
  margin: 3em 0;
`

const FormIngress = styled.p`
  margin: 0 0 2em 0;
`

const FormWrapper = styled.div`
  max-width: 600px;
  padding: 1em;
`

export const Support = () => {
  const { t } = useTranslation()

  const sectionFAQ = useRef(null)
  const sectionReport = useRef(null)

  return (
    <Main>
      <h1>{t('support.titles.page')}</h1>
      <QuickMenu>
        <ScrollButton leadingIcon={FaChevronDown} onClick={() => scrollTo(sectionFAQ)} dataCy='scrollBtn-faq'>
          {t('support.labels.faq')}
        </ScrollButton>
        <ScrollButton leadingIcon={FaChevronDown} onClick={() => scrollTo(sectionReport)} dataCy='scrollBtn-report'>
          {t('support.labels.report')}
        </ScrollButton>
      </QuickMenu>

      <Grid>
        <Column $gridArea="col1">
          <SupportFAQ ref={sectionFAQ} />

          <SectionReport ref={sectionReport}>
            <StyledLegend>
              <LuMessageCircleWarning />
            </StyledLegend>
            <SectionTitle>{t('component.reportIssue.modalTitle')}</SectionTitle>
            <FormWrapper>
              <FormIngress>{t('component.reportIssue.modalIngress')}</FormIngress>
              <ReportIssueForm />
            </FormWrapper>
          </SectionReport>
        </Column>

        <Column $gridArea="col2">
          <SupportContact reportSection={sectionReport} />
        </Column>
      </Grid>
    </Main>
  )
}
