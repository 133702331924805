import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, DepartureDate, Download, Spinner } from '@fjordline/styles-v3'
import { QRCodeSVG } from 'qrcode.react'

import { Journey } from '../../graphql/types'
import { useTimeZonedDateFormatter } from '../../hooks/DateFormat'
import { useAvailabilityItems } from '../../providers/AvailabilityItemsProvider'
import { MayBe } from '../../types/MayBe'
import { findSumTotalBasket } from '../bookingList/BookingCards'

import BasketInfo from './boardingCards/BasketInfo'
import { BookingDetailsBasketInfoWrapper } from './boardingCards/basketInfo/basketInfo.styles'
import {
  BookingInfoDetailsWrapper,
  BookingInfoHeaderWrapper,
  BookingNumber,
  QrWrapper,
  TravelWay,
} from './sectionJourneyDetails/sectionJourneyDetails.styles'
import useDownloadPDF from './useDownloadPDF'

/**
 * @description - Render the qr code and information about the outbound and inbound journey
 * @param inbound - inbound journey if any
 * @param outbound - outbound journey if any
 * @param bookingCode - for the qr code
 * @param showDownloadButton - booking confirmation download
 * @constructor
 */
export type SectionJourneyDetailsProps = {
  backTargetTitle?: string
  inbound: Journey | undefined
  outbound: Journey | undefined
  bookingCode: string
  showDownloadButton?: boolean
}

export const SectionJourneyDetails = ({ backTargetTitle, inbound, outbound, bookingCode, showDownloadButton = false }: SectionJourneyDetailsProps) => {
  const { t } = useTranslation()
  const { downloadPDF, fetchingPDF } = useDownloadPDF()

  const addToCartState = useAvailabilityItems().addToCartState

  return (
    <div className='mb-5 font-source_sans'>
      <JourneyHeader outbound={outbound} />

      {findSumTotalBasket(bookingCode, addToCartState) > 0 ? (
        <BookingDetailsBasketInfoWrapper>
          <BasketInfo
            sumTotal={findSumTotalBasket(bookingCode, addToCartState)}
            bookingCode={bookingCode || ''}
            backTargetTitle={backTargetTitle}
          />
        </BookingDetailsBasketInfoWrapper>
      ) : null}

      <div className='shadow-sm rounded-b-xl border border-gray-200 bg-white p-XL'>
        <div className='flex content-start flex-col items-center mb-5 md:flex-row md:items-start md:mb-0'>
          <QrWrapper>
            <QRCodeSVG size={100} value={bookingCode} />
          </QrWrapper>
          <div className='flex flex-col content-start grow md:pl-5 gap-1'>
            <h5>{t('label.booking.code')}</h5>
            <span className='block text-3xl'>{bookingCode}</span>
          </div>

          {showDownloadButton ? (
            <div>
              <Button
                dataCy="btn-download-pdf"
                disabled={fetchingPDF}
                onClick={downloadPDF}
                theme="secondary"
                size="small"
                leadingIcon={fetchingPDF ? Spinner : Download}
              >
                {t('label.button.downloadPDF')}
              </Button>
            </div>
          ) : null}
        </div>

        {outbound !== undefined ? (
          <JourneyRoute route={outbound} />
        ) : // TODO: Render checkin times here
        null}

        {inbound !== undefined ? (
          <JourneyRoute route={inbound} />
        ) : // TODO: Render checkint times here
        null}
      </div>
    </div>
  )
}

const JourneyHeader = ({ outbound }) => {
  const { t } = useTranslation()

  const outboundDestinationPort: string = useMemo<string>(
    () => (outbound ? t(`ports.${outbound.arrivalPortInfo?.carresPortCode}`) : ''),
    [outbound, t],
  )

  const headerImage = MayBe.orElse<string>(
    outbound?.arrivalPortInfo?.imageUrl ?? null,
    'https://cdn.sanity.io/images/90kslno9/homeport/2602c6720afce8e9fa7fb6b0eecf8f3bbc49e6b3-1000x700.jpg?auto=format',
  )

  const headerBackground = `linear-gradient( 180deg, rgba(0, 0, 0, 0.33) 50%, rgba(255, 255, 255, 0) 100% ),url(${headerImage}),#c4c4c4`

  return (
    <div
      className={`rounded-t-xl`}
      style={{background: headerBackground, backgroundPosition: '50% 50%', backgroundSize: 'cover', paddingBottom: '25%'}}
    >
      <h1 className='text-white p-5 !m-0 block'>{outboundDestinationPort}</h1>
    </div>
  )
}

const JourneyRoute = ({ route }) => {
  const d = useTimeZonedDateFormatter()

  return (
      <div className='flex items-center gap-5 py-4 border-t-1 border-gray-200'>
        <DepartureDate width={30} height={30} />
        <div>
          <h5 className="font-semibold text-lg">{route.departurePortInfo?.portName} - {route.arrivalPortInfo?.portName}</h5>
          <span className='text-lg block'>{d(route.departureDate, 'PPPPp')}</span>
        </div>
      </div>
  )
}
