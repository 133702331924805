import { useParams } from 'react-router-dom'

import { findPassengerQuantityPerFareCode } from '../../../pages/booking/editOrAddItemsPages/meals/utils'
import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { Currency, FareCode, useGetAvailableCabinsQuery } from '../../types'
import UseRouteExpect from '../route/UseRouteExpect'

function UseGetInboundCabins(addModalExtrasBookingCode?: string | undefined) {
  const { bookingCode: bookingCodeFromParams } = useParams()
  const bookingCode = addModalExtrasBookingCode || bookingCodeFromParams

  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })

  const { expectCabins, routeExpectLoading } = UseRouteExpect({
    originPort: flBooking?.inbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.inbound?.arrivalPortInfo?.portCode as string,
  })

  const allowUpgrade = flBooking?.upgradeOptions?.inbound?.canUpgradeCabins && expectCabins

  const { adults, children, infants } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const passengersToPlace = adults + children + infants

  const journeyCode = flBooking?.inbound?.journeyCode as string
  const { data, loading } = useGetAvailableCabinsQuery({
    variables: {
      requestParams: {
        ticketCode: flBooking?.inbound?.ticket?.ticketCode as string,
        journeyCode: journeyCode,
        bookingCode: bookingCode as string,
        departureDate: flBooking?.inbound?.departureDate as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.inbound?.departureCode as string,
        destinationPort: flBooking?.inbound?.arrivalPortInfo?.portCode as string,
        hasVehicles: flBooking?.inbound?.vehicles && flBooking?.inbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.outbound?.passengers?.[0].customerCode &&
          flBooking?.outbound?.passengers[0].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.inbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.inbound?.vesselCode,
        passengers: [
          {
            code: FareCode.Adult,
            quantity: passengersToPlace,
          },
        ],
      },
    },
    skip:
      flBooking?.upgradeOptions?.canBuyBookingItems === false ||
      routeExpectLoading ||
      !allowUpgrade ||
      journeyCode === undefined ||
      flBooking?.outbound?.departureCode === undefined ||
      flBooking.inbound?.departureCode === undefined ||
      flBooking.inbound.ticket?.ticketCode === undefined,
  })

  return { data, loading }
}

export default UseGetInboundCabins
