import React, { RefObject } from 'react'
import styled from 'styled-components'
import { StyledFieldset, StyledLegend, SectionTitle, StyledLink } from "./Support.styled"
import { useTranslation } from 'react-i18next'
import { CustomerService } from '@fjordline/styles-v3'
import { scrollTo } from './scrollTo'

const Wrapper = styled(StyledFieldset)`
  position: static;
  margin-bottom: 1em;

  @media (min-width: 768px) {
    position: sticky;
    top: 1rem;
  }
`

const InnerContainer = styled.div`
  padding: 0 1rem;
`

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`

const StyledFigure = styled.figure`
  margin: 0;
  padding: 2em 0 2em;

  figcaption {
    margin-bottom: 3px;
    color: var(--fl-ocean);
    font-weight: 500;
  }
`

const ScrollLink = styled(StyledLink)`
  cursor: pointer;
`

type SupportContactProps = {
    reportSection: RefObject<HTMLElement | null>
}

export const SupportContact = ({ reportSection } : SupportContactProps) => {
  const { t } = useTranslation()

  const openingHours = t('support.openingHours', { returnObjects: true }) as string[]

  return (
    <Wrapper>
      <StyledLegend><CustomerService /></StyledLegend>
      <SectionTitle>{t('support.titles.contact')}</SectionTitle>
      <InnerContainer>
        <StyledList>
          <li>
            {t('support.labels.email')}: <StyledLink href={`mailto:${t('support.email')}`}>{t('support.email')}</StyledLink>
          </li>
        </StyledList>

        <StyledFigure>
          <figcaption>{t('support.labels.openingHours')}</figcaption>
          <StyledList>
            {openingHours.map((value, index) => (
              <li key={`openingHours-${index}`}>
                {value}
              </li>
            ))}
          </StyledList>
        </StyledFigure>

        <ScrollLink onClick={() => scrollTo(reportSection)}>
          {t('support.labels.report')}
        </ScrollLink>
      </InnerContainer>
    </Wrapper>
  )
}