import groq from 'groq'
import { sanityClient } from './sanityClient'
import { ShipMeal } from './meals/types'
import { ShipCabin } from './cabins/types'

export async function fetchCabinsByCodeFromSanity(code: string): Promise<ShipCabin | undefined> {
  return await sanityClient.fetch(groq`*[_type == "shipCabin" && cabinType == $code]`, { code })
}

export async function fetchAllCabinsFromSanity(): Promise<ShipCabin[] | undefined> {
  return await sanityClient.fetch(groq`*[_type == "shipCabin" ]`)
}

export async function fetchAllMealsFromSanity(): Promise<ShipMeal[] | undefined> {
  return await sanityClient.fetch(groq`*[_type == "shipMeal" ]`)
}

export async function fetchMealByCodeOrRelationFromSanity(mealCode: string): Promise<ShipMeal | undefined> {
  return await await sanityClient.fetch(
    groq`*[ _type == "shipMeal" && !(_id in path("drafts.**")) && (mealType == $mealCode || $mealCode in (relatedMeals[].mealCode))]`,
    { mealCode },
  )
}

export async function fetchExtrasByCodeFromSanity(code: string) {
  return await sanityClient.fetch(groq`*[ _type == "shipExtra" && !(_id in path("drafts.**")) && extraType == $code]`, {
    code,
  })
}

export async function fetchMultipleExtrasByCodeFromSanity(code: string[]) {
  return await sanityClient.fetch(
    groq`*[ 
      _type == "shipExtra" && 
      !(_id in path("drafts.**")) && 
      extraType in $code
    ]`,
    {
      code,
    },
  )
}

export async function fetchTicketsByCodeFromSanity(code: string) {
  return await sanityClient.fetch(groq`*[ _type == "shipProductCode" && productCode == $code]`, { code })
}

export async function fetchAllTicketsFromSanity() {
  return await sanityClient.fetch(groq`*[ _type == "shipProductCode" ]`)
}

export async function fetchShipByCodeFromSanity(code: string) {
  return await sanityClient.fetch(groq`*[_type == "ship" && shipCode == $code]`, { code })
}

export async function fetchAllShipsFromSanity() {
  return await sanityClient.fetch(groq`*[_type == "ship"]`)
}

export async function fetchImageContainerFromSanity(code: string) {
  return await sanityClient.fetch(groq`*[_type == "imageContainer" && name == $code]`, { code })
}

export async function fetchSpecificationByCodeFromSanity(code: string) {
  return await sanityClient.fetch(groq`* [ _type == "specification" && specificationCode == $code]`, { code })
}

export async function fetchAllSpecificationsFromSanity() {
  return await sanityClient.fetch(groq`* [ _type == "specification" ]`)
}

export async function fetchAllAccommodationsFromSanity() {
  return await sanityClient.fetch(groq`* [ _type == "accommodation" ]`)
}

export async function fetchAccommodationByCodeFromSanity(code: string) {
  return await sanityClient.fetch(groq`*[_type == 'accommodation_v2' && externalHotelId == $code]`, { code })
}
export async function fetchTranslationByCodeFromSanity(code: string) {
  return await sanityClient.fetch(groq`* [ _type == "translation" && key == $code]`, { code })
}

export async function fetchPortsSanity() {
  return await sanityClient.fetch(groq`* [ _type == "port"]`)
}

export async function fetchPayableWithClubPointsFromSanity(lang: string) {
  return await sanityClient.fetch(
    groq`*[
  _type == "cruise_v2" &&
  language == $lang &&
  packageRules.productCode->payableWithClubPoints == true
]{
  ...,
  packageRules {
    productCode->
  }
}`,
    { lang },
  )
}

export async function fetchFjordClubJourneysFromSanity(lang: string) {
  return await sanityClient.fetch(
    groq`*[
  _type == "cruise_v2" &&
  language == $lang &&
  packageRules.productCode->restrictedToMembers == true
]{
    ...,
  packageRules {
    productCode->
  }
}
`,
    { lang },
  )
}

export function fetchProductCodeInfo(productCode: string) {
  return sanityClient.fetch(groq`*[_type=="shipProductCode" && productCode==$productCode]`, { productCode })
}
