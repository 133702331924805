import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { gender } from '@fjordline/booking-draft'

export const useGenderOptions = () => {
  const { t, i18n } = useTranslation()

  const genders = useMemo(
    () => [
      {
        description: gender.MALE,
        value: gender.MALE,
        label: t('label.gender.M'),
      },
      {
        description: gender.FEMALE,
        value: gender.FEMALE,
        label: t('label.gender.F'),
      },
    ],
    [i18n.language],
  )

  return {
    genders: genders
  }
}
