import React from 'react'
import clsx from "clsx"

type StackedButtonProps = {
  onClick?: () => void
  disabled?: boolean
  href?: string | undefined
  children: React.ReactNode
}
export const StackedButton: React.FC<StackedButtonProps> = ({ onClick = () => {}, disabled = false, href = undefined, children, ...rest }) => {
  const classes = clsx(
    'flex flex-1 flex-row gap-3 items-center p-6 md:p-4 rounded border-1',
    {
      '!bg-[#EDEDF1] hover:!bg-[#6e7189] border-[#D7D8E0] !text-[#373843] hover:!text-white hover:cursor-pointer': !disabled,
      'bg-gray-100 border-gray-100 text-gray-400 cursor-not-allowed': disabled
    }
  )

  if (href) {
    return (
      <a href={href} target="_blank" rel='noopener' className={classes} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <button disabled={disabled} onClick={onClick} className={classes} {...rest}>
      {children}
    </button>
  )
}
