import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Column, FormElementWrapper, Row } from '@fjordline/styles-v3'

import CustomBackButton from '../components/CustomBackbutton'
import { ButtonFull } from '../components/globalStyles'
import { FlBooking } from '../graphql'
import UseGetBookingDetails from '../graphql/customerHooks/UseGetBookingDetails'
import { BookingNavLink } from '../routes/navLinkFunctions'
import usePurchaseGTM from '../utils/GTM/usePurchaseGTM'
import { useAvailabilityItems } from '../providers/AvailabilityItemsProvider'
import { CartsByBookingCodes } from '../providers/availabilityItemsProvider/types'
import { useWebSocketContext } from '../providers/myPageStateProvider/websocketProvider/websocketContext'

import AddExtrasModalButton from './booking/AddExtrasModalButton'
import { findSumTotalBasket } from './bookingList/BookingCards'
import useClearCartOfBookingCode from './receipt/useClearCartOfBookingCode'
import useQueryParams, { PAID_STATE, PAYMENT_PARAMS, PAYMENT_STATUS } from './receipt/useQueryParams'
import { trackEvent } from '../providers/TelemetryProvider'
import { extractBookingNumber } from '../providers/WebsocketProvider'
import { useAdyenSubmitDetails } from './basket/useAdyenSubmitDetails'

const Receipt: React.FC = () => {
  const { cartData } = useWebSocketContext()

  const { queryParams } = useQueryParams()
  const fullyPaid = queryParams.get(PAYMENT_PARAMS.PAID_STATE) === PAID_STATE.FULLY_PAID
  const partlyPaid = queryParams.get(PAYMENT_PARAMS.PAID_STATE) === PAID_STATE.PARTLY_PAID
  const bookingBalance = queryParams.get(PAYMENT_PARAMS.BOOKING_BALANCE)
  const bookingBalanceToInteger = parseInt(bookingBalance || '0')
  // const nothingPaid = queryParams.get(PAYMENT_PARAMS.PAID_STATE) === PAID_STATE.NOTHING_PAID
  const paymentAccepted = queryParams.get(PAYMENT_PARAMS.PAYMENT_STATUS) === PAYMENT_STATUS.ACCEPTED.toUpperCase()
  const paymentDeclined = queryParams.get(PAYMENT_PARAMS.PAYMENT_STATUS) === PAYMENT_STATUS.DECLINED
  // const paymentCancelled = queryParams.get(PAYMENT_PARAMS.PAYMENT_STATUS) === PAYMENT_STATUS.CANCELLED
  useMemo(() => window.scrollTo(0, 0), [])
  const { t } = useTranslation()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)

  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const navigate = useNavigate()
  const addToCartState: CartsByBookingCodes = useAvailabilityItems().addToCartState
  usePurchaseGTM(flBooking as FlBooking, bookingCode ?? '', findSumTotalBasket(bookingCode || '', addToCartState))
  useClearCartOfBookingCode(bookingCode, fullyPaid)
  useAdyenSubmitDetails(cartData.bookingCarts?.[bookingCode || '']?.bookingResult)
  useEffect(() => {
    // If the booking is partly paid and there is still a balance to pay, navigate to the basket (This happens if a user cancels a payment with Vipps or Trustly)
    if (partlyPaid && bookingBalanceToInteger > 0) {
      navigate(`/bookingList/${bookingCode}/basket`)
    }
  }, [bookingBalanceToInteger, bookingCode, navigate, partlyPaid])

  useEffect(() => {
    trackEvent('user landed on receipt page')
  }, [])

  return (
    <>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={BookingNavLink({ bookingCode: bookingCode })} />
      </FormElementWrapper>
      {fullyPaid && paymentAccepted ? (
        <>
          <h1> {t('label.booking.paymentDone')}</h1>
          <h3> {t('label.booking.paymentMessage')}</h3>{' '}
        </>
      ) : null}

      {partlyPaid && paymentAccepted ? (
        <>
          <h1> {t('payment.partlyPaid')}</h1>
          <h3> {t('payment.partlyPaid_description')}</h3>
          {cartData &&
          cartData?.bookingCarts &&
          bookingCode &&
          cartData?.bookingCarts?.[bookingCode || '']?.bookingResult?.paymentConfiguration ? (
            <Button
              data-cy="go-to-payment"
              onClick={() => {
                navigate(`/bookingList/${bookingCode}/basket`)
              }}
            >
              {' '}
              {t('component.extras.basket.goToPayment')}
            </Button>
          ) : null}
        </>
      ) : null}

      {paymentDeclined ? <h1> {t('payment.declined')}</h1> : null}

      <Row style={{ marginTop: '2rem' }}>
        <Column large={4} medium={4} small={12}>
          <FormElementWrapper>
            <ButtonFull>
              <Button
                onClick={() => navigate(`/bookingList/${bookingCode}`)}
                size="large"
                dataCy="go-to-bookingOverview"
              >
                {t('label.button.bookingOverview')}
              </Button>
            </ButtonFull>
          </FormElementWrapper>
        </Column>
        <Column large={6} medium={6} small={12}>
          <AddExtrasModalButton
            largeSizedButton
            bookingCode={bookingCode || ''}
            backTargetTitle=""
            isActiveOrFuture={true}
          />
        </Column>
      </Row>
    </>
  )
}

export default Receipt
