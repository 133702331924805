/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FormElementWrapper, H3 } from '@fjordline/styles-v3'

import { useZonedTimeToUtcDateFormatter } from '../../hooks/DateFormat'
import { useMyPage } from '../../providers/myPageStateProvider/context'

type TravelFriendTextsType = {
  label: string
  text: string
}

const FjordClubTravelFriendList = () => {
  const { t } = useTranslation()
  const { customer } = useMyPage()
  const d = useZonedTimeToUtcDateFormatter()

  const isCustomerMember: boolean = useMemo(() => {
    const clubMemberships = customer?.clubMemberships
    return clubMemberships?.find((m) => m?.active) !== undefined
  }, [customer?.clubMemberships])

  const getTexts: (travelFriend) => TravelFriendTextsType[] = useCallback(
    (travelFriend) => {
      const { nonRegisteredCustomerInfo, registeredCustomerInfo } = travelFriend
      const texts: TravelFriendTextsType[] = []

      texts.push({
        label: t('label.personalia.firstName'),
        text: nonRegisteredCustomerInfo?.firstName || '',
      })
      texts.push({
        label: t('label.personalia.lastName'),
        text: nonRegisteredCustomerInfo?.lastName || '',
      })

      texts.push({
        label: t('label.personalia.dateOfBirth'),
        text: d(nonRegisteredCustomerInfo?.birthDate || undefined, 'PPP'),
      })

      texts.push({
        label: t('label.customer.customerCode'),
        text: registeredCustomerInfo?.customerCode || '',
      })

      texts.push({
        label: t('label.address.nationality'),
        text: nonRegisteredCustomerInfo?.nationalityCode
          ? t('label.country.' + nonRegisteredCustomerInfo?.nationalityCode)
          : '',
      })

      return texts
    },
    [d, t],
  )

  // if (customer === undefined || (travelFriends !== null && travelFriends !== undefined && travelFriends.length <= 0)) {
  //   return null
  // }
  return (
    <>
      {/* <Feedback variant="notification" type="info">
        {t('label.travelFriend.tempDisabled')}
      </Feedback> */}
      {/* {travelFriends !== null && travelFriends !== undefined && travelFriends.length > 0 && (
        <>
          <h3>{t('label.travelFriend.header')}</h3>
          {travelFriends.map((travelFriend) => {
            const rowNumber: number | undefined = travelFriend.rowNumber ? travelFriend.rowNumber.valueOf() : undefined
            if (rowNumber !== undefined) {
              const texts = getTexts(travelFriend)
              return (
                <FormElementWrapper dataCy="div-member-info" key={rowNumber}>
                  <EditInput texts={texts} onClick={() => navigate(TravelerFriendNavLink({ rowNumber }))} />
                </FormElementWrapper>
              )
            }
          })}
        </>
      )} */}
      <FormElementWrapper dataCy="div-add-traveler">
        {/* <ButtonFull>
          <Button theme="primary" disabled={!isCustomerMember}>
            {t('label.travelFriend.add')}
          </Button>
        </ButtonFull> */}
      </FormElementWrapper>
    </>
  )
}

export default FjordClubTravelFriendList
