import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Download, Close, CustomerService, Feedback, FormElementWrapper, LottieLoader, Spinner } from '@fjordline/styles-v3'

import CustomBackButton from '../components/CustomBackbutton'
import { BookedAccommodation, Passenger, useGetBoardingCardsQuery, useGetBookingLazyQuery, Vehicle } from '../graphql'
import { navLinks } from '../routes/navlinks'
import { useBookingDetails } from '../providers/BookingDetailsProvider'
import { useWebSocketContext } from '../providers/myPageStateProvider/websocketProvider/websocketContext'
import { extractBookingNumber } from '../providers/WebsocketProvider'

import AddExtrasModalButton from './booking/AddExtrasModalButton'
import BoardingCardLinkOrMessage from './booking/BoardingCardLinkOrMessage'
import CancelBooking from './booking/CancelBooking'
import getBookingObjects from './booking/getBookingObjects'
import SectionCabins from './booking/SectionCabins'
import SectionExtrasAshore from './booking/SectionExtrasAshore'
import SectionExtrasOnboard from './booking/SectionExtrasOnboard'
import { SectionJourneyDetails } from './booking/SectionJourneyDetails'
import SectionMeals from './booking/SectionMeals'
import SectionPassengers from './booking/SectionPassengers'
import SectionVehicles from './booking/SectionVehicles'
import useDownloadPDF from './booking/useDownloadPDF'
import useResolveGraphqlDataForBooking from './booking/useResolveGraphqlDataForBooking'
import OrderNextJourneyBannerOrButton from './bookingList/OrderNextJourneyBanner'
import { FEATURE_SHOW_CANCEL_BOOKING } from '../config'
import SectionAccommodations from './booking/SectionAccommodations'
import SectionTaxesAndFees from './booking/SectionTaxesAndFees'
import { mergeRoomsByCode } from './booking/sectionAccommodations/mergeRoomsHelper'
import { isPastDeadline } from '../utils/isPastDeadline'
import ModifyBookingDeadlineTimer from '../components/ModifyBooingDeadlineTimer'
import { useAvailabilityItems } from '../providers/AvailabilityItemsProvider'
import { ErrorPage } from './booking/ErrorPage'
import { QueryParamsEnum } from '../hooks/useQueryParams'
import SectionExternalBookings from './booking/SectionExternalBookings'
import { SectionCard } from './booking/SectionComponent'
import { StackedButton } from '../components/StackedButton'

const Booking: React.FC = () => {
  const { hasActivePayment } = useAvailabilityItems()
  const location = useLocation()
  const bookingCode = extractBookingNumber(location)
  const { pathname } = useLocation()
  const { flBooking, flBookingLoading } = useBookingDetails()
  const { downloadPDF, fetchingPDF } = useDownloadPDF()
  const { expectCabins, expectExtras, expectMeals } = useResolveGraphqlDataForBooking()
  const {
    t,

    i18n: { language },
  } = useTranslation()

  const modifyBookingDeadline = flBooking?.modificationDeadline

  const {
    inbound,
    inboundCabins,
    inboundExtrasAshore,
    inboundExtrasOnboard,
    inboundMealsGroupedByDateAndStartTime,
    isActiveOrFuture,
    isFuture,
    journeyPorts,
    outbound,
    outboundCabins,
    outboundExtraOnboard,
    outboundExtrasAshore,
    outboundMealsGroupedByDateAndStartTime,
    validatedBookingCode,
    outboundEtax,
    inboundEtax,
  } = useMemo(
    () => getBookingObjects({ flBooking: flBooking, bookingCode, t, language }),
    [flBooking, bookingCode, t, language],
  )

  const passengersToShow: Passenger[] | undefined =
    flBooking?.outbound?.passengers || (outbound?.passengers as Passenger[])
  const vehiclesToShow: Vehicle[] | undefined = flBooking?.outbound?.vehicles || (outbound?.vehicles as Vehicle[])

  const shouldRefetchBooking = sessionStorage.getItem(bookingCode || '') === 'refetchBooking'
  const [refetchBooking] = useGetBookingLazyQuery()

  const { data: boardingCardsData } = useGetBoardingCardsQuery({
    variables: {
      bookingCode: bookingCode || '',
    },
    skip: !bookingCode || bookingCode === '' || flBooking?.showBoardingCards === false,
  })

  const params = new URLSearchParams(location.search)
  const isUpdatingParam = params.get(QueryParamsEnum.isUpdating)
  const updatingBooking = isUpdatingParam === 'true'

  useEffect(() => {
    if (shouldRefetchBooking) {
      setTimeout(() => {
        refetchBooking({
          variables: {
            bookingCode: bookingCode || '',
          },
          fetchPolicy: 'network-only',
        })
        sessionStorage.removeItem(bookingCode || '')
      }, 15000)
    }
  }, [bookingCode, refetchBooking, shouldRefetchBooking])

  const navigate = useNavigate()

  const hideCancelButton = flBooking?.isOngoing || flBooking?.isInPast || FEATURE_SHOW_CANCEL_BOOKING !== true

  if (flBookingLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
        <LottieLoader />
      </div>
    )
  }

  if (!flBookingLoading && !flBooking) {
    return <ErrorPage type="bookingDetails" bookingCode={bookingCode} />
  }

  const localizedLinks = t('links', { returnObjects: true }) as { [key: string]: string }

  return (
    <div style={{ maxWidth: '50rem' }}>
      {hasActivePayment && (
        <div style={{ marginBottom: '1rem' }}>
          <Feedback type="info" variant="notification">
            {t('component.extras.basket.alreadyReservedInCart_description')} ({hasActivePayment.bookingCode}) <br />{' '}
            <span
              onClick={() => navigate(`/bookingList/${hasActivePayment.bookingCode}/basket`)}
              style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
            >
              {t('label.button.gotoBasket')}
            </span>
          </Feedback>
        </div>
      )}
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={navLinks.BOOKING_LIST} />
      </FormElementWrapper>
      {flBooking !== undefined ? (
        <>
          <SectionJourneyDetails
            backTargetTitle={journeyPorts}
            inbound={inbound}
            outbound={outbound}
            bookingCode={validatedBookingCode}
            showDownloadButton={!flBooking?.isInPast}
          />
          {boardingCardsData?.booking.boardingCards && boardingCardsData.booking.boardingCards.length > 0 ? (
            <BoardingCardLinkOrMessage
              bookingCode={bookingCode || ''}
              backTargetTitle={journeyPorts}
              backTarget={pathname}
              departureDate={outbound?.departureDate}
              isActiveOrFuture={isActiveOrFuture}
            />
          ) : null}

          {flBooking.upgradeOptions?.canBuyBookingItems === false ||
          isPastDeadline({ modifyBookingDeadline }) ||
          hasActivePayment?.bookingResult ? null : (
            <>
              <AddExtrasModalButton
                bookingCode={validatedBookingCode}
                backTargetTitle={journeyPorts}
                isActiveOrFuture={isActiveOrFuture}
              />

              <ModifyBookingDeadlineTimer modifyBookingDeadline={modifyBookingDeadline} />
            </>
          )}

          {updatingBooking && (
            <div style={{ marginBottom: '1rem' }}>
              <Feedback type="info" variant="notification" showIcon>
                {t('component.general.updatingBooking')} <Spinner />
              </Feedback>{' '}
            </div>
          )}

          <SectionPassengers isFuture={isFuture} passengers={passengersToShow} />
          <SectionVehicles
            vehicles={vehiclesToShow}
            bookingCode={validatedBookingCode}
            isActiveOrFuture={isActiveOrFuture}
          />
          {expectCabins ? (
            <SectionCabins
              bookingCode={validatedBookingCode}
              inboundCabins={inboundCabins}
              journeyPorts={journeyPorts}
              outboundCabins={outboundCabins}
            />
          ) : null}
          {expectMeals === true ? (
            <SectionMeals
              bookingCode={validatedBookingCode}
              inboundMealsGroupedByDateAndStartTime={inboundMealsGroupedByDateAndStartTime}
              journeyPorts={journeyPorts}
              outboundMealsGroupedByDateAndStartTime={outboundMealsGroupedByDateAndStartTime}
            />
          ) : null}
          {expectExtras === true ? (
            <SectionExtrasOnboard
              bookingCode={validatedBookingCode}
              inboundExtrasOnboard={inboundExtrasOnboard}
              journeyPorts={journeyPorts}
              outboundExtraOnboard={outboundExtraOnboard}
            />
          ) : null}

          {expectExtras === true ? (
            <SectionExtrasAshore
              bookingCode={validatedBookingCode}
              inboundExtrasAshore={inboundExtrasAshore}
              journeyPorts={journeyPorts}
              outboundExtraAshore={outboundExtrasAshore}
            />
          ) : null}

          {flBooking.accommodations && flBooking.accommodations?.length > 0 ? (
            <SectionAccommodations
              accommodations={mergeRoomsByCode(flBooking?.accommodations) as BookedAccommodation[]}
            />
          ) : null}

          {flBooking?.externalBookings && flBooking?.externalBookings?.length > 0 ? (
            <SectionExternalBookings externalBookings={flBooking.externalBookings} />
          ) : null}

          {outboundEtax || inboundEtax ? (
            <SectionTaxesAndFees inboundEtax={inboundEtax} outboundEtax={outboundEtax} />
          ) : null}

        </>
      ) : null}

      <hr className='my-10 border-gray-200' />

      <SectionCard>
        <h3 className="flex flex-row items-center mb-5 gap-3 font-playfair text-2xl">Manage your booking</h3>

        <div className='mt-5 flex flex-col gap-3'>
          {flBooking?.isInPast ? null : (
            <StackedButton onClick={downloadPDF} disabled={fetchingPDF}>
              <div className='w-[20px]'>
                {fetchingPDF ? <Spinner /> : <Download width={20} height={20} />}
              </div>
              <span>{t('label.button.downloadPDF')}</span>
            </StackedButton>
          )}

          {hideCancelButton ? null : <CancelBooking variant='stacked' />}

          <StackedButton href={localizedLinks.customerService}>
            <CustomerService width={20} height={20} />
            <span>Contact support</span>
          </StackedButton>
        </div>
      </SectionCard>

      <hr className='my-10 border-gray-200' />


      <div className='mb-6'>
        <OrderNextJourneyBannerOrButton showBanner={!isActiveOrFuture} />
      </div>
    </div>
  )
}

export default Booking
