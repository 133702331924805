import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  Add,
  Button,
  Container,
  Divider,
  Feedback,
  Modal,
  StepperInput,
  useToastDispatcher,
} from '@fjordline/styles-v3'
import styled from 'styled-components'

import { StepperInputWrapper } from '../../../../components/StepperStateWrapper'
import UseGetInboundCabins from '../../../../graphql/availabilityHooks/cabins/UseGetInboundCabins'
import UseGetOutboundCabins from '../../../../graphql/availabilityHooks/cabins/UseGetOutboundCabins'
import UseGetSuggestedCabinsInbound from '../../../../graphql/availabilityHooks/cabins/UseGetSuggestedCabinsInbound'
import UseGetSuggestedCabinsOutbound from '../../../../graphql/availabilityHooks/cabins/UseGetSuggestedCabinsOutbound'
import UseGetBookingDetails from '../../../../graphql/customerHooks/UseGetBookingDetails'
import { AvailabilityItemWithSpecOptions, Cabin, Currency, SuggestedCabin } from '../../../../graphql/types'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../../../providers/AvailabilityItemsProvider'
import { findMaxCabinSelect } from './cabinFunctions'
import { findPassengerQuantityPerFareCode } from './utils'
import { useWebSocketOperationsContext } from '../../../../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import { useWebSocketContext } from '../../../../providers/myPageStateProvider/websocketProvider/websocketContext'
import useAddLounge from './availableCabins/useAddLounge'
import useFetchCabins from '../../../../sanity/cabins/useFetchCabins'
import { imageUrlFor } from '../../../../sanity/imageUrlBuilder'
import { CabinIcons } from './CabinIcons'

type Props = {
  valueFallback: number
  codeFallback: string
}

const AvailableCabins: React.FC<Props> = ({ valueFallback, codeFallback }: Props) => {
  const { bookingCode } = useParams()
  const { showModal, addToCartState } = useAvailabilityItems()
  const { setShowModal, addCabinsToCart } = useAvailabilityItemsOperations()

  const { data: outboundCabins } = UseGetOutboundCabins()
  const { data: inboundCabins } = UseGetInboundCabins()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })

  const [savedSuggested, setSavedSuggested] = useState<SuggestedCabin[]>([])
  const [shouldAddSuggested, setShouldAddSuggested] = useState(false)

  const cabinsInState = showModal?.isOutbound
    ? addToCartState?.[bookingCode || '']?.['outbound']?.cabins || []
    : addToCartState?.[bookingCode || '']?.['inbound']?.cabins || []

  const quantityOfEachCabinInState = cabinsInState.reduce((acc: Cabin, cabin) => {
    if (cabin.quantityInCabin < 0) {
      return acc
    }

    // Check if the cabin category already exists in the accumulator
    if (acc[cabin.code]) {
      acc[cabin.code] += cabin.quantityInCabin
    } else {
      acc[cabin.code] = cabin.quantityInCabin
    }

    return acc
  }, {})

  const filteredCabinsInState = cabinsInState.filter((e) => e.quantityInCabin > 0)

  const passengersPlaced = filteredCabinsInState.reduce((acc, cabin) => acc + cabin.quantityInCabin, 0)
  const passengersToPlace = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const passengersYetToPlace = passengersToPlace - passengersPlaced

  const cabins = showModal.isOutbound
    ? outboundCabins?.cabinAvailability.availableCabins
    : inboundCabins?.cabinAvailability.availableCabins
  const seats = showModal.isOutbound
    ? outboundCabins?.cabinAvailability.availableSeats
    : inboundCabins?.cabinAvailability.availableSeats
  const allCabinsAndSeats = useMemo(
    () => [...(cabins ?? []), ...(seats ?? [])] as AvailabilityItemWithSpecOptions[],
    [cabins, seats],
  )

  const filterOutCabinsWithNoQuantityLeft = (cabins: AvailabilityItemWithSpecOptions[]) => {
    return cabins.filter((cabin) => {
      const quantityInState = quantityOfEachCabinInState[cabin.code]
      const quantityLeft = cabin.specification.maximumQuantityToBook - quantityInState
      return quantityLeft > 0
    })
  }

  //remove cabins that exists in filteredCabins from allCabinsAndSeats
  const filteredCabinsAndSeats = allCabinsAndSeats.filter((cabin) => {
    return !filterOutCabinsWithNoQuantityLeft(allCabinsAndSeats).find(
      (filteredCabin) =>
        filteredCabin.code === cabin.code &&
        filteredCabin.quantityAvailable - quantityOfEachCabinInState[cabin.code] <= 0,
    )
  })

  const allCabinsAndSeatsSorted = [...filteredCabinsAndSeats]

  const [value, setValue] = useState({
    value: valueFallback,
    code: codeFallback,
  })

  const { outboundSuggestedCabins } = UseGetSuggestedCabinsOutbound({
    passengersToPlace: value.value,
    itemCode: value.code,
  })

  const { inboundSuggestedCabins } = UseGetSuggestedCabinsInbound({
    passengersToPlace: value.value,
    itemCode: value.code,
  })

  const suggestedCabins = useMemo(
    () => (showModal?.isOutbound ? outboundSuggestedCabins : inboundSuggestedCabins),
    [inboundSuggestedCabins, outboundSuggestedCabins, showModal?.isOutbound],
  )

  useEffect(() => {
    if (value.value > passengersYetToPlace) {
      setValue({
        value: 0,
        code: value.code,
      })
    }
  }, [value, passengersYetToPlace])

  useEffect(() => {
    if (suggestedCabins) {
      setSavedSuggested(suggestedCabins || [])
    }

    if (!showModal.showModal && savedSuggested.length > 0) {
      setSavedSuggested([])
    }
  }, [suggestedCabins, savedSuggested.length, showModal.showModal])

  useEffect(() => {
    if (shouldAddSuggested) {
      setTimeout(() => {
        savedSuggested?.map((cabin) => {
          const cab = {
            ...cabin,
          }

          return addCabinsToCart(cab, cabin.quantityInCabin, showModal.isOutbound ? true : false, bookingCode || '')
        })
      }, 50)

      setShouldAddSuggested(false)
    }
  }, [addCabinsToCart, bookingCode, savedSuggested, shouldAddSuggested, showModal.isOutbound])

  useEffect(() => {
    if ((value.code || value.value > 0) && !showModal.showModal) {
      setValue({
        code: '',
        value: 0,
      })
    }
  }, [showModal, value.code, value.value])

  return (
    <div>
      {showModal.showModal && allCabinsAndSeatsSorted ? (
        <Modal
          label=""
          hideHeader
          onRequestClose={() =>
            setShowModal({
              showModal: false,
              isOutbound: showModal.isOutbound,
            })
          }
        >
          <Container>
            {allCabinsAndSeatsSorted?.map((cabin, index) => {
              const key = index + 1
              return (
                <AllCabinsContainer
                  key={key}
                  index={index}
                  cabin={cabin}
                  valueFallback={valueFallback}
                  codeFallback={codeFallback}
                />
              )
            })}
          </Container>
        </Modal>
      ) : null}
    </div>
  )
}

export default AvailableCabins

type AllCabinsContainerProps = {
  cabin: AvailabilityItemWithSpecOptions
  index: number
  valueFallback: number
  codeFallback: string
}
const AllCabinsContainer: React.FC<AllCabinsContainerProps> = ({
  cabin,
  index,
  valueFallback,
  codeFallback,
}: AllCabinsContainerProps) => {
  const { dispatchToast } = useToastDispatcher()

  const { t, i18n } = useTranslation()
  const { bookingCode } = useParams()
  const { showModal, addToCartState } = useAvailabilityItems()
  const { cartData } = useWebSocketContext()
  const { setShowModal, addCabinsToCart, setAddToCartState } = useAvailabilityItemsOperations()
  const { updateCart } = useWebSocketOperationsContext()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const [savedSuggested, setSavedSuggested] = useState<SuggestedCabin[]>([])
  const [shouldAddSuggested, setShouldAddSuggested] = useState(false)
  const cabinsInState = showModal?.isOutbound
    ? addToCartState?.[bookingCode || '']?.['outbound']?.cabins || []
    : addToCartState?.[bookingCode || '']?.['inbound']?.cabins || []
  const filteredCabinsInState = cabinsInState.filter((e) => e.quantityInCabin > 0)
  const passengersPlaced = filteredCabinsInState.reduce((acc, cabin) => acc + cabin.quantityInCabin, 0)
  const passengersToPlace = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const passengersYetToPlace = passengersToPlace - passengersPlaced
  const { allCartsUpdated } = useAddLounge(savedSuggested, cabinsInState)
  const [getSuggestedItemError, setGetSuggestedError] = useState(false)

  const [value, setValue] = useState({
    value: valueFallback,
    code: codeFallback,
  })

  const { outboundSuggestedCabins } = UseGetSuggestedCabinsOutbound({
    passengersToPlace: value.value,
    itemCode: value.code,
  })

  const { inboundSuggestedCabins } = UseGetSuggestedCabinsInbound({
    passengersToPlace: value.value,
    itemCode: value.code,
  })

  const suggestedCabins = useMemo(
    () => (showModal?.isOutbound ? outboundSuggestedCabins : inboundSuggestedCabins),
    [inboundSuggestedCabins, outboundSuggestedCabins, showModal?.isOutbound],
  )
  //Accumulate price of suggestedCabins which is an array, and add value.price togeheter
  const suggestedCabinsPrice = suggestedCabins?.reduce((acc, cabin) => {
    const price = cabin.price.value
    return acc + price
  }, 0)

  useEffect(() => {
    if (value.value > passengersYetToPlace) {
      setValue({
        value: 0,
        code: value.code,
      })
    }
  }, [value, passengersYetToPlace])

  useEffect(() => {
    if (suggestedCabins) {
      setSavedSuggested(suggestedCabins || [])
    }

    if (!showModal.showModal && savedSuggested.length > 0) {
      setSavedSuggested([])
    }
  }, [suggestedCabins, savedSuggested.length, showModal.showModal])

  useEffect(() => {
    if (shouldAddSuggested) {
      setTimeout(() => {
        savedSuggested?.map((cabin) => {
          const cab = {
            ...cabin,
          }

          return addCabinsToCart(cab, cabin.quantityInCabin, showModal.isOutbound ? true : false, bookingCode || '')
        })
      }, 50)

      setShouldAddSuggested(false)
    }
  }, [addCabinsToCart, bookingCode, savedSuggested, shouldAddSuggested, showModal.isOutbound])

  useEffect(() => {
    if ((value.code || value.value > 0) && !showModal.showModal) {
      setValue({
        code: '',
        value: 0,
      })
    }
  }, [showModal, value.code, value.value])

  const showToast = useCallback(
    (message: string) => {
      dispatchToast({
        closeOnActionClick: true,
        message,
        timeout: 5000,
      })
    },
    [dispatchToast],
  )

  const vesselCode = showModal.isOutbound ? flBooking?.outbound?.vesselCode : flBooking?.inbound?.vesselCode
  const { cabinsFromSanity } = useFetchCabins(cabin.code, vesselCode || undefined)
  return (
    <div>
      {index === 0 ? null : <Divider />}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-[2rem]" key={cabin.code}>
        <div>
          <img
            src={imageUrlFor(cabinsFromSanity?.cabinImages?.[0].asset._ref)?.url()}
            alt={cabinsFromSanity?.cabinImages[0].altText?.[i18n.language]}
            width="100%"
          />
        </div>
        <div className="md:col-span-2">
          {' '}
          <h3>{cabinsFromSanity?.cabinName?.[i18n.language] ?? cabin.itemName}</h3>{' '}
          <p className="mb-[var(--spacing-XL)]">
            {cabinsFromSanity?.cabinFullDesc?.[i18n.language]}
          </p>{' '}
          <CabinIcons cabinInfo={cabinsFromSanity} />
        </div>
        <div className="md:col-span-3">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {suggestedCabins && suggestedCabins.length === 0 && getSuggestedItemError && value.code === 'LOUNGE' && (
              <div style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
                <Feedback variant="notification" type="error">
                  {t('component.extras.cabin.loungeError')}
                </Feedback>
              </div>
            )}
            <div>
              <StepperInputWrapper>
                <StepperInput
                  inputAsText
                  max={findMaxCabinSelect(cabin, passengersToPlace, passengersYetToPlace)}
                  min={cabin.specification.minimumQuantityToBook}
                  allowZero={cabin.specification.minimumQuantityToBook > passengersYetToPlace ? false : true}
                  label={t('component.extras.cabin.quantityInCabin')}
                  disabled={cabin.specification.minimumQuantityToBook > passengersYetToPlace}
                  value={value.code === cabin.code ? value.value : 0}
                  price={{
                    currency: flBooking?.currency || Currency.Nok,
                    locale: i18n.language || 'nb',
                    amount:
                      cabin.specification?.options.find((e) => e.maxQuantity >= value.value)?.optionPrice?.value ||
                      suggestedCabinsPrice ||
                      0,
                  }}
                  setValue={(value) =>
                    setValue({
                      value: value,
                      code: cabin.code,
                    })
                  }
                />
              </StepperInputWrapper>
            </div>
            <div className="md:col-span-2">
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    if (value.code === 'LOUNGE' && suggestedCabins && suggestedCabins.length === 0) {
                      setGetSuggestedError(true)
                    }

                    if (value.value === 0) {
                      showToast(t('component.extras.cabin.feedback.toast.minReq'))
                    }

                    if (cabin.specification.minimumQuantityToBook > passengersYetToPlace) {
                      showToast(
                        t('component.extras.cabin.feedback.toast.minOrd', {
                          minOrd: cabin.specification.minimumQuantityToBook,
                          passengersYetToPlace: passengersYetToPlace,
                        }),
                      )
                    } else if (suggestedCabins && suggestedCabins.length > 0) {
                      updateCart({
                        ...cartData,
                        bookingCarts: allCartsUpdated,
                      })
                      setAddToCartState(allCartsUpdated)

                      setShowModal({
                        showModal: false,
                        isOutbound: showModal.isOutbound,
                      })
                    } else if (value.value > 0 && cabin.code !== 'LOUNGE') {
                      addCabinsToCart(cabin, value.value, showModal.isOutbound ? true : false, bookingCode || '')
                    }
                  }}
                  leadingIcon={Add}
                  theme="ghost"
                >
                  {t('label.button.add')}
                </Button>
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
    button {
      width: 100%;
    }
  }
`
