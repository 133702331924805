import React, { JSX } from 'react'
import { Button, Column, Feedback } from '@fjordline/styles-v3'
import { ITextInputStates } from '@fjordline/styles-v3/dist/types/sharedInputTypes'
import styled from 'styled-components'

import { Country, FlCustomer, Gender } from '../../../graphql/types'
import { defaultFjordClubMembership } from '../validation/types'

const VerificationWrapper = styled.div`
  margin-top: -0.5rem;
`

export function getVerifyFeedBackRow({
  isVerified,
  successMessage,
  errorMessage,
  infoBeforeVerifiedMessage,
}: {
  isVerified: boolean
  successMessage: string
  errorMessage?: string
  infoBeforeVerifiedMessage?
}): JSX.Element {
  return (
    <VerificationWrapper>
      {isVerified ? (
        <Feedback dataCy="feedback-verified-mobileNumber-success" type="success">
          {successMessage}
        </Feedback>
      ) : errorMessage ? (
        <Feedback dataCy="feedback-verified-mobileNumber-error" type="error">
          {errorMessage}
        </Feedback>
      ) : infoBeforeVerifiedMessage ? (
        <Feedback dataCy="feedback-verified-mobileNumber-info" type="info">
          {infoBeforeVerifiedMessage}
        </Feedback>
      ) : null}
    </VerificationWrapper>
  )
}

export function getVerificationButtonRow({
  disabled,
  text,
  onClick,
}: {
  disabled: boolean
  text: string
  onClick: () => void
}): JSX.Element {
  return (
    <Column data-testid="123" alignItems="flex-end">
      <Button size="medium" dataCy="verify-mobileNumber-btn" onClick={onClick} type="button" disabled={disabled}>
        {text}
      </Button>
    </Column>
  )
}

export function getActiveClubMembership({ customer }: { customer: FlCustomer | undefined }): FlCustomer {
  return {
    // customerIsActiveCustomerAndMember: customer?.active === true && customer?.customerCode !== undefined,
    active: customer?.active ?? false,
    customerCode: customer?.active === true ? customer?.customerCode : undefined,
    firstName: customer?.firstName ?? '',
    lastName: customer?.lastName ?? '',
    birthDay: customer?.birthDay ?? 0,
    birthMonth: customer?.birthMonth ?? 0,
    birthYear: customer?.birthYear ?? 0,
    gender: customer?.gender || Gender.Female,
    nationalityCode: customer?.nationalityCode ?? defaultFjordClubMembership.nationalityCode,
    address: {
      countryCode: customer?.address?.countryCode ?? ('NO' as Country),
      streetAddress: customer?.address?.streetAddress ?? '',
      postalCode: customer?.address?.postalCode ?? '',
      postalDistrict: customer?.address?.postalDistrict ?? '',
    },
    defaultDeparturePortCode: customer?.defaultDeparturePortCode || 'B',
    customerConsents: customer?.customerConsents,
    meansOfContact: customer?.meansOfContact ?? [],
    personId: customer?.personId ?? '',
  }
}

export function getErrorMessage({
  error,
  touched,
}: {
  error: string | undefined
  touched: boolean | undefined
}): string | undefined {
  if (touched === undefined || !touched) {
    return undefined
  }
  return error !== undefined && touched ? error : undefined
}

export function getInputState({
  error,
  touched,
}: {
  error: string | undefined
  touched: boolean | undefined
}): 'default' | ITextInputStates {
  if (touched === undefined || !touched) {
    return 'default'
  }
  return error !== undefined && touched ? 'error' : 'success'
}

export function getInputStateString({
  error,
  touched,
}: {
  error: string | undefined
  touched: boolean | undefined
}): string {
  if (touched === undefined || !touched) {
    return 'default'
  }
  return error !== undefined && touched ? 'error' : 'success'
}
