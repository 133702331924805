import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import TranslationRenderer from '../../components/TranslationRenderer'
import { StyledFieldset, StyledLegend, StyledLink, SectionTitle } from "./Support.styled"
import { FaInfoCircle, FaChevronUp, FaChevronDown } from "react-icons/fa"
import { RiQuestionAnswerLine, RiExternalLinkLine } from "react-icons/ri";
import styled from "styled-components"
import StringWithComponents from '../../components/StringWithComponents'

const FAQWrapper = styled.section`
  grid-area: 'col1';
  display: flex;
  flex-direction: column;
`

const FAQFooter = styled.div`
  margin-top: 1em;
  display: flex;
  gap: 1em;
  padding: 1em;

  svg {
    color: var(--fl-ocean);
    flex-shrink: 0;
    font-size: 1.5em;
  }
`

const StyledDetails = styled.details`
  border-bottom: solid 1px #d1d5db;
  padding: 1em;
  line-height: 1.7em;

  &[open] {
    background: var(--fl-ocean-50);
  }

  @media (min-width: 768px) {
    padding: 1.2em;
  }
`

const DetailsAnswer = styled.div`
  padding: 1em;
  margin-top: 1em;
  background: var(--fl-ocean-100);

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    padding: 1.2em;
  }
`

const StyledSummary = styled.summary`
  list-style: none;
  cursor: pointer;
  display: flex;
  gap: 1.5em;

  &::-webkit-details-marker {
    display: none;
  }

  span {
    flex-grow: 1;
  }

  svg {
    font-size: 1.3em;
    flex-shrink: 0;
    margin-top: 0.2em;
    color: var(--fl-ocean);
  }
`

const AnswerLabel = styled.span`
  display: flex;
  gap: 0.5em;
  align-items: center;
  font-weight: 500;
  padding-bottom: 0.5em;

  svg {
    font-size: 1.3em;
  }
`

const ExternalStyledLink = styled(StyledLink)`
  display: inline-flex;
  flex-direction: row;
  gap: 0.2em;
  align-items: center;
  margin-right: 0.2em;

  svg {
    font-size: 1.2em;
  }
`

const BoldText = styled.strong`
  font-weight: 500;
`

type ExternalLinkProps = {
  children?: React.ReactNode
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const ExternalLink = ({ children, ...rest }: ExternalLinkProps) => {
  return (
    <ExternalStyledLink {...rest}>
      {children}
      <RiExternalLinkLine />
    </ExternalStyledLink>
  )
}

type FAQItemProps = {
  question: string
  answer: string
}

const faqItemComponents = {
  bold: (props) => <BoldText {...props} />,
  lineBreak: () => <br />
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledDetails name="faq" onToggle={() => setIsOpen(!isOpen)}>
      <StyledSummary>
        <StringWithComponents inputString={question} components={faqItemComponents} />
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </StyledSummary>
      <DetailsAnswer>
        <AnswerLabel>
          <RiQuestionAnswerLine />
          {t('support.labels.answer')}
        </AnswerLabel>
        <p><StringWithComponents inputString={answer} components={faqItemComponents} /></p>
      </DetailsAnswer>
    </StyledDetails>
  )
}

type FAQ = {
  question: string
  answer: string
}

export const SupportFAQ = ({ ...rest }) => {
  const { t } = useTranslation()

  const localizedLinks = t('links', { returnObjects: true }) as { [key: string]: string }
  const localizedFAQ = t('support.faq', { returnObjects: true }) as FAQ[]

  if (!localizedFAQ) return null

  return (
    <StyledFieldset data-cy='faq-section' {...rest}>
      <StyledLegend><RiQuestionAnswerLine /></StyledLegend>
      <SectionTitle>{t('support.titles.faq')}</SectionTitle>
      <FAQWrapper data-cy='faq-list'>
        {localizedFAQ.map((item, index) => (
          <FAQItem data-cy='faq-list-item' key={`faq-${index}`} question={item.question} answer={item.answer} />
        ))}
      </FAQWrapper>
      <FAQFooter>
        <FaInfoCircle />
        <span>
          <TranslationRenderer
            i18nKey='support.moreFAQ'
            components={{
              supportLink: <ExternalLink rel="noopener" target="_blank" href={localizedLinks.customerService} />,
            }}
          />
        </span>
      </FAQFooter>
    </StyledFieldset>
  )
}
