import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Add, Button, Spinner, Ticket } from '@fjordline/styles-v3'

import { ButtonFull } from '../../components/globalStyles'
import UseGetInboundExtras from '../../graphql/availabilityHooks/extras/UseGetInboundExtras'
import UseGetOutboundExtras from '../../graphql/availabilityHooks/extras/UseGetOutboundExtras'
import { Extra } from '../../graphql/types'
import { routerBookingSubPathsStrings } from '../../routes/appRoutes/routerPaths'
import { AddExtrasAshoreNavLink, TicketsNavLink } from '../../routes/navLinkFunctions'

import ExtrasAshore from './sectionExtrasAshore/ExtrasAshore'
import { EASSETS_CODES } from '../../config'
import useFetchExtras from '../../sanity/extras/useFetchExtras'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { isPastDeadline } from '../../utils/isPastDeadline'
import { JSX } from 'react/jsx-runtime'

import { Section, SectionCard } from './SectionComponent'

export default function SectionExtrasAshore({
  bookingCode,
  inboundExtrasAshore,

  journeyPorts,
  outboundExtraAshore,
}: {
  bookingCode: string
  inboundExtrasAshore: Extra[]
  journeyPorts: string
  outboundExtraAshore: Extra[]
}): JSX.Element | null {
  const { flBooking } = useBookingDetails()
  const modifyBookingDeadline = flBooking?.modificationDeadline
  const { t } = useTranslation()
  const hasExtrasAshore = useMemo<boolean>(
    () =>
      (inboundExtrasAshore !== undefined && inboundExtrasAshore.length > 0) ||
      (outboundExtraAshore !== undefined && outboundExtraAshore.length > 0),
    [inboundExtrasAshore, outboundExtraAshore],
  )
  const hasInboundExtrasAshore = useMemo<boolean>(
    () => inboundExtrasAshore !== undefined && inboundExtrasAshore.length > 0,
    [inboundExtrasAshore],
  )

  const hasOutboundExtrasAshore = useMemo<boolean>(
    () => outboundExtraAshore !== undefined && outboundExtraAshore.length > 0,
    [outboundExtraAshore],
  )

  const { outboundExtrasAshore: outboundAvailable, loading: outboundLoading } = UseGetOutboundExtras(bookingCode)
  const { inboundExtrasAshore: inboundAvailable, loading: inboundLoading } = UseGetInboundExtras(bookingCode)

  const loading = outboundLoading || inboundLoading

  const hasExtrasAshoreAvailable =
    (outboundAvailable && outboundAvailable.length > 0) || (inboundAvailable && inboundAvailable.length > 0)
      ? true
      : false

  const hasEassetsTicketsOutbound = outboundExtraAshore.some(
    (extra) => extra.code && EASSETS_CODES.includes(extra.code),
  )
  const hasEassetsTicketsInbound = inboundExtrasAshore.some((extra) => extra.code && EASSETS_CODES.includes(extra.code))

  const eassetsCodesOutbound = outboundExtraAshore.filter((extra) => extra.code && EASSETS_CODES.includes(extra.code))
  const eassetsCodesInbound = inboundExtrasAshore.filter((extra) => extra.code && EASSETS_CODES.includes(extra.code))

  const allEassetsCodes = eassetsCodesOutbound.concat(eassetsCodesInbound)

  const hasEassetsTickets = hasEassetsTicketsOutbound || hasEassetsTicketsInbound

  const showAddExtras = hasExtrasAshoreAvailable && !loading && !isPastDeadline({ modifyBookingDeadline });
  
  return (
    <Section
      data-cy="section-extrasAshore"
      title={t('component.extras.misc.ashoreTitle')}
      classNames={showAddExtras || hasExtrasAshore ? '' : 'hidden'}
      Icon={Ticket}
    >
      {hasExtrasAshore ? (
        <>
          {hasOutboundExtrasAshore ? (
            <SectionCard>
              <ExtrasAshore extras={outboundExtraAshore} />
            </SectionCard>
          ) : null}

          {hasInboundExtrasAshore ? (
            <SectionCard>
              <ExtrasAshore isInbound extras={inboundExtrasAshore} />
            </SectionCard>
          ) : null}

          {hasEassetsTickets ? (
            <NavLink
              to={TicketsNavLink({ bookingCode: bookingCode, backTargetTitle: journeyPorts })}
              key={routerBookingSubPathsStrings.TICKETS}
            >
              <ButtonFull>
                <Button
                  dashedBorder
                  theme="ghost"
                  size="medium"
                  leadingIcon={Ticket}
                  dataCy="btn-section-add-extras-ashore"
                >
                  {t('component.extras.misc.tickets')} (
                    {allEassetsCodes?.map((e, index) => {
                      if (e.code) {
                        const key = e.code + index
                        return <ResolveNameForEassetsInButton key={key} code={e.code} />
                      }
                    })}
                  )
                </Button>
              </ButtonFull>
            </NavLink>
          ) : null}
        </>
      ) : null}

      {showAddExtras ? (
        <NavLink
          to={AddExtrasAshoreNavLink({ bookingCode: bookingCode, backTargetTitle: journeyPorts })}
          key={routerBookingSubPathsStrings.ADD_EXTRAS_ASHORE}
        >
          <ButtonFull>
            <Button disabled={loading} dashedBorder theme="ghost" size="medium" leadingIcon={loading ? Spinner : Add}>
              {t('label.button.addMoreExtraAshore')}
            </Button>
          </ButtonFull>
        </NavLink>
      ) : null}
    </Section>
  )
}

type ResolveNameForEassetsInButtoProps = {
  code: string
}
const ResolveNameForEassetsInButton = ({ code }: ResolveNameForEassetsInButtoProps) => {
  const { findExtrasName } = useFetchExtras(code)
  const name = findExtrasName() ?? code

  return name
}
