import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Add, Banner, Button, Food, FormElementWrapper, Spinner } from '@fjordline/styles-v3'

import { ButtonFull } from '../../components/globalStyles'
import { CDN_IMG_UPGRADE_MEAL } from '../../config'
import UseGetinboundMeals from '../../graphql/availabilityHooks/meals/UseGetInboundMeals'
import UseGetOutboundMeals from '../../graphql/availabilityHooks/meals/UseGetOutboundMeals'
import { routerBookingSubPathsStrings } from '../../routes/appRoutes/routerPaths'
import { AddMealsNavLink } from '../../routes/navLinkFunctions'

import { MealInfoGroupedByDateAndStartTime } from './getBookingObjects/getBookingObjects.types'
import Meals from './sectionMeals/Meals'
import { BannerWrapper } from './SectionCabins'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { isPastDeadline } from '../../utils/isPastDeadline'
import { Section, SectionCard } from './SectionComponent'

/**
 * @description - render meals outbound and inbound if any
 * @param bookingCode
 * @param inboundMealsGroupedByDateAndStartTime
 * @param isActiveOrFuture
 * @param journeyPorts
 * @param outboundMealsGroupedByDateAndStartTime
 * @constructor
 */
export default function SectionMeals({
  bookingCode,
  inboundMealsGroupedByDateAndStartTime,
  journeyPorts,
  outboundMealsGroupedByDateAndStartTime,
}: {
  bookingCode: string
  inboundMealsGroupedByDateAndStartTime: MealInfoGroupedByDateAndStartTime | undefined
  journeyPorts: string
  outboundMealsGroupedByDateAndStartTime: MealInfoGroupedByDateAndStartTime | undefined
}): JSX.Element | null {
  const { flBooking } = useBookingDetails()
  const modifyBookingDeadline = flBooking?.modificationDeadline
  const { t } = useTranslation()
  const hasMeals = useMemo<boolean>(
    () => inboundMealsGroupedByDateAndStartTime !== undefined || outboundMealsGroupedByDateAndStartTime !== undefined,
    [inboundMealsGroupedByDateAndStartTime, outboundMealsGroupedByDateAndStartTime],
  )
  const hasInboundMeals = useMemo<boolean>(
    () => inboundMealsGroupedByDateAndStartTime !== undefined,
    [inboundMealsGroupedByDateAndStartTime],
  )

  const hasOutboundMeals = useMemo<boolean>(
    () => outboundMealsGroupedByDateAndStartTime !== undefined,
    [outboundMealsGroupedByDateAndStartTime],
  )

  const { data: outboundMeals, loading: outboundLoading } = UseGetOutboundMeals(bookingCode)
  const { data: inboundMeals, loading: inboundLoading } = UseGetinboundMeals(bookingCode)
  const hasOutboundMealsAvailable = outboundMeals && outboundMeals.length > 0
  const hasInboundMealsAvailable = inboundMeals && inboundMeals.length > 0
  const hasMealsAvailable = hasOutboundMealsAvailable || hasInboundMealsAvailable ? true : false
  const loading = outboundLoading || inboundLoading

  return (
    <Section data-cy="section-meals" title={t('component.extras.meal.title')} Icon={Food}>
      {hasMeals ? (
        <>
          {hasOutboundMeals ? (
            <SectionCard>
              <Meals meals={outboundMealsGroupedByDateAndStartTime} />
            </SectionCard>
          ): null }

          {hasInboundMeals ? (
            <SectionCard>
              <Meals isInbound meals={inboundMealsGroupedByDateAndStartTime} />
            </SectionCard>
          ) : null}
        </>
      ) : null}
      {hasMealsAvailable && !loading && !isPastDeadline({ modifyBookingDeadline }) ? (
        <NavLink
          to={AddMealsNavLink({ bookingCode: bookingCode, backTargetTitle: journeyPorts })}
          key={routerBookingSubPathsStrings.ADD_MEALS}
        >
          <FormElementWrapper>
            <BannerWrapper>
              <Banner
                label=""
                message={`${t('component.extras.meal.add')}`} // TODO: get message from Sanity
                image={{
                  src: CDN_IMG_UPGRADE_MEAL,
                  alt: `${t('component.extras.meal.add')}`,
                }}
                enableClickEverywhere
              />
            </BannerWrapper>
          </FormElementWrapper>
          <ButtonFull>
            <Button
              dataCy="btn-section-add-meals"
              disabled={loading}
              dashedBorder
              theme="ghost"
              size="medium"
              leadingIcon={loading ? Spinner : Add}
            >
              {t('component.extras.meal.add')}
            </Button>
          </ButtonFull>
        </NavLink>
      ) : null}
    </Section>
  )
}
