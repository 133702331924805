import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Element } from 'react-scroll'
import {
  Button,
  ChevronDown,
  Column,
  CountrySelect,
  Feedback,
  FormActionsWrapper,
  FormElementWrapper,
  Input,
  NationalitySelect,
  Paragraph,
  Row,
  Select,
  Spinner,
  Switch,
  useToastDispatcher,
} from '@fjordline/styles-v3'
import { useFormik } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'

import CustomBackButton from '../../components/CustomBackbutton'
import { logDev } from '../../components/LogDev'
import { Country, FlCustomer, Gender, MeansOfContactType } from '../../graphql'
import useAnchor, { anchors } from '../../hooks/useAnchors'
import { useMyPage } from '../../providers/myPageStateProvider/context'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { useWebSocketOperationsContext } from '../../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import { mobileList } from '../../storage/StateStorage'
import { createBirthdayLists } from './editMemberShip/createBirthdayLists'
import {
  getActiveClubMembership,
  getInputState,
  getVerificationButtonRow,
  getVerifyFeedBackRow,
} from './editMemberShip/functions'
import PortOptions, { TOption } from './editMemberShip/portOptions'
import { AlertMessageWrapper } from './editMemberShip/styles'
import { AddVerifiedMobile, AddVerifiedMobileArgs, VerifiedMobile } from './editMemberShip/types'
import VerifyEmailMenuDialog from './editMemberShip/VerifyModalMenuDialog'
import { fjordClubYupSchema } from './validation/fjordClubYupSchema'
import { defaultFjordClubMembership } from './validation/types'
import { useGenderOptions } from '../../hooks/useGenderOptions'
import { trackEvent } from '../../providers/TelemetryProvider'
import PhoneInput from '../../components/PhoneInput'
import { useKeycloak } from '../../providers/KeycloakProvider'

import TranslationRenderer from '../../components/TranslationRenderer'
import { ValidationFeedback } from '../../components/ValidationFeedback'

const EditMember: React.FC = () => {
  const { userEmail, isAuthenticated: kc_isAuth, isLoading } = useKeycloak()
  const isAuthenticated = kc_isAuth

  const user = { email: userEmail }

  const { customer } = useMyPage()

  const { setFlCustomerLoading, flCustomerLoading, setCustomerSuccessUpdated, customerSuccessUpdated } =
    useWebSocketContext()
  const { updateFlCustomer, createFlCustomer } = useWebSocketOperationsContext()
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language
  const portOptions = useMemo(() => PortOptions(t), [t])
  const { pathname } = useLocation()
  useAnchor({ currentPath: pathname })
  const isMember = useMemo<boolean>(() => (customer?.active === true ? true : false), [customer?.active])
  const { daysList, months, fcSignUpList } = createBirthdayLists()
  const [showVerifyEmail, setShowVerifyEmail] = useState<boolean>(false)
  const { dispatchToast } = useToastDispatcher()
  const navigate = useNavigate()
  const location = useLocation()
  const wasNonMember = sessionStorage.getItem('nonMember') === 'true'
  const { genders } = useGenderOptions()

  useEffect(() => {
    if (isMember === false) {
      sessionStorage.setItem('nonMember', 'true')
    } else if (isMember && sessionStorage.getItem('nonMember') === 'true') {
      sessionStorage.removeItem('nonMember')
    }
  }, [isMember])

  useEffect(() => {
    if (customerSuccessUpdated) {
      setCustomerSuccessUpdated(false)
      if (!wasNonMember) {
        dispatchToast({
          message: t('component.fjordClub.updated'),
          timeout: 5000,
        })
      } else {
        // Why the timeout? React is re-rendering or unmounting the component before navigate can execute
        // Wrapping it in a timeout ensures that React finishes its updates before the navigation is triggered
        // This is very much a bandaid that fixes the symptoms, not the problems. We need to investigate further to properly fix this
        setTimeout(() => {
          navigate('/fjordClub/signedUp')
        }, 0)
      }
    }
  }, [customer?.active, customerSuccessUpdated, dispatchToast, navigate, setCustomerSuccessUpdated, t, wasNonMember])

  const onSubmit = (values, actions) => {
    actions.validateForm().then((r) => {
      if (Object.keys(r).length === 0) {
        actions.setSubmitting(true)
        setFlCustomerLoading(true)
        if (customer?.active === false || customer === undefined) {
          //Constens on Creation of a new customer. Email and newsletter should default to try, and be disabled on creation (On the client.)
          createFlCustomer({
            ...values,
            customerCode: undefined,
            personId: undefined,
            customerConsents: {
              consents: {
                email: true,
                newsletter: true,
                mail: values.customerConsents?.consents.mail,
                sms: values.customerConsents?.consents.sms,
              },
            },
          })
            .then((r) => {
              logDev('createFlCustomer ', r)
              trackEvent('user signed up for fjord club')
            })
            .catch((e) => {
              logDev('createFlCustomer error', e)
            })
            .finally(() => {
              actions.setSubmitting(false)
            })
        } else {


            const updatedCustomer = {
              ...values,
              customerConsents: {
                consents: {
                  email: values.customerConsents?.consents.email,
                  newsletter: values.customerConsents?.consents.email,
                  mail: values.customerConsents?.consents.mail,
                  sms: values.customerConsents?.consents.sms,
                },
              },
            }

            if (customer?.customerConsents?.uid) {
              updatedCustomer.customerConsents.uid = customer.customerConsents.uid
            }
            if (customer?.customerConsents?.personId) {
              updatedCustomer.customerConsents.personId = customer.customerConsents.personId
            }
            if (customer?.customerConsents?.created) {
              updatedCustomer.customerConsents.created = customer.customerConsents.created
            }
            if (customer?.customerConsents?.lastModified) {
              updatedCustomer.customerConsents.lastModified = customer.customerConsents.lastModified
            }

            updateFlCustomer(updatedCustomer)
            .then((r) => {
              trackEvent('user updated fjord club membership')
              logDev('updateFlCustomerResult', r)
            })
            .catch((e) => {
              logDev('updateFlCustomer error', e)
            })
            .finally(() => {
              actions.setSubmitting(false)
            })
        }
      }
    })
  }

  const memberValidationSchema: yup.Schema<FlCustomer> = useMemo(() => fjordClubYupSchema(t), [t])

  const { values, handleSubmit, errors, touched, isSubmitting, handleBlur, handleChange, setValues, isValid } =
    useFormik<FlCustomer>({
      initialValues: isMember && customer ? { ...customer } : { ...defaultFjordClubMembership },
      validationSchema: memberValidationSchema,
      onSubmit,
    })

  const disabledForm = isSubmitting || flCustomerLoading

  //fallbackvalues for refresh of browser
  useEffect(() => {
    const customerValues = {
      ...values,
      customerConsents: {
        ...customer?.customerConsents,
        consents: {
          email: values.customerConsents?.consents.email,
          newsletter: values.customerConsents?.consents.email,
          mail: values.customerConsents?.consents.mail,
          sms: values.customerConsents?.consents.sms,
        },
      },
      meansOfContact: [
        {
          type: MeansOfContactType.Mob,
          value: customer?.meansOfContact?.find((e) => e.type === 'MOB')?.value as string,
        },
        {
          type: MeansOfContactType.Email,
          value: user?.email as string,
        },
      ],
      active: customer?.active === true ? true : false,
    }

    sessionStorage.setItem('form_values', JSON.stringify(customerValues))
  }, [customer, customer?.active, user?.email, values])

  const [verifiedMobileList, setVerifiedMobileList] = useState<VerifiedMobile>(mobileList.getVerifiedList())
  const currentMobileIsVerified = useMemo(() => {
    const g = Object.keys(verifiedMobileList).find(
      (number) => number === values.meansOfContact?.find((e) => e.type === 'MOB')?.value,
    )

    return g !== undefined
  }, [values.meansOfContact, verifiedMobileList])

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const clubMembership =
        customer?.active === true ? getActiveClubMembership({ customer }) : defaultFjordClubMembership

      setValues({
        ...clubMembership,
      })
      if (clubMembership.meansOfContact && clubMembership.meansOfContact.find((e) => e.type === 'MOB')?.value) {
        mobileList.add(clubMembership.meansOfContact.find((e) => e.type === 'MOB')?.value as string)
        setVerifiedMobileList(mobileList.getVerifiedList())
      }
    }
  }, [customer, i18n.language, isAuthenticated, isLoading, setValues])

  const addVerifiedMobile: AddVerifiedMobile = ({ MOB }: AddVerifiedMobileArgs) => {
    const newVerifiedMobileList: VerifiedMobile = {
      ...verifiedMobileList,
      [MOB]: {
        timeStamp: new Date().toUTCString(),
      },
    }
    setVerifiedMobileList(newVerifiedMobileList)
    mobileList.add(MOB)
  }
  useEffect(() => {
    if (location.pathname.includes('signupAdd') && customer?.customerCode && customer.customerCode?.length > 0) {
      navigate('/fjordClub/editMemberShip')
    }
  }, [customer?.customerCode, location.pathname, navigate])

  const phoneNumberVerificationButton = useMemo(() => {
    return getVerificationButtonRow({
      disabled:
        (isMember && currentMobileIsVerified) ||
        Object.keys(verifiedMobileList).includes(values.meansOfContact?.find((e) => e.type === 'MOB')?.value as string),
      text: t('label.personalia.verify', { value: t('label.personalia.mobile').toLowerCase() }),
      onClick: () => setShowVerifyEmail(true),
    })
  }, [currentMobileIsVerified, isMember, t, values?.meansOfContact, verifiedMobileList])

  const isLastEligbleYear = values.birthYear === fcSignUpList[fcSignUpList.length - 1]?.id
  const monthsTouse = useMemo(() => {
    if (isLastEligbleYear) {
      return months.filter((e) => e?.id <= new Date().getMonth() + 1)
    } else return months
  }, [isLastEligbleYear, months])

  const daysToUse = useMemo(() => {
    if (isLastEligbleYear && values.birthMonth === new Date().getMonth() + 1) {
      return daysList.filter((e) => e?.id <= new Date().getDate())
    } else return daysList
  }, [daysList, isLastEligbleYear, values.birthMonth])

  useEffect(() => {
    if (isLastEligbleYear && values.birthMonth > monthsTouse[monthsTouse.length - 1]?.id) {
      setValues({
        ...values,
        birthMonth: monthsTouse[monthsTouse.length - 1]?.id,
      })
    }
  }, [isLastEligbleYear, monthsTouse, setValues, values])

  useEffect(() => {
    if (isLastEligbleYear && values.birthDay > daysToUse[daysToUse.length - 1]?.id) {
      setValues({
        ...values,
        birthDay: daysToUse[daysToUse.length - 1]?.id,
      })
    }
  }, [isLastEligbleYear, daysToUse, setValues, values])

  //check if form has unsaved changes -> Show modal if true and user is navigating away from page

  useEffect(() => {
    if (!isValid) {
      handleSubmit()
    }
  }, [handleSubmit, isValid])

  const [timerExpired, setTimerExpired] = useState<boolean>(false)

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined

    if (flCustomerLoading) {
      // Start a timer for 30 seconds
      timer = setTimeout(() => {
        setTimerExpired(true)
        setFlCustomerLoading(false) // Set flCustomerLoading to false after 30 seconds
      }, 60000)
    } else {
      // Clear the timer if flCustomerLoading becomes false
      if (timer) {
        clearTimeout(timer)
      }
    }

    // Clean up the timer when the component unmounts or when flCustomerLoading changes
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [flCustomerLoading, setFlCustomerLoading])

  const mobileNumber = (values.meansOfContact?.find((e) => e.type === 'MOB')?.value as string) ?? ''
  const hasMobileNumberLength = mobileNumber?.length > 6

  useEffect(() => {
    sessionStorage.removeItem('register_fc')
  }, [])

  const localizedLinks = t('links', { returnObjects: true }) as { [key: string]: string }

  const personaliaErrors = getValidationErrors(['firstName', 'lastName', 'nationalityCode', 'gender'], errors)
  const addressErrors = getValidationErrors(
    ['countryCode', 'postalDistrict', 'postalCode', 'streetAddress'],
    errors.address,
  )
  const preferencesErrors = getValidationErrors(
    ['birthYear', 'birthMonth', 'birthDay', 'defaultDeparturePortCode'],
    errors,
  )

  // Phone number is not handled in the errors object like other fields
  if (!hasMobileNumberLength) {
    personaliaErrors.push(t('validation.fcRequiredField', { field: t('label.personalia.mobile') }))
  } else if (!currentMobileIsVerified) {
    personaliaErrors.push(t('component.fjordClub.mustVerifyMobileFeedback'))
  }

  return (
    <FormWrapper style={{ maxWidth: '50rem' }}>
      <FormElementWrapper>
        <CustomBackButton defaultBackLinkTo={customer?.active === true ? '/fjordClub' : '/bookingList'} />
      </FormElementWrapper>
      {showVerifyEmail ? (
        <VerifyEmailMenuDialog
          data-cy="verify-email-dialog"
          setShowVerifyEmail={setShowVerifyEmail}
          mobile={values.meansOfContact?.find((e) => e.type === 'MOB')?.value as string}
          addVerifiedMobile={addVerifiedMobile}
        />
      ) : null}
      <h1>{t('component.fjordClub.header')}</h1>
      {customer?.active !== true ? (
        <>
          <h2>{t('component.fjordClub.join')}</h2>
          <TranslationRenderer i18nKey="component.fjordClub.join_description" isArray ParagraphComponent={Paragraph} />
        </>
      ) : null}
      <form onSubmit={handleSubmit} autoComplete="off">
        <Element name={anchors.fjordClubMobile} />

        <StyledFieldset>
          <StyledLegend>{t('label.personalia.personalData')}</StyledLegend>
          <FormElementWrapper>
            <Row>
              <Column large={6} medium={6}>
                <Input
                  disabled={disabledForm}
                  id="firstName"
                  label={t('label.personalia.firstName')}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('placeholder.personalia.firstName')}`}
                  state={getInputState({ error: errors.firstName, touched: touched.firstName })}
                  type="search"
                  value={values.firstName}
                  data-cy="input-firstName"
                />
              </Column>
              <Column large={6} medium={6}>
                <Input
                  disabled={disabledForm}
                  id="lastName"
                  label={t('label.personalia.lastName')}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('placeholder.personalia.lastName')}`}
                  state={getInputState({ error: errors.lastName, touched: touched.lastName })}
                  type="search"
                  value={values.lastName}
                  data-cy="input-lastName"
                />
              </Column>
            </Row>
          </FormElementWrapper>

          <FormElementWrapper>
            <Row>
              <Column large={6} medium={6}>
                <Input
                  data-cy="input-email"
                  disabled={isAuthenticated || disabledForm}
                  id="email"
                  label={t('label.personalia.email')}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('placeholder.personalia.email')}`}
                  type="search"
                  value={user?.email}
                />
              </Column>
              <Column large={6} medium={6}>
                <ColumnWrapper>
                  <PhoneInput
                    data-cy="input-mobile"
                    disabled={disabledForm}
                    inputProps={{ name: 'MOB', id: 'MOB' }}
                    defaultErrorMessage={'error'}
                    enableTerritories
                    enableSearch
                    country={customer?.active ? undefined : (values.nationalityCode?.toLowerCase() as Country)}
                    label={t('label.personalia.mobile')}
                    onBlur={handleBlur}
                    onChange={(newMobile) => {
                      setValues({
                        ...values,
                        meansOfContact: [
                          {
                            type: MeansOfContactType.Mob,
                            value: `+${newMobile}`,
                          },
                          {
                            type: MeansOfContactType.Email,
                            value: user?.email as string,
                          },
                        ],
                      })
                    }}
                    state={'default'}
                    value={values.meansOfContact?.find((e) => e.type === 'MOB')?.value || ''}
                  />
                </ColumnWrapper>
                <FormElementWrapper>
                  {!currentMobileIsVerified ? (
                    <AlertMessageWrapper>
                      <Feedback type="error">
                        {!currentMobileIsVerified
                          ? t('validation.isRequired')
                          : `${t('label.personalia.verificationRequired', {
                              field: t('label.personalia.mobile').toLowerCase(),
                            })}`}
                      </Feedback>
                    </AlertMessageWrapper>
                  ) : null}
                </FormElementWrapper>

                {getVerifyFeedBackRow({
                  isVerified: Object.keys(verifiedMobileList).includes(
                    values.meansOfContact?.find((e) => e.type === 'MOB')?.value as string,
                  ),
                  successMessage: t('label.personalia.verificationCompleted', {
                    field: t('label.personalia.mobile'),
                  }),
                  errorMessage: '',
                  infoBeforeVerifiedMessage: undefined,
                })}

                {!currentMobileIsVerified && hasMobileNumberLength ? phoneNumberVerificationButton : null}
              </Column>
            </Row>
          </FormElementWrapper>

          <FormElementWrapper>
            <Row>
              <Column large={6} medium={6}>
                <ColumnWrapper>
                  <NationalitySelect
                    data-cy="select-nationality"
                    disabled={disabledForm}
                    state={getInputState({ error: errors.nationalityCode, touched: touched.nationalityCode })}
                    label={t('label.address.nationality')}
                    onChange={(e) => {
                      if (e.target.value.length > 0)
                        setValues({ ...values, nationalityCode: e.target.value.toUpperCase() as Country })
                    }}
                    placeholder={t('placeholder.address.nationality')}
                    locale={currentLocale as 'en' | 'da' | 'de' | 'nb' | 'nl'}
                    value={values.nationalityCode?.toUpperCase() || ''}
                  />
                </ColumnWrapper>
              </Column>
              <Column large={6} medium={6}>
                <Select
                  data-cy="select-gender"
                  state={getInputState({ error: errors.gender, touched: touched.gender })}
                  label={t('label.personalia.gender')}
                  name="gender"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      gender: e.target.value as Gender,
                    })
                  }}
                  options={genders}
                  optionTitleFn={(option) => option.label}
                  optionValueFn={(option) => option.value}
                  placeholder={t('placeholder.personalia.gender') as string}
                  value={values.gender as string}
                  disabled={disabledForm}
                />
              </Column>
            </Row>
          </FormElementWrapper>
          <ValidationFeedback errors={personaliaErrors} />
        </StyledFieldset>

        <StyledFieldset>
          <StyledLegend>{t('label.address.header')}</StyledLegend>
          <FormElementWrapper>
            <Row>
              <Column large={6} medium={6}>
                <CountrySelect
                  state={getInputState({ error: errors?.address?.countryCode, touched: touched?.address?.countryCode })}
                  label={t('label.address.country')}
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          countryCode: e.target.value.toUpperCase() as Country,
                        },
                      })
                    }
                  }}
                  placeholder={t('placeholder.address.country')}
                  locale={currentLocale as 'en' | 'da' | 'de' | 'nb'}
                  value={values.address?.countryCode || ''}
                  disabled={disabledForm}
                  data-cy="select-country"
                />
              </Column>
              <Column large={6} medium={6}>
                <Input
                  disabled={disabledForm}
                  id="streetAddress"
                  label={t('label.address.address')}
                  data-cy="input-streetAddress"
                  name="streetAddress"
                  onBlur={(e) =>
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        streetAddress: e.target.value,
                      },
                    })
                  }
                  onChange={(e) =>
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        streetAddress: e.target.value,
                      },
                    })
                  }
                  placeholder={`${t('placeholder.address.address')}`}
                  state={getInputState({
                    error: errors.address?.streetAddress,
                    touched: touched.address?.streetAddress,
                  })}
                  type="text"
                  value={values.address?.streetAddress}
                />
              </Column>
              <Column large={6} medium={6}>
                {' '}
                <Input
                  data-cy="input-postalCode"
                  disabled={disabledForm}
                  id="postalCode"
                  label={t('label.address.zip')}
                  name="postalCode"
                  onBlur={(e) =>
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        postalCode: e.target.value,
                      },
                    })
                  }
                  onChange={(e) =>
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        postalCode: e.target.value,
                      },
                    })
                  }
                  placeholder={`${t('placeholder.address.zip')}`}
                  state={getInputState({ error: errors.address?.postalCode, touched: touched.address?.postalCode })}
                  type="search"
                  value={values.address?.postalCode}
                />
              </Column>
              <Column large={6} medium={6}>
                {' '}
                <Input
                  data-cy="input-postalAddress"
                  disabled={disabledForm}
                  id="postalAddress"
                  label={t('label.address.city')}
                  name="postalAddress"
                  onBlur={(e) =>
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        postalDistrict: e.target.value,
                      },
                    })
                  }
                  onChange={(e) =>
                    setValues({
                      ...values,
                      address: {
                        ...values.address,
                        postalDistrict: e.target.value,
                      },
                    })
                  }
                  placeholder={`${t('placeholder.address.city')}`}
                  state={getInputState({
                    error: errors.address?.postalDistrict,
                    touched: touched.address?.postalDistrict,
                  })}
                  type="search"
                  value={values.address?.postalDistrict}
                />
              </Column>
            </Row>
          </FormElementWrapper>
          <ValidationFeedback errors={addressErrors} />
        </StyledFieldset>

        <StyledFieldset>
          <StyledLegend>{t('label.preferences.header')}</StyledLegend>
          <FormElementWrapper>
            <TranslationRenderer
              i18nKey="component.fjordClub.preferredPortOfDeparture"
              ParagraphComponent={Paragraph}
              components={{
                bold: <span className="font-semibold" />,
              }}
            />
            <Select<TOption>
              data-cy="select-defaultDeparturePortCode"
              disabled={disabledForm}
              id="defaultDeparturePortCode"
              label={t('label.preferences.portOfDeparture')}
              name="defaultDeparturePortCode"
              state={getInputState({
                error: errors.defaultDeparturePortCode,
                touched: touched.defaultDeparturePortCode,
              })}
              onChange={(e) => {
                if (e.target.value && e.target.value.length > 0) {
                  handleChange(e)
                }
              }}
              optionTitleFn={(port) => port.title}
              optionValueFn={(port) => port?.id}
              options={portOptions}
              placeholder={`${t('placeholder.preferences.portOfDeparture')}`}
              value={values.defaultDeparturePortCode || ''}
            />
          </FormElementWrapper>

          <FormElementWrapper>
            <TranslationRenderer
              i18nKey="component.fjordClub.birthdateMessage"
              ParagraphComponent={Paragraph}
              isArray
            />
            <Row>
              <Column large={4} medium={4} small={12}>
                <Select
                  options={fcSignUpList.reverse()}
                  name="birthYear"
                  id="birthYear"
                  data-cy="select-year"
                  disabled={disabledForm}
                  value={values.birthYear}
                  optionTitleFn={(option) => option.title}
                  state={getInputState({ error: errors.birthYear, touched: touched.birthYear })}
                  optionValueFn={(option) => option?.id}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0)
                      setValues({
                        ...values,
                        birthYear: parseInt(e.target.value),
                      })
                  }}
                  label={t('label.date.year')}
                  placeholder={t('label.date.year')}
                />
              </Column>
              <Column large={4} medium={4} small={12}>
                <Select
                  data-cy="select-month"
                  options={monthsTouse}
                  value={values.birthMonth}
                  state={getInputState({ error: errors.birthMonth, touched: touched.birthMonth })}
                  name="birthMonth"
                  id="birthMonth"
                  disabled={disabledForm}
                  optionTitleFn={(option) => option.title}
                  optionValueFn={(option) => option?.id}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0)
                      setValues({
                        ...values,
                        birthMonth: parseInt(e.target.value),
                      })
                  }}
                  label={t('label.date.month')}
                  placeholder={t('label.date.month')}
                />
              </Column>
              <Column large={4} medium={4} small={12}>
                <Select
                  data-cy="select-day"
                  options={daysToUse}
                  value={values.birthDay}
                  state={getInputState({ error: errors.birthDay, touched: touched.birthDay })}
                  name="birthDay"
                  id="birthDay"
                  optionTitleFn={(option) => option.title}
                  disabled={disabledForm}
                  optionValueFn={(option) => option?.id}
                  label={t('label.date.day')}
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 0)
                      setValues({
                        ...values,
                        birthDay: parseInt(e.target.value),
                      })
                  }}
                  placeholder={t('label.date.day')}
                />
              </Column>
            </Row>
          </FormElementWrapper>
          <ValidationFeedback errors={preferencesErrors} />
        </StyledFieldset>

        <StyledFieldset>
          <StyledLegend>{t('label.customer.consents.header')}</StyledLegend>
          <TranslationRenderer
            i18nKey="component.fjordClub.consentsMessage"
            ParagraphComponent={Paragraph}
            components={{
              termsLink: <a className={tailWindClassesLinks} rel="noopener" target="_blank" href={localizedLinks.termsAndConditions} />,
            }}
          />
          <FormElementWrapper>
            <Switch
              checked={values.customerConsents?.consents.mail === true}
              disabled={disabledForm}
              label={t('label.customer.consents.MAIL')}
              name="consentMail"
              data-cy="switch-consentMail"
              onChange={(e) =>
                setValues({
                  ...values,
                  customerConsents: {
                    uid: customer?.customerConsents?.uid || '',
                    personId: customer?.customerConsents?.personId || '',
                    created: customer?.customerConsents?.created || '',
                    lastModified: customer?.customerConsents?.lastModified || '',
                    consents: {
                      email: values.customerConsents?.consents.email,
                      mail: e.target.checked,
                      sms: values.customerConsents?.consents.sms,
                    },
                  },
                })
              }
            />
          </FormElementWrapper>
          <FormElementWrapper>
            <Switch
              data-cy="switch-consentSms"
              checked={values.customerConsents?.consents.sms === true}
              disabled={disabledForm}
              label={t('label.customer.consents.SMS')}
              name="consentSms"
              onChange={(e) =>
                setValues({
                  ...values,
                  customerConsents: {
                    uid: customer?.customerConsents?.uid || '',
                    personId: customer?.customerConsents?.personId || '',
                    created: customer?.customerConsents?.created || '',
                    lastModified: customer?.customerConsents?.lastModified || '',
                    consents: {
                      email: values.customerConsents?.consents.email,
                      mail: values.customerConsents?.consents.mail,
                      sms: e.target.checked,
                    },
                  },
                })
              }
            />
          </FormElementWrapper>
          <FormElementWrapper>
            {customer?.active === true ? (
              <Switch
                name="consentEmail"
                label={t('label.customer.consents.EMAIL')}
                data-cy="switch-consentEmail"
                checked={values.customerConsents?.consents.email === true}
                disabled={disabledForm}
                onChange={(e) =>
                  setValues({
                    ...values,
                    customerConsents: {
                      uid: customer?.customerConsents?.uid || '',
                      personId: customer?.customerConsents?.personId || '',
                      created: customer?.customerConsents?.created || '',
                      lastModified: customer?.customerConsents?.lastModified || '',
                      consents: {
                        email: e.target.checked,
                        newsletter: e.target.checked,
                        mail: values.customerConsents?.consents.mail,
                        sms: values.customerConsents?.consents.sms,
                      },
                    },
                  })
                }
              />
            ) : (
              <Switch name="consentEMail" label={t('label.customer.consents.EMAIL')} checked disabled />
            )}
          </FormElementWrapper>
        </StyledFieldset>

        {!currentMobileIsVerified ? (
          <div style={{ marginBottom: '1rem' }}>
            <Feedback variant="notification" type="warning">
              {t('component.fjordClub.mustVerifyMobileFeedback')}
            </Feedback>
          </div>
        ) : null}

        <FormActionsWrapper>
          <Button
            dataCy="submit-btn"
            type="submit"
            disabled={!currentMobileIsVerified || disabledForm || flCustomerLoading}
            icon={disabledForm || flCustomerLoading ? Spinner : ChevronDown}
            size="large"
          >
            {customer?.active ? t('label.button.save') : t('label.button.join')}
          </Button>
        </FormActionsWrapper>
        {timerExpired ? (
          <FormElementWrapper>
            <Feedback type="warning" variant="notification">
              {isMember ? t('component.fjordClub.fcMemberUpdateFailed') : t('component.fjordClub.fcMemberCreateFailed')}
            </Feedback>
          </FormElementWrapper>
        ) : null}
        <StyledHr />
        <FormElementWrapper>
          <TranslationRenderer
            i18nKey="component.fjordClub.termsMessage"
            isArray
            ParagraphComponent={Paragraph}
            components={{
              privacyPolicy: <a className={tailWindClassesLinks} rel="noopener" target="_blank" href={localizedLinks.privacyPolicy} />,
              customerService: <a className={tailWindClassesLinks} rel="noopener" target="_blank" href={localizedLinks.customerService} />,
              myPage: <a className={`${tailWindClassesLinks} font-semibold`} rel="noopener" target="_blank" href={localizedLinks.myPage} />
            }}
          />
        </FormElementWrapper>
      </form>
    </FormWrapper>
  )
}

export default EditMember

const StyledHr = styled.hr`
  border-top: solid 1px #d1d5db;
  border-bottom: 0;
  margin: 1em 0;
`

const ColumnWrapper = styled.div`
  min-width: 100% !important;

  label {
    min-width: 100%;
  }
`

const FormWrapper = styled.div`
  svg {
    display: none;
  }
`

const StyledFieldset = styled.fieldset`
  padding: 1em;
  margin: 2em 0;
  border: solid 1px #d1d5db;
`

const StyledLegend = styled.legend`
  padding: 0 1em;
  font-size: 0.875rem;
`

const tailWindClassesLinks = 'font-medium text-[#407f7f] underline'

export function deepEqual(obj1: FlCustomer, obj2: FlCustomer) {
  if (obj1 === obj2) {
    return true
  }

  if (obj1 == null || typeof obj1 !== 'object' || obj2 == null || typeof obj2 !== 'object') {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

const getValidationErrors = (keys: string[], errors) => {
  if (!keys || keys.length === 0 || !errors || errors.length === 0) return []

  return keys
    .map((key: string) => {
      if (key in errors) return errors[key]
    })
    .filter(Boolean)
}
