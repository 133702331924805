/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TFunction } from 'i18next'
import * as yup from 'yup'

import { Consent, Country, FlCustomer, Gender, MeansOfContactType } from '../../../graphql'

import {
  isAlphabeticNameRegEx,
  isAlphaNumeric,
  isCurrentlyValidPostalInfoRegEx,
  isMobile,
  isPostalCode,
} from './regExTemplates'

export const fjordClubYupSchema: (t: TFunction<string>) => yup.Schema<FlCustomer> = (t) => {
  const isAlphabeticValidationMessage = t('validation.isAlphabetic')
  const isAlphanumericValidationMessage = t('validation.isAlphanumeric')
  const isEmailMessage = t('validation.isEmail')
  const isMobileMessage = t('validation.isMobile')
  const isRequiredValidationMessage = t('validation.isRequired')
  const isPostalCodeMessage = t('validation.isPostalCode')

  const MeansOfContactSchema = yup.object().shape({
    type: yup.string().oneOf(Object.values(MeansOfContactType)).required(isMobileMessage),
    value: yup.string().required(isMobileMessage),
  })

  const CustomerAddressSchema = yup.object().shape({
    countryCode: yup
      .string()
      .oneOf(Object.values(Country))
      .required(t('validation.fcRequiredField', { field: t('label.address.country')})),
    postalDistrict: yup
      .string()
      .required(t('validation.fcRequiredField', { field: t('label.address.city')}))
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    postalCode: yup.string().required(t('validation.fcRequiredField', { field: t('label.address.zip')})).matches(isPostalCode, isPostalCodeMessage),
    streetAddress: yup
      .string()
      .required(t('validation.fcRequiredField', { field: t('label.address.address')}))
      .matches(isAlphaNumeric, isAlphanumericValidationMessage),
  })

  const schema: yup.Schema<FlCustomer> = yup.object().shape({
    active: yup.boolean().required(),
    customerCode: yup.string(),
    firstName: yup
      .string()
      .required(t('validation.fcRequiredField', { field: t('label.personalia.firstName')}))
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    lastName: yup
      .string()
      .required(t('validation.fcRequiredField', { field: t('label.personalia.lastName')}))
      .matches(isAlphabeticNameRegEx, isAlphabeticValidationMessage),
    gender: yup.string().oneOf(Object.values(Gender)).required(t('validation.fcRequiredField', { field: t('label.personalia.gender')})),
    birthYear: yup.number().required(t('validation.fcRequiredField', { field: t('label.date.year')})),
    birthMonth: yup.number().required(t('validation.fcRequiredField', { field: t('label.date.month')})),
    birthDay: yup.number().required(t('validation.fcRequiredField', { field: t('label.date.day')})),
    nationalityCode: yup.string().oneOf(Object.values(Country)).required(t('validation.fcRequiredField', { field: t('label.address.nationality')})),
    meansOfContact: yup.array().of(MeansOfContactSchema).required(),
    address: CustomerAddressSchema.required(),
    defaultDeparturePortCode: yup.string().required(t('validation.fcRequiredField', { field: t('label.preferences.portOfDeparture')})),
    consents: yup.array<Consent[]>(),
    personId: yup.string(),
  })
  return schema
}
