import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ArrowLeft,
  Button,
  Close,
  Container,
  Feedback,
  FormActionsWrapper,
  Modal,
  Paragraph,
  Spinner,
} from '@fjordline/styles-v3'
import i18next, { t } from 'i18next'

import UseGetBookings from '../../graphql/customerHooks/UseGetBookings'
import { GroupedBookingResponse } from '../../graphql/types'
import { useMyPageOperations } from '../../providers/myPageStateProvider/context'
import getIndexedBookingLists from '../../providers/myPageStateProvider/websocketProvider/customerAndBookingListLoader/getIndexedBookingLists'
import { extractBookingNumber } from '../../providers/WebsocketProvider'

import useCancelBooking from './useCancelBooking'
import { StackedButton } from '../../components/StackedButton'

type CancelBookingProps = {
  variant?: 'normal' | 'stacked'
}

function CancelBooking({ variant = 'normal' }: CancelBookingProps) {
  const { cancelBooking, cancelling, error, cancellationFee, getCancellationFee, isCancelled } = useCancelBooking()
  const { bookings } = UseGetBookings()
  const hasUpdatedBooking = sessionStorage.getItem('updatedBookingList') !== null
  const updatedBookingList = hasUpdatedBooking
    ? (JSON.parse(sessionStorage.getItem('updatedBookingList') || '{}') as GroupedBookingResponse)
    : bookings

  const location = useLocation()
  const bookingNumber = extractBookingNumber(location)
  const { setBookingList } = useMyPageOperations()
  const [openCancelModal, setOpenCancelModal] = React.useState(false)

  const indexedBookings = useCallback(() => {
    if (isCancelled) {
      const currentBooking = updatedBookingList?.future.filter((e) => e.bookingCode === bookingNumber) || []
      const cancelled = updatedBookingList?.cancelled || []
      const newCancelled = cancelled.concat(currentBooking)
      const newBookings: GroupedBookingResponse = {
        ongoing: updatedBookingList?.ongoing ?? [],
        future: updatedBookingList?.future.filter((e) => e.bookingCode !== bookingNumber) ?? [],
        passed: updatedBookingList?.passed ?? [],
        cancelled: newCancelled,
      }
      const indexedBookingLists = getIndexedBookingLists({ bookings: newBookings })
      sessionStorage.setItem('updatedBookingList', JSON.stringify(newBookings))
      sessionStorage.setItem('updatedIndexedBookingList', JSON.stringify(indexedBookingLists))
      setBookingList(indexedBookingLists)
    }
  }, [bookingNumber, isCancelled, setBookingList, updatedBookingList])

  useEffect(() => {
    if (isCancelled) {
      indexedBookings()
    }
  }, [indexedBookings, isCancelled])

  const navigate = useNavigate()
  useEffect(() => {
    if (isCancelled && !openCancelModal) {
      navigate('bookingList')
    }
  }, [isCancelled, navigate, openCancelModal])

  const handleClick = () => {
    getCancellationFee()
    setOpenCancelModal(true)
  }

  const buttonLabel = t('component.cancelBooking.title')

  return (
    <>
      { variant === 'stacked' ? (
        <StackedButton onClick={handleClick} disabled={cancelling} data-cy="btn-cancel-booking">
          <Close width={20} height={20} />
          <span>{buttonLabel}</span>
        </StackedButton>
      ): (
        <Button
          onClick={handleClick}
          disabled={cancelling}
          leadingIcon={cancelling ? Spinner : undefined}
          size="medium"
          theme="warning"
          dataCy="btn-cancel-booking"
        >
          {buttonLabel}
        </Button>
      )}
      {error && error.response && error.response && !cancellationFee ? (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Feedback type="error" variant="notification">
            {t('error.booking.cancellationFailed')}
          </Feedback>
        </div>
      ) : null}
      {cancellationFee && openCancelModal ? (
        <Modal hideHeader label="" onRequestClose={() => setOpenCancelModal(false)}>
          <Container>
            <h2> {t('component.cancelBooking.title')}</h2>
            {!isCancelled ? (
              <>
                <Paragraph>
                  {' '}
                  {t('component.cancelBooking.info')} {t('component.cancelBooking.description')}{' '}
                  <b>
                    <u>
                      <a
                        rel="noreferrer"
                        href={`https://www.fjordline.com/${i18next.language}/p/reise-og-kjopsvilkar#3`}
                        target="_blank"
                      >
                        {t('component.cancelBooking.readMore')}
                      </a>
                    </u>
                  </b>
                </Paragraph>

                <Paragraph>
                  {t('component.cancelBooking.cost')}: {cancellationFee.currency} {cancellationFee.cancellationFee}{' '}
                </Paragraph>
                <Paragraph>
                  <b>{t('component.cancelBooking.disclaimer')}</b>
                </Paragraph>
              </>
            ) : null}

            {isCancelled ? (
              <FormActionsWrapper>
                <Button
                  onClickCapture={() => {
                    navigate('bookingList')
                  }}
                  leadingIcon={ArrowLeft}
                  onClick={() => setOpenCancelModal(false)}
                  theme="ghost"
                  size="medium"
                >
                  {t('label.button.bookingOverview')}
                </Button>
              </FormActionsWrapper>
            ) : (
              <FormActionsWrapper>
                <Button
                  dataCy="btn-go-back"
                  leadingIcon={ArrowLeft}
                  onClick={() => setOpenCancelModal(false)}
                  theme="ghost"
                  size="medium"
                >
                  {' '}
                  {t('component.cancelBooking.dontCancel')}
                </Button>
                <Button
                  disabled={cancelling}
                  leadingIcon={cancelling ? Spinner : undefined}
                  theme="warning"
                  size="medium"
                  dataCy="btn-cancel-booking-final"
                  onClick={() => {
                    cancelBooking()
                  }}
                >
                  {t('component.cancelBooking.title')}
                </Button>
              </FormActionsWrapper>
            )}

            {isCancelled ? (
              <Feedback type="success" variant="notification">
                {' '}
                {t('error.booking.cancelled')}
              </Feedback>
            ) : null}
            {error && error.response && error.response && !cancellationFee ? (
              <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Feedback type="error" variant="notification">
                  {t('error.booking.cancellationFailed')}
                </Feedback>
              </div>
            ) : null}
          </Container>
        </Modal>
      ) : null}
    </>
  )
}

export default CancelBooking
