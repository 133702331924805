import React from 'react'
import { Input, Feedback, FormActionsWrapper, Button, Spinner, MenuDialog } from '@fjordline/styles-v3'
import { t } from 'i18next'
import { useState } from 'react'
import { useGetLoginProvider, providerEnum } from './useHandleLoginProvider'
import { isEmail } from '../pages/fjordClub/validation/regExTemplates'
import { useKeycloak, useKeycloakOperations } from '../providers/KeycloakProvider'

function Login() {
  const [showError, setShowError] = useState(false)
  const { email } = useKeycloak()
  const { login: kc_login, setEmail, register: kc_register } = useKeycloakOperations()
  const { isError, isLoading, refetch } = useGetLoginProvider({ email })
  const [renewModalOpen, setRenewModalOpen] = useState(false)

  const handleLogin = () => {
    if (email === undefined || email === '' || email.length === 0 || !isEmail.test(email)) {
      setShowError(true)
    } else if (email && isEmail.test(email)) {
      refetch().then((data) => {
        const provider = data.data?.data.provider as providerEnum

        if (provider === providerEnum.Keycloak) {
          kc_login()
        }

        if (provider === providerEnum.Renew) {
          setRenewModalOpen(true)
        }

        if (provider === providerEnum.none) {
          sessionStorage.removeItem('token')
          kc_register()
        }
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin()
    }
  }

  return (
    <>
      <h3>{t('component.auth.login')}</h3>
      <Input
        label={t('component.auth.email')}
        data-cy="email-input"
        type="email"
        value={email || ''}
        style={{ maxWidth: '400px' }}
        name="email"
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setEmail(e.target.value.trim())
          setShowError(false)
        }}
      />
      {(showError && email === undefined) || (email && email.length === 0) ? (
        <div style={{ width: '100%', maxWidth: '400px' }}>
          <Feedback variant="notification" type="error">
            {t('validation.isRequired')}
          </Feedback>
        </div>
      ) : null}
      {showError && email && !isEmail.test(email) ? (
        <div style={{ width: '100%', maxWidth: '400px' }}>
          <Feedback variant="notification" type="error">
            {t('validation.isEmail')}{' '}
          </Feedback>
        </div>
      ) : null}
      {isError ? (
        <div style={{ width: '100%', maxWidth: '400px' }}>
          <Feedback variant="notification" type="error">
            {t('component.auth.loginError')}
          </Feedback>
        </div>
      ) : null}
      <FormActionsWrapper>
        <Button
          trailingIcon={isLoading ? Spinner : undefined}
          disabled={isLoading}
          dataCy="login-btn"
          onClick={handleLogin}
          size="large"
        >
          {isLoading ? t('label.button.loading') : t('component.auth.login')}
        </Button>
        <Button
          dataCy="register-btn"
          onClick={() => {
            sessionStorage.setItem('register_fc', 'true')
            kc_register()
          }}
          size="large"
        >
          {t('component.auth.signUp')}
        </Button>
      </FormActionsWrapper>

      {renewModalOpen && (
        <MenuDialog onRequestClose={() => setRenewModalOpen(false)}>
          <div style={{ width: '100%' }}>
            <h3>{t('component.auth.renew.headline')}</h3>
            <p>{t('component.auth.renew.paragraph1')}</p>
            <p style={{ fontWeight: 'bold' }}>{t('component.auth.renew.paragraph2')}</p>
          </div>
          <Button style={{ marginTop: '1rem' }} size="large" onClick={kc_register}>
            {t('component.auth.renew.renewBtn')}
          </Button>
        </MenuDialog>
      )}
    </>
  )
}

export default Login
