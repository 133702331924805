import { useParams } from 'react-router-dom'

import { findPassengerQuantityPerFareCode } from '../../../pages/booking/editOrAddItemsPages/meals/utils'
import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { Currency, FareCode, useGetAvailableCabinsQuery } from '../../types'
import UseRouteExpect from '../route/UseRouteExpect'

function UseGetOutboundCabins(addModalExtrasBookingCode?: string | undefined) {
  const { bookingCode: bookingCodeFromParams } = useParams()
  const bookingCode = addModalExtrasBookingCode || bookingCodeFromParams
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })

  const { expectCabins, routeExpectLoading } = UseRouteExpect({
    originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
  })

  const allowUpgrade = flBooking?.upgradeOptions?.outbound?.canUpgradeCabins && expectCabins

  const journeyCode = flBooking?.outbound?.journeyCode as string

  const { adults, children, infants } = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const passengersToPlace = adults + children + infants

  const { data, loading } = useGetAvailableCabinsQuery({
    variables: {
      requestParams: {
        journeyCode: journeyCode,
        departureDate: flBooking?.outbound?.departureDate as string,
        bookingCode: bookingCode as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.outbound?.departureCode as string,
        destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
        ticketCode: flBooking?.outbound?.ticket?.ticketCode as string,
        hasVehicles: flBooking?.outbound?.vehicles && flBooking?.outbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.outbound?.passengers?.[0].customerCode &&
          flBooking?.outbound?.passengers[0].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.outbound?.vesselCode,
        passengers: [
          {
            code: FareCode.Adult,
            quantity: passengersToPlace,
          },
        ],
      },
    },
    skip:
      flBooking?.isOngoing ||
      flBooking?.upgradeOptions?.canBuyBookingItems === false ||
      routeExpectLoading ||
      !allowUpgrade ||
      journeyCode === undefined ||
      flBooking?.outbound?.departureCode === undefined ||
      flBooking?.outbound?.ticket?.ticketCode === undefined,
  })

  return { data, loading }
}

export default UseGetOutboundCabins
