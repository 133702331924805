import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import
  {
    BedDouble,
    Button,
    Column,
    Container,
    Divider,
    FormActionsWrapper,
    FormElementWrapper,
    Label,
    Modal,
    Paragraph,
    Row,
    StepperInput,
    Trash,
    TrashLight,
  } from '@fjordline/styles-v3'

import { StepperInputWrapper } from '../../components/StepperStateWrapper'
import UseGetBookingDetails from '../../graphql/customerHooks/UseGetBookingDetails'
import { useAvailabilityItems, useAvailabilityItemsOperations } from '../../providers/AvailabilityItemsProvider'
import { cabinToAdd, CartsByBookingCodes } from '../../providers/availabilityItemsProvider/types'
import { useWebSocketContext } from '../../providers/myPageStateProvider/websocketProvider/websocketContext'
import { findPassengerQuantityPerFareCode } from '../booking/editOrAddItemsPages/editCabins/utils'
import { WithImageLeft, MealImage, Grow, LowerButtonForCabin, DividerWrapper } from './meals/meals.styles'
import useFetchCabins from '../../sanity/cabins/useFetchCabins'
import { imageUrlFor } from '../../sanity/imageUrlBuilder'

type Props = {
  isOutbound: boolean
}

const Cabins: React.FC<Props> = ({ isOutbound }: Props) =>
{
  const { bookingCode } = useParams()
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { addToCartState } = useAvailabilityItems()
  const stateItems = addToCartState as CartsByBookingCodes
  const itemsInState = stateItems?.[ bookingCode || '' ]?.[ isOutbound ? 'outbound' : 'inbound' ]?.cabins
  const { addCabinsToCart, resetCabins } = useAvailabilityItemsOperations()
  const hasExistingCabinsInAddToCabinState = itemsInState?.some((e) => e.quantityInCabin <= 0)
  const existingCabins = useMemo(
    () => (isOutbound ? flBooking?.outbound?.cabins ?? [] : flBooking?.inbound?.cabins ?? []),
    [ flBooking?.inbound?.cabins, flBooking?.outbound?.cabins, isOutbound ],
  )

  useEffect(() =>
  {
    if (sessionStorage.getItem('newPriceIsLessThanExistingPrice') === 'true') {
      resetCabins(isOutbound, bookingCode || '')
      sessionStorage.removeItem('newPriceIsLessThanExistingPrice')
    }
  }, [ bookingCode, isOutbound, resetCabins ])

  useEffect(() =>
  {
    if (itemsInState && itemsInState.length > 0 && !hasExistingCabinsInAddToCabinState) {
      existingCabins?.map((e) =>
      {
        return addCabinsToCart(e, 0, isOutbound, bookingCode || '')
      })
    }
  }, [ addCabinsToCart, bookingCode, existingCabins, hasExistingCabinsInAddToCabinState, isOutbound, itemsInState ])

  if (itemsInState) {
    return itemsInState?.map((item: cabinToAdd) =>
    {
      if (item.quantityInCabin > 0) {
        return <CabinByCodeItem key={item?.id} item={item} isOutbound={isOutbound} />
      }
    })
  } else {
    return null
  }
}

export default Cabins

type CabinByCodeProps = {
  item: cabinToAdd
  isOutbound: boolean
}

const CabinByCodeItem: React.FC<CabinByCodeProps> = ({ item, isOutbound }: CabinByCodeProps) =>
{
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { removeCabin } = useAvailabilityItemsOperations()
  const { cartData } = useWebSocketContext()
  const { bookingCode } = useParams()
  const hasPaymentConfig = cartData?.bookingCarts?.[ bookingCode || '' ]?.bookingResult?.paymentConfiguration
    ? true
    : false
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode || '' })
  const { t } = useTranslation()
  const direction = isOutbound ? t('component.extras.common.departure') : t('component.extras.common.arrival')
  const { addToCartState } = useAvailabilityItems()
  const stateItems = addToCartState as CartsByBookingCodes
  const { addCabinsToCart, resetCabins } = useAvailabilityItemsOperations()
  const vesselCode = isOutbound ? flBooking?.outbound?.vesselCode : flBooking?.inbound?.vesselCode
  const { cabinsFromSanity } = useFetchCabins(item.code, vesselCode || undefined)

  const outboundCabinsInState = useMemo(
    () => stateItems?.[ bookingCode || '' ]?.[ 'outbound' ]?.cabins || [],
    [ bookingCode, stateItems ],
  )
  const filteredOutboundCabinsInState = outboundCabinsInState?.filter((e) => e.quantityInCabin > 0)
  const inboundCabinsInState = useMemo(
    () => stateItems?.[ bookingCode || '' ]?.[ 'inbound' ]?.cabins || [],
    [ bookingCode, stateItems ],
  )
  const filteredInboundCabinsInState = inboundCabinsInState.filter((e) => e.quantityInCabin > 0)

  const passengersToPlace = findPassengerQuantityPerFareCode(flBooking?.outbound?.passengers || [])
  const passengersPlacedOutbound = filteredOutboundCabinsInState?.reduce(
    (acc, cabin) => acc + cabin.quantityInCabin,
    0,
  ) as number
  const passengersPlacedInbound = filteredInboundCabinsInState?.reduce(
    (acc, cabin) => acc + cabin.quantityInCabin,
    0,
  ) as number

  const outboundError = useMemo(
    () =>
      flBooking &&
        outboundCabinsInState &&
        outboundCabinsInState.length > 0 &&
        passengersPlacedOutbound !== passengersToPlace
        ? true
        : false,
    [ flBooking, outboundCabinsInState, passengersPlacedOutbound, passengersToPlace ],
  )
  const inboundError = useMemo(
    () =>
      flBooking &&
        inboundCabinsInState &&
        inboundCabinsInState.length > 0 &&
        passengersPlacedInbound !== passengersToPlace
        ? true
        : false,
    [ flBooking, inboundCabinsInState, passengersPlacedInbound, passengersToPlace ],
  )

  const errorType = outboundError ? t('component.extras.common.departure') : t('component.extras.common.arrival')

  const [ modal, setModal ] = useState<boolean>(false)
  useMemo(() =>
  {
    if (flBooking) {
      if (outboundError || inboundError) {
        setModal(true)
      } else {
        setModal(false)
      }
    }
  }, [ flBooking, inboundError, outboundError ])

  return (
    <div key={item?.id} data-cy={item.code}>
      <Row>
        <Column>
          <FormElementWrapper>
            <WithImageLeft>
              <MealImage
                alt={cabinsFromSanity?.cabinName?.[ i18n.language ] || item.code || ''}
                src={
                  imageUrlFor(cabinsFromSanity?.cabinImages?.[ 0 ].asset._ref)?.url() ||
                  cabinsFromSanity?.cabinImages[ 0 ].altText?.[ i18n.language ]
                }
              />
              <Grow>
                <div>
                  <Label>{cabinsFromSanity?.cabinName?.[ i18n.language ] || item.code || ''}</Label>
                </div>
                <div>{`${direction}`}</div>
              </Grow>
            </WithImageLeft>
          </FormElementWrapper>
        </Column>
      </Row>
      <Row>
        <Column large={11.5} medium={11.5} small={10}>
          <StepperInputWrapper>
            <StepperInput
              data-cy="cabin-stepper"
              label=""
              value={item?.quantityInCabin}
              disabled
              setValue={(value) =>
              {
                addCabinsToCart(item, value, isOutbound, bookingCode || '')
              }}
              price={{
                amount: item.price?.value || 0,
                currency: item.price?.currency || '',
                locale: i18n.language,
              }}
              inputAsText
              min={item.quantityInCabin}
              direction="row"
            />
          </StepperInputWrapper>
        </Column>
        {hasPaymentConfig ? null : (
          <Column large={0.5} medium={0.5} small={2}>
            <LowerButtonForCabin>
              <Button
                dataCy="remove-cabin"
                onClick={() => removeCabin(addToCartState, item?.id, isOutbound, bookingCode || '')}
                theme="clean"
                size="small"
                icon={TrashLight}
              />
            </LowerButtonForCabin>
          </Column>
        )}
      </Row>
      <Row>
        <Column medium={12} large={12}>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
        </Column>
      </Row>
      {modal ? (
        <Modal hideHeader label="" onRequestClose={() => setModal(true)}>
          <Container>
            <h2> {t('component.extras.cabin.feedback.basket.missingCabins')}</h2>
            <Paragraph>{t('component.extras.cabin.feedback.basket.allPassengersPlacedError', { errorType })}</Paragraph>
            <FormActionsWrapper>
              <Button
                onClick={() =>
                  navigate(`/bookingList/${bookingCode}/cabins?missing=${outboundError ? 'outbound' : 'inbound'}`)
                }
                trailingIcon={BedDouble}
              >
                {t('component.extras.cabin.feedback.basket.goToCabins')}
              </Button>
              <Button
                onClick={() =>
                {
                  if (bookingCode) {
                    resetCabins(isOutbound, bookingCode)
                  }
                }}
                trailingIcon={Trash}
                theme="ghost"
              >
                {t('component.extras.cabin.feedback.basket.removeCabins')} {errorType}
              </Button>
            </FormActionsWrapper>
          </Container>
        </Modal>
      ) : null}
    </div>
  )
}
