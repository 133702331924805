import { Group, Sunset, BedCrib, Tv, BedDouble, SofaGroup, Wifi, Shower } from '@fjordline/styles-v3'
import { t } from 'i18next'
import React from 'react'
import { ShipCabin } from '../../../../sanity/cabins/types'

import styled from 'styled-components'
type CabinIconsProps = {
  cabinInfo: ShipCabin | undefined
}

type RenderIconBasedOnRoomQuantityProps = {
  max: number
  min: number
}

export const RenderIconBasedOnRoomQuantity: React.FC<RenderIconBasedOnRoomQuantityProps> = ({
  max,
  min,
}: RenderIconBasedOnRoomQuantityProps) => {
  if (max === min) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{min}</>
    )
  } else
    return (
      <>
        {min} - {max}
      </>
    )
}

export const CabinIcons: React.FC<CabinIconsProps> = ({ cabinInfo }: CabinIconsProps) => {
  return (
    <ul className="w-full grid grid-cols-[repeat(auto-fit,_minmax(75px,_75px))] gap-y-4 ml-[-0.8rem] md:ml-[-1.5rem]">
      {cabinInfo?.cabinMaxPersons && cabinInfo?.cabinMaxPersons > 1 ? (
        <IconListItem>
          <Group />
          <span>
            <RenderIconBasedOnRoomQuantity max={cabinInfo?.cabinMaxPersons} min={cabinInfo?.cabinMinPersons} />
          </span>
        </IconListItem>
      ) : null}

      {cabinInfo?.cabinHasWindow ? (
        <IconListItem>
          <Sunset />
          <span>{t('icons.seaView')}</span>
        </IconListItem>
      ) : null}
      {cabinInfo?.cabinRoomForCrib ? (
        <IconListItem>
          <BedCrib />
          <span>{t('icons.roomForCrib')}</span>
        </IconListItem>
      ) : null}

      {cabinInfo?.cabinProperties && cabinInfo?.cabinProperties.length > 0
        ? cabinInfo?.cabinProperties?.map((e, index) => {
            const key = index + 1
            const iconsFromSanity = e

            switch (iconsFromSanity) {
              case 'TV':
                return (
                  <IconListItem key={key}>
                    <Tv />
                    <span>Tv</span>
                  </IconListItem>
                )
              case 'TWINBED':
                return (
                  <IconListItem key={key}>
                    <BedDouble />
                    <span>{t('icons.doubleBed')}</span>
                  </IconListItem>
                )
              case 'SOFABEDDOUBLE':
                return (
                  <IconListItem key={key}>
                    <SofaGroup />
                    <span>{t('icons.sofaBed')}</span>
                  </IconListItem>
                )
              case 'SOFABED':
                return (
                  <IconListItem key={key}>
                    <SofaGroup />
                    <span>{t('icons.sofaBed')}</span>
                  </IconListItem>
                )
              case 'WIFI':
                return (
                  <IconListItem key={key}>
                    <Wifi />
                    <span>Wifi</span>
                  </IconListItem>
                )
              case 'SHOWER':
                return (
                  <IconListItem key={key}>
                    <Shower />
                    <span>{t('icons.shower')}</span>
                  </IconListItem>
                )
              default:
                return null
            }
          })
        : null}
    </ul>
  )
}

export const IconListItem = styled.li`
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  width: 75px;
  justify-items: center;

  > svg {
    max-height: 20px;
    @media (max-width: 768px) {
      max-height: 15px;
    }
  }

  > span {
    width: 100%;
    display: block;
  }
`
