import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AvailabilityMealItemWithSpec, MealsDetailsFragment } from '../../../../graphql/types'
import { IExtras, IMeal } from '../../../../types/myPage/customerBookingDetails/types'
import AccordionMeal from './meal/MealAccordion'
import moment from 'moment/min/moment-with-locales'

type MealsProps = {
  data: AvailabilityMealItemWithSpec[]
  isOutbound: boolean
  startDay?: string
}

export type stateType = {
  [key: string]: {
    [key: string]: {
      meals: IMeal[]
      extrasOnboard: IExtras[]
      extrasAshore: IExtras[]
    }
  }
}

const Meals: React.FC<MealsProps> = ({ data, isOutbound, startDay }: MealsProps) => {
  useMemo(() => window.scrollTo(0, 0), [])
  const { i18n } = useTranslation()
  const journey = isOutbound ? 'outbound' : 'inbound'
  const [meals, setMeals] = useState<AvailabilityMealItemWithSpec[]>([])
  const mealsCopy = [...meals]
  const sortedMeals = mealsCopy.sort((a, b) => {
    const dateA = new Date(a.startTime as string)
    const dateB = new Date(b.startTime as string)
    return dateA.getTime() - dateB.getTime()
  })
  const grouped: Record<string, MealsDetailsFragment[]> = sortedMeals?.reduce((acc, meal) => {
    const itemType = meal.itemType
    if (!acc[itemType as string]) {
      acc[itemType as string] = []
    }
    acc[itemType as string].push(meal)
    return acc
  }, {})
  const formattedStartDay = moment(startDay).locale(i18n.language).format('dddd DD. MMMM YYYY')

  useEffect(() => {
    if (data) {
      setMeals(data)
    }
  }, [data])

  let index = -1

  return (
    <div key={`${index}-${journey}-${data?.[0]?.code}`}>
      <h3>{formattedStartDay}</h3>

      {data &&
        Object.values(grouped).map((groupedMappedItem) => {
          const items = groupedMappedItem as AvailabilityMealItemWithSpec[]
          index += 1
          const itemsWithSameStartTime = Object.values(items).reduce((acc, item) => {
            const key =
              new Date(item.startTime as string).getFullYear() +
              '-' +
              new Date(item.startTime as string).getMonth() +
              '-' +
              new Date(item.startTime as string).getDate()

            if (!acc[key]) {
              acc[key] = []
            }
            acc[key].push(item)
            return acc
          }, []) as unknown as Record<string, MealsDetailsFragment[]> | undefined

          const item = items[0]

          return (
            <CustomAccordionWrapper key={items[0].code}>
              <AccordionMeal
                item={item}
                itemsWithSameStartTime={itemsWithSameStartTime}
                isOutbound={isOutbound}
                meals={meals}
              />
            </CustomAccordionWrapper>
          )
        })}
    </div>
  )
}

export default Meals

const CustomAccordionWrapper = styled.div`
  > div > div > div > div {
    padding: 1rem;
  }
`
