import React from 'react'
import { Modal, DialogContent } from '@fjordline/styles-v3'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import useBreakpoint from '../../hooks/useBreakpoint'
import { ReportIssueForm } from './Form'
import { routerRootPaths } from '../../routes/appRoutes/routerPaths'
import TranslationRenderer from '../TranslationRenderer'
import { REDIRECT_URI } from '../../config'

const StyledDialogContent = styled(DialogContent)`
  margin-top: 1rem;
`

type Props = {
  onClose: () => void
}

export const ReportIssueModal: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()
  const { Breakpoints, breakpoint } = useBreakpoint()

  return (
    <Modal
      dialog={breakpoint !== Breakpoints.Small}
      label={t('component.reportIssue.modalTitle')}
      onRequestClose={onClose}
      ingress={t('component.reportIssue.modalIngress')}
    >
      <p className="p-[0_1.25rem_1rem] md:p-[1rem_4.5rem_0]">
        <TranslationRenderer
          i18nKey="component.reportIssue.modalSupportLink"
          components={{supportLink: <a href={`${REDIRECT_URI.replace(/\/$/, '')}${routerRootPaths.SUPPORT}`} className="text-[#407f7f] font-medium underline"/> }}
        />
      </p>
      <StyledDialogContent>
        <ReportIssueForm onClose={onClose} />
      </StyledDialogContent>
    </Modal>
  )
}
