import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { OfferCircle, Divider } from '@fjordline/styles-v3'
import { Deliverable, ExternalBooking, ExternalRow } from '../../graphql'
import { logDev } from '../../components/LogDev'
import useFetchAccommodations from '../../sanity/accommodations/useFetchAccommodations'
import { useTimeZonedDateFormatter } from '../../hooks/DateFormat'
import { Section, SectionCard } from './SectionComponent'

type Props = {
  externalBookings: ExternalBooking[]
}

export default function SectionExternalBookings({ externalBookings }: Props): JSX.Element | null {
  const { t } = useTranslation()
 
  logDev('externalBookings', externalBookings)

  return (
    <Section title={t('metadata.title.externalBookings')} Icon={OfferCircle} data-cy="section-externalBookings">
      {externalBookings.map((externalBooking: ExternalBooking) => {
        return (
          <SectionCard>
            {externalBooking.externalRows.map((externalRow : ExternalRow, index: number) => (
              <>
                <ExternalRowComponent externalRow={externalRow} key={`${externalRow.code}-${externalRow.cmsCode}`} />
                {index < externalBooking.externalRows.length - 1 && <Divider />}
              </>
            ))}
          </SectionCard>
        )
      })}
    </Section>
  )
}

type ExternalRowComponentProps = {
  externalRow: ExternalRow
}

const ExternalRowComponent = ({ externalRow }: ExternalRowComponentProps) => {
  let type = DeliverableType.Other
  switch (externalRow.code) {
    case 'NROOM':
    case 'DROOM':
      type = DeliverableType.Room
      break
    case 'NBRE':
      type = DeliverableType.Breakfast
      break
    default:
      break
  }

  let accommodation: { name?: string } | null = null
  if (type === DeliverableType.Room) {
    accommodation = useFetchAccommodations(externalRow.cmsCode).accommodation
    if (!accommodation) return null
  }

  const { name } = accommodation ?? {}

  return (
    <article>
      {type !== DeliverableType.Breakfast && <h4 className='font-semibold tracking-wide mb-5'>{name}</h4>}
      {externalRow.deliverables.map((deliverable: Deliverable) => (
        <DeliverableComponent
          key={deliverable.cmsCode}
          deliverable={deliverable}
          accommodation={accommodation}
          type={type}
        />
      ))}
    </article>
  )
}

type DeliverableComponentProps = {
  deliverable: Deliverable
  accommodation: any
  type: DeliverableType
}

type DeliverableRoomComponentProps = {
  deliverable: Deliverable
  accommodation: any
}

type DeliverableOtherComponentProps = {
  deliverable: Deliverable
}

enum DeliverableType {
  Room,
  Breakfast,
  Other
}

const DeliverableComponent = ({ deliverable, accommodation, type } : DeliverableComponentProps) => {
  switch (type) {
    case DeliverableType.Room:
      return <DeliverableRoomComponent deliverable={deliverable} accommodation={accommodation} />
    case DeliverableType.Breakfast:
      return <DeliverableBreakfastComponent deliverable={deliverable} />
    case DeliverableType.Other:
      return <DeliverableOtherComponent deliverable={deliverable} />
  }
}

const DeliverableRoomComponent = ({ deliverable, accommodation } : DeliverableRoomComponentProps) => {
  const { t } = useTranslation()
  const cmsCode = deliverable.cmsCode
  const roomInfo = accommodation?.rooms?.find((e) => e.externalRoomId === cmsCode)
  const name = roomInfo?.name ?? deliverable.name
  const d = useTimeZonedDateFormatter()

  const { checkInTime, checkOutTime } = accommodation ?? {}
  
  return (
    <>
      <DeliverableDetail title={t('component.extras.ashore.checkin')} value={deliverable.startDate ? d(deliverable.startDate, 'HH:mm, d. MMMM yyyy') : checkInTime ?? '-'} />
      <Divider />
      <DeliverableDetail title={t('component.extras.ashore.checkout')} value={deliverable.endDate ? d(deliverable.endDate, 'HH:mm, d. MMMM yyyy') : checkOutTime ?? '-'} />
      <Divider />
      <DeliverableDetail title={t('component.extras.ashore.roomType')} value={name} />
    </>
  )
}

const DeliverableBreakfastComponent = ({ deliverable } : DeliverableOtherComponentProps) => {
  const { t } = useTranslation()
  const d = useTimeZonedDateFormatter()

  return (
    <DeliverableDetail 
      title={t('component.extras.ashore.otherType')} 
      value={`${t('component.extras.ashore.breakfast')}: ${deliverable.startDate ? d(deliverable.startDate, 'HH:mm') : '-'} - ${deliverable.endDate ? d(deliverable.endDate, 'HH:mm') : '-'}`} 
    />
  )
}

const DeliverableOtherComponent = ({ deliverable } : DeliverableOtherComponentProps) => {
  const { t } = useTranslation() 
  const d = useTimeZonedDateFormatter()

  return (
    <>
      <DeliverableDetail title={t('component.extras.ashore.checkin')} value={deliverable.startDate ? d(deliverable.startDate, 'HH:mm, d. MMMM yyyy') : '-'} />
      <Divider />
      <DeliverableDetail title={t('component.extras.ashore.checkout')} value={deliverable.endDate ? d(deliverable.endDate, 'HH:mm, d. MMMM yyyy') : '-'} />
      <Divider />
      <DeliverableDetail title={t('component.extras.ashore.otherType')} value={deliverable.name} />
    </>
  )
}

const DeliverableDetail = ({ title, value }: { title: string, value: string }) => (
  <p className="py-2">
    <h5 className="small-text-semibold">{title}</h5>
    <span>{value}</span>
  </p>
)
