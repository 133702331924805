import { useParams } from 'react-router-dom'

import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { Currency, useGetAvailableExtrasQuery } from '../../types'
import UseRouteExpect from '../route/UseRouteExpect'
import { useMemo } from 'react'
import useFetchExtrasMultipleExtras from '../../../sanity/extras/useFetchMultipleExtras'
import { useTranslation } from 'react-i18next'

function UseGetOutboundExtras(addModalExtrasBookingCode?: string | undefined) {
  const { bookingCode: bookingCodeFromParams } = useParams()
  const bookingCode = addModalExtrasBookingCode || bookingCodeFromParams
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })
  const journeyCode = flBooking?.outbound?.journeyCode as string

  const { expectExtras, routeExpectLoading } = UseRouteExpect({
    originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
  })

  const allowUpgrade = flBooking?.upgradeOptions?.outbound?.canUpgradeExtras && expectExtras

  const { data, loading, refetch } = useGetAvailableExtrasQuery({
    variables: {
      requestParams: {
        ticketCode: flBooking?.outbound?.ticket?.ticketCode as string,
        journeyCode: journeyCode,
        bookingCode: bookingCode as string,
        departureDate: flBooking?.outbound?.departureDate as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.outbound?.departureCode as string,
        destinationPort: flBooking?.outbound?.arrivalPortInfo?.portCode as string,
        hasVehicles: flBooking?.outbound?.vehicles && flBooking?.outbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.outbound?.passengers?.[0].customerCode &&
          flBooking?.outbound?.passengers[0].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.outbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.outbound?.vesselCode,
      },
    },
    skip:
      flBooking?.isOngoing ||
      flBooking?.upgradeOptions?.canBuyBookingItems === false ||
      !allowUpgrade ||
      routeExpectLoading ||
      journeyCode === undefined ||
      flBooking?.outbound?.departureCode === undefined ||
      flBooking?.outbound?.departurePortInfo?.carresPortCode === undefined ||
      flBooking.outbound.ticket?.ticketCode === undefined,
  })

  const attatchedExtrasAshoreCodes = useMemo(
    () => flBooking?.outbound?.extrasAshore?.filter((extra) => extra.isAttachment === true)?.map((extra) => extra.code),
    [flBooking?.outbound?.extrasAshore],
  )

  const attatchedExtrasOnboardCodes = useMemo(
    () =>
      flBooking?.outbound?.extrasOnboard?.filter((extra) => extra.isAttachment === true)?.map((extra) => extra.code),
    [flBooking?.outbound?.extrasOnboard],
  )

  const concatCodes = useMemo(
    () =>
      attatchedExtrasAshoreCodes
        ?.concat((attatchedExtrasOnboardCodes as string[]) ?? [])
        .concat(
          data?.extrasAvailability.availableExtrasAshore
            .map((e) => e.code)
            .concat(data?.extrasAvailability.availableExtrasOnboard.map((e) => e.code)) as string[],
        ),
    [
      attatchedExtrasAshoreCodes,
      attatchedExtrasOnboardCodes,
      data?.extrasAvailability.availableExtrasAshore,
      data?.extrasAvailability.availableExtrasOnboard,
    ],
  ) as string[]
  const { multipleExtrasFromSanity } = useFetchExtrasMultipleExtras({ code: concatCodes })
  const { i18n } = useTranslation()

  const createAttatchedOnboardWithName = attatchedExtrasOnboardCodes?.map((code) => {
    return {
      code: code,
      name: multipleExtrasFromSanity?.find((e) => e.extraType === code)?.extraName[i18n.language],
    }
  })

  const createAttatchedAshoreWithName = attatchedExtrasAshoreCodes?.map((code) => {
    return {
      code: code,
      name: multipleExtrasFromSanity?.find((e) => e.extraType === code)?.extraName[i18n.language],
    }
  })

  const extraAshoreproducts = data?.extrasAvailability.availableExtrasAshore.map((a) => {
    return {
      ...a,
      name: multipleExtrasFromSanity?.find((e) => e.extraType === a.code)?.extraName[i18n.language],
    }
  })

  const extraOnboardproducts = data?.extrasAvailability.availableExtrasOnboard.map((a) => {
    return {
      ...a,
      name: multipleExtrasFromSanity?.find((e) => e.extraType === a.code)?.extraName[i18n.language],
    }
  })

  function filterExtras(availableFromGQL, attatchedItems) {
    if (!attatchedItems || (attatchedItems && attatchedItems.length === 0)) return availableFromGQL
    return availableFromGQL?.filter(
      (availableItem) =>
        !attatchedItems.some(
          (filterItem) =>
            filterItem.code === availableItem.code ||
            availableItem.name === filterItem.name ||
            availableItem.code.slice(0, -1) === filterItem.code,
        ),
    )
  }

  const hasAttatchedExtrasAshore =
    flBooking?.outbound?.extrasAshore?.filter((extra) => extra.isAttachment === true) &&
    flBooking?.outbound?.extrasAshore?.filter((extra) => extra.isAttachment === true)?.length > 0
  const hasAttatchedExtrasOnboard =
    flBooking?.outbound?.extrasOnboard?.filter((extra) => extra.isAttachment === true) &&
    flBooking?.outbound?.extrasOnboard?.filter((extra) => extra.isAttachment === true)?.length > 0

  const outboundExtrasAshore = hasAttatchedExtrasAshore
    ? filterExtras(extraAshoreproducts, createAttatchedAshoreWithName)
    : data?.extrasAvailability.availableExtrasAshore
  const outboundExtrasOnboard = hasAttatchedExtrasOnboard
    ? filterExtras(extraOnboardproducts, createAttatchedOnboardWithName)
    : data?.extrasAvailability.availableExtrasOnboard

  return { outboundExtrasAshore, outboundExtrasOnboard, loading, refetch }
}

export default UseGetOutboundExtras
