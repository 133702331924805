import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { Feedback, MenuDialog } from '@fjordline/styles-v3'
import { useTranslation } from 'react-i18next'

type props = {
  modifyBookingDeadline?: string
}

const ModifyBookingDeadlineTimer = ({ modifyBookingDeadline }: props) => {
  const [isVisible, setIsVisible] = useState(false)
  const [timeLeft, setTimeLeft] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    //The actual check for the deadline is done in the isPastDeadline.ts file in utils folder. This is just a timer to show the user how much time is left.

    //Substracting 5 minutes from the countdown to give the users enough time to actually pay etc.
    const deadline = moment.tz(modifyBookingDeadline, 'Europe/Oslo')
    const interval = setInterval(() => {
      const now = moment.tz('Europe/Oslo')
      const diff = deadline.diff(now, 'minutes')
      const diffDays = deadline.diff(now, 'days')

      if (diffDays <= 7 && diff > 0) {
        setIsVisible(true)
        const duration = moment.duration(deadline.diff(now))
        let hoursTranslation = 't'
        let daysTranslations = ' days'

        if (i18n.language === 'en') {
          hoursTranslation = 'h'
          daysTranslations = ' days'
          if (duration.days() === 1) {
            daysTranslations = ' day'
          }
        }

        if (i18n.language === 'de') {
          hoursTranslation = 'st'
          daysTranslations = ' Tage'
          if (duration.days() === 1) {
            daysTranslations = ' Tag'
          }
        }

        if (i18n.language === 'da') {
          daysTranslations = ' dage'

          if (duration.days() === 1) {
            daysTranslations = ' dag'
          }
        }

        if (i18n.language === 'nb') {
          daysTranslations = ' dager'
          if (duration.days() === 1) {
            daysTranslations = ' dag'
          }
        }

        const days = duration.days() > 0 ? `${duration.days()}${daysTranslations}` : ''
        const hours = duration.hours() > 0 && duration.days() <= 1 ? `${duration.hours()}${hoursTranslation}` : ''
        const minutes =
          duration.minutes() > 0 && duration.days() === 0 && duration.hours() <= 1 ? `${duration.minutes()}m` : ''
        const seconds =
          duration.seconds() > 0 && duration.days() === 0 && duration.hours() <= 1 ? `${duration.seconds()}s` : ''

        setTimeLeft(`${days} ${hours} ${minutes} ${seconds} ${i18n.language === 'de' ? 'ab' : ''}`)
      } else if (diff <= 0) {
        setIsVisible(false)
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [i18n.language, modifyBookingDeadline])

  if (!isVisible) {
    return null
  }

  return (
    <>
      <div style={{ marginBottom: '1rem', cursor: 'pointer' }} onClick={() => setOpenModal(!openModal)}>
        <Feedback variant="notification" type="info">
          {t('modificationDeadline.timerText')} <b>{timeLeft}</b>
          <div style={{ display: 'flex' }}>
            <p style={{ fontWeight: '500', textDecoration: 'underline' }}>{t('modificationDeadline.seeWhatCloses')}</p>
          </div>
        </Feedback>
      </div>

      {openModal && (
        <MenuDialog label="" onRequestClose={() => setOpenModal(!openModal)}>
          <h2>{t('modificationDeadline.modalHeadline')}</h2>
          <ul>
            <li>{t('component.extras.meal.add')}</li>
            <li>
              {t('component.extras.cabin.addCabin')}/{t('component.extras.cabin.title_seat').toLowerCase()}
            </li>

            <li>{t('label.button.addMoreExtraOnboard')}</li>
            <li>{t('label.button.addMoreExtraAshore')}</li>
            <li>{t('label.traveler.edit')}</li>
            <li>{t('label.vehicle.edit')}</li>
          </ul>
        </MenuDialog>
      )}
    </>
  )
}

export default ModifyBookingDeadlineTimer
