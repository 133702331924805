import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { Banner, Boat, Button, FormElementWrapper } from '@fjordline/styles-v3'

import { CDN_IMG_BOOK_NEXT_TRIP, FL_BOOKING_URL } from '../../config'

export default function OrderNextJourneyBannerOrButton({
  showBanner = false,
}: {
  showBanner?: boolean
}): JSX.Element | null {
  const { t, i18n } = useTranslation()

  if (showBanner) {
    return (
      <FormElementWrapper>
        <Banner
          target={() => (window.location.href = `${FL_BOOKING_URL}?lang=${i18n.language}`)}
          label={`${t('label.booking.makeBooking')}`}
          message=""
          image={{
            src: CDN_IMG_BOOK_NEXT_TRIP,
            alt: `${t('label.booking.makeBooking')}`,
          }}
          enableClickEverywhere
        />
      </FormElementWrapper>
    )
  } else {
    return (
      <Button
        theme="primary"
        leadingIcon={Boat}
        size="medium"
        onClick={() => (window.location.href = `${FL_BOOKING_URL}?lang=${i18n.language}&status=newBooking`)}
      >
        {t('label.booking.makeBooking')}
      </Button>
    )
  }
}
