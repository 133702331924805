import React from 'react';

type StringWithComponentsProps = {
  inputString: string;
  components: { [key: string]: React.ElementType };
};

const StringWithComponents = ({ inputString, components }: StringWithComponentsProps) => {
  const regex = /<(\w+)>(.*?)<\/\1>/g;
  const parts: (string | React.ReactNode)[] = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(inputString)) !== null) {
    const [fullMatch, tag, content] = match;
    const index = match.index;

    // Add text before the match
    if (index > lastIndex) {
      parts.push(inputString.slice(lastIndex, index));
    }

    // Add the component
    const Component = components[tag];
    if (Component) {
      parts.push(<Component key={index}>{content}</Component>);
    } else {
      parts.push(fullMatch);
    }

    lastIndex = index + fullMatch.length;
  }

  // Add remaining text after the last match
  if (lastIndex < inputString.length) {
    parts.push(inputString.slice(lastIndex));
  }

  return <span>{parts}</span>;
};

export default StringWithComponents;