import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HubConnection } from '@microsoft/signalr'
import { v4 as uuidv4 } from 'uuid'

import { logDev } from '../../../../../components/LogDev'
import { FlCustomer } from '../../../../../graphql'
import { MpCart } from '../../../../../types/myPage/MpCart'
import { useMyPageOperations } from '../../../context'
import signalREndpoints from '../../signalREndpoints'
import { useWebSocketContext } from '../../websocketContext'
import { WebsocketErrorReason } from '../subscribeToExceptionEvents'

import { mpCart } from './context'
import { trackError } from '../../../../TelemetryProvider'

export type InvokeUpdateResult = {
  status: string
  message?: string
  error?: string
}

type InvokeUpdateCart = ({ connection, mpCart }: { connection: HubConnection | null; mpCart: mpCart }) => void
type InvokeUpdateFlCustomer = ({
  connection,
  fjordClubMembership,
}: {
  connection: HubConnection | null
  fjordClubMembership: FlCustomer
}) => Promise<InvokeUpdateResult>

type UseMpCartFunctions = () => {
  invokeUpdateCart: InvokeUpdateCart
  invokeUpdateFlCustomer: InvokeUpdateFlCustomer
  invokeCreateFlCustomer: InvokeUpdateFlCustomer
}

const useCartFunctions: UseMpCartFunctions = () => {
  const { t } = useTranslation()
  const { pageDispatchToast } = useMyPageOperations()
  const { cartData, setCartData } = useWebSocketContext()

  const invokeUpdateCart: InvokeUpdateCart = useCallback(
    ({ connection, mpCart }) => {
      if (connection !== null && setCartData !== null) {
        if (!mpCart?.id) {
          mpCart.id = uuidv4()
        }

        connection
          .invoke<MpCart>(signalREndpoints.updateCart, mpCart)
          .then(() => {
            if (mpCart?.id) {
              logDev('(#12)updateCart invoked. waiting for CartUpdated', mpCart)
              const newTemporaryData = {
                ...cartData,

                id: mpCart?.id,
                timestamp: Date.now(),
              }
              // this will set the cartData in the state,
              // but the state will be overwritten by the websocket on(cartUpdated) event once price is updated
              // thus newTemporaryData
              setCartData(newTemporaryData)
            } else {
              logDev('(#14) NEW updateCart invoked. waiting for CartUpdated', mpCart)
            }
          })
          .catch((error) => {
            logDev('(#8)updateCart error', error)
            trackError('updateCart', error)
            pageDispatchToast({
              message: t(`cartException.${WebsocketErrorReason.Known}`, 'websocket communication exception'),
            })
          })
      }
    },
    [cartData, pageDispatchToast, setCartData, t],
  )

  const invokeUpdateFlCustomer: InvokeUpdateFlCustomer = useCallback(
    ({ connection, fjordClubMembership }) => {
      if (connection !== null && setCartData !== null) {
        return connection
          .invoke<FlCustomer>(signalREndpoints.updateFlCustomer, fjordClubMembership)
          .then(() => {
            logDev('(#23) NEW updateCustomer invoked. waiting for FlCustomerUpdated', fjordClubMembership)
            return Promise.resolve<InvokeUpdateResult>({ status: 'ok' })
          })
          .catch((error: string) => {
            logDev('(#24)updateCart error', error)
            pageDispatchToast({
              message: t(`updateFlCustomer.${WebsocketErrorReason.Known}`, 'websocket communication exception'),
            })
            return Promise.reject<InvokeUpdateResult>({ status: 'error', message: 'updateFlCustomer failed', error })
          })
      }
      return Promise.reject<InvokeUpdateResult>({ status: 'error', message: 'no connection', error: '' })
    },
    [pageDispatchToast, setCartData, t],
  )

  const invokeCreateFlCustomer: InvokeUpdateFlCustomer = useCallback(
    ({ connection, fjordClubMembership }) => {
      if (connection !== null && setCartData !== null) {
        return connection
          .invoke<FlCustomer>(signalREndpoints.createFlCustomer, fjordClubMembership)
          .then(() => {
            logDev('(#23) NEW updateCustomer invoked. waiting for FlCustomerUpdated', fjordClubMembership)
            return Promise.resolve<InvokeUpdateResult>({ status: 'ok' })
          })
          .catch((error: string) => {
            logDev('(#24)updateCart error', error)
            pageDispatchToast({
              message: t(`updateFlCustomer.${WebsocketErrorReason.Known}`, 'websocket communication exception'),
            })
            return Promise.reject<InvokeUpdateResult>({ status: 'error', message: 'Create customer failed', error })
          })
      }
      return Promise.reject<InvokeUpdateResult>({ status: 'error', message: 'no connection', error: '' })
    },
    [pageDispatchToast, setCartData, t],
  )

  return {
    invokeUpdateCart,
    invokeUpdateFlCustomer,
    invokeCreateFlCustomer,
  }
}

export default useCartFunctions
