import React from 'react'
import RemovedCabins from './RemovedCabins'
import { mpCart } from '../../../providers/myPageStateProvider/websocketProvider/websocketProviderFunctions/WebsocketOperationsProvider/context'
import { useTranslation } from 'react-i18next'

type Props = {
  cartData: mpCart
  bookingCode: string
}

const RemoveCabinsContainer: React.FC<Props> = ({ cartData, bookingCode }) => {
  const { t } = useTranslation()

  const hasCabinsOutbound = cartData?.bookingCarts?.[bookingCode || '']?.outbound?.cabins?.some(
    (item) => item.quantityInCabin < 0 && item.rowNumber,
  )

  const hasCabinssInbond = cartData?.bookingCarts?.[bookingCode || '']?.inbound?.cabins?.some(
    (item) => item.quantityInCabin < 0 && item.rowNumber,
  )

  const hasCabinsInCart = hasCabinsOutbound || hasCabinssInbond

  return hasCabinsInCart ? (
    <div style={{ background: '#F2F2F2', padding: '5px' }}>
      <h3 style={{ margin: 0 }}>{t('component.extras.cabin.oldCabins_basket')}</h3>

      {hasCabinsOutbound &&
        cartData?.bookingCarts?.[bookingCode || '']?.outbound?.cabins?.map((item) => {
          if (item.quantityInCabin < 0 && item.rowNumber)
            return (
              <RemovedCabins
                data-cy={`removed-cabin-outbound-${item.code}`}
                key={item.id}
                code={item.code}
                bookingCode={bookingCode || ''}
                rowNumber={item.rowNumber}
                isOutbound={true}
              />
            )
        })}
      {hasCabinssInbond &&
        cartData?.bookingCarts?.[bookingCode || '']?.inbound?.cabins?.map((item) => {
          if (item.quantityInCabin < 0 && item.rowNumber)
            return (
              <RemovedCabins
                data-cy={`removed-cabin-inbound-${item.code}`}
                key={item.id}
                code={item.code}
                bookingCode={bookingCode || ''}
                rowNumber={item.rowNumber}
                isOutbound={false}
              />
            )
        })}
    </div>
  ) : null
}

export default RemoveCabinsContainer
