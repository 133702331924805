import React from 'react'
import IconProps from '@fjordline/styles-v3/dist/icons/types/IconProps'

export type SectionProps = {
  title: string
  Icon: React.FunctionComponent<IconProps> | null
  classNames?: string
  children?: React.ReactNode
}

export const Section: React.FC<SectionProps> = ({ title, Icon, classNames, children, ...rest }) => {
  return (
    <section className={`p-XL bg-[#F7F7F8] rounded-xl my-5 border-1 border-[#D7D8E0] ${classNames}`} {...rest}>
      <h3 className="flex flex-row items-center mb-5 gap-3">
        {Icon ? <Icon width={26} height={26} /> : null}
        {title}
      </h3>
      <div className='flex flex-col gap-6'>
        {children}
      </div>
    </section>
  )
}

export type SectionCardProps = {
  children?: React.ReactNode
}

export const SectionCard: React.FC<SectionCardProps> = ({ children }) => (
  <div className="shadow-sm rounded-xl border-1 border-[#D7D8E0] bg-white p-XL">{children}</div>
)
