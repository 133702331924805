import React, { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EditInput, Feedback, FormElementWrapper, HeaderWithIcon, Spinner, Travelers } from '@fjordline/styles-v3'

import { Passenger } from '../../graphql/types'
import { BookingTravelerNavLink } from '../../routes/navLinkFunctions'
import { MayBe } from '../../types/MayBe'
import moment from 'moment/min/moment-with-locales'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { isPastDeadline } from '../../utils/isPastDeadline'
import { QueryParamsEnum } from '../../hooks/useQueryParams'
import { SkeletonEditInputContainer } from './SectionVehicles'
import Skeleton from 'react-loading-skeleton'

import { Section } from './SectionComponent'

/***
 * @description - Render passengers available, else only the header if showHeaderEvenIfNoVehicles is true
 * @param isFuture
 * @param passengers
 * @param showHeaderEvenIfNoVehicles - hide header if no passengers
 * @constructor
 */
export default function SectionPassengers({
  isFuture,
  passengers,
  showHeaderEvenIfNoVehicles = false,
}: {
  isFuture: boolean
  passengers: Passenger[]
  showHeaderEvenIfNoVehicles?: boolean
}): JSX.Element {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { flBooking } = useBookingDetails()
  const translations = useMemo(() => {
    return {
      firstName: t('label.personalia.firstName'),
      lastName: t('label.personalia.lastName'),
      dateOfBirth: t('label.personalia.dateOfBirth'),
      memberNumber: t('label.membership.memberNumber'),
    }
  }, [t])

  const params = new URLSearchParams(location.search)
  const isUpdatingParam = params.get(QueryParamsEnum.isUpdatingPassengers)
  const updatingBooking = isUpdatingParam !== null

  return (
    <Section title={t('label.traveler.header')} Icon={Travelers} data-cy="section-passengers" classNames={showHeaderEvenIfNoVehicles || passengers.length > 0 ? '' : 'hidden'}>
      {updatingBooking && (
        <div style={{ marginBottom: '1rem' }}>
          <Feedback type="info" variant="notification" showIcon>
            {t('component.general.updatingBooking')} <Spinner />
          </Feedback>{' '}
        </div>
      )}

      {passengers.length > 0
        ? passengers.map((passenger: Passenger) => {
            const birthDateUTC = moment.utc(
              `${passenger.birthYear}-${passenger.birthMonth}-${passenger.birthDay}`,
              'YYYY-MM-DD',
            )
            const formattedDate = birthDateUTC.locale(i18n.language).format('D. MMMM YYYY')
            const rowNumber = passenger.rowNumber

            if (updatingBooking && rowNumber === parseInt(isUpdatingParam)) {
              return (
                <SkeletonEditInputContainer key={`${passenger.rowNumber}`}>
                  <Skeleton width={100} height={20} />
                  <Skeleton height={20} style={{ marginBottom: '1rem' }} />
                  <Skeleton width={100} height={20} />
                  <Skeleton height={20} style={{ marginBottom: '1rem' }} />
                  <Skeleton width={100} height={20} />
                  <Skeleton height={20} style={{ marginBottom: '1rem' }} />
                </SkeletonEditInputContainer>
              )
            }

            return (
              <FormElementWrapper key={passenger.rowNumber}>
                <EditInput
                  data-cy={`edit-passenger-${passenger.rowNumber}`}
                  onClick={() => navigate(BookingTravelerNavLink({ rowNumber: passenger.rowNumber }).substring(1))}
                  disabled={
                    updatingBooking ||
                    !isFuture ||
                    isPastDeadline({ modifyBookingDeadline: flBooking?.modificationDeadline })
                  }
                  texts={[
                    {
                      key: MayBe.orElse(passenger.firstName || null, 'enKey'),
                      label: translations.firstName,
                      text: MayBe.orElse(passenger.firstName ?? null, translations.firstName),
                    },
                    {
                      key: MayBe.orElse(passenger.lastName || null, 'enKey'),
                      label: translations.lastName,
                      text: MayBe.orElse(passenger.lastName ?? null, translations.lastName),
                    },
                    {
                      label: translations.dateOfBirth,
                      text: formattedDate,
                    },
                    {
                      key: MayBe.orElse(passenger.customerCode || null, 'enKey'),
                      label: passenger.customerCode ? translations.memberNumber : '',
                      text: passenger.customerCode ? passenger.customerCode : undefined,
                    },
                  ]}
                />
              </FormElementWrapper>
            )
          })
        : null}
    </Section>
  )
}
