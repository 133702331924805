import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import { BackButton } from '@fjordline/styles-v3'
import styled from 'styled-components'

import { paramKeys } from '../routes/navLinkfunctions/types'

type CustomBackButtonArgs = {
  defaultBackLinkTo: string
  disabled?: boolean
  onClick?: React.MouseEventHandler
  title?: string
}

const MoveLeft = styled.div`
  display: flex;

  svg {
    display: block!important;
  }
`

const WithMpLoading = styled.div`
  display: flex;

  > div {
    padding-left: 1rem;
  }
`

/**
 * @description - Returns BackButton or a BackButton wrapped in a Link from @fjordline/styles-v3.
 *                If 'backTarget' or 'onClick' has value, else null.
 *                - 'backTarget' query param overrides defaultBackLinkTo.
 *                - 'backTargetTitle' query param overrides title.
 *                - Existing query params (excluding 'backTarget' and 'backTargetTitle') are preserved.
 * @param defaultBackLinkTo - The target for a Link if no 'backTarget' query param is set.
 * @param title - Default title is 'Back'.
 * @param onClick - Action to perform when clicking the link.
 * @param disabled - If true, renders a disabled back button without Link.
 * @constructor
 */
const CustomBackButton = ({ defaultBackLinkTo, title, onClick, disabled = false }: CustomBackButtonArgs) => {
  const { t } = useTranslation()
  const [params, setParams] = useSearchParams()
  const location = useLocation()

  // Extract 'backTarget' and 'backTargetTitle' from query params
  const backTarget = params.get(paramKeys.BACK_TARGET)
  const backTargetTitle = params.get(paramKeys.BACK_TARGET_TITLE)

  // Determine the target link and title
  const linkTo = backTarget || defaultBackLinkTo
  const defaultTitle = useMemo(() => title || t('label.button.back'), [title, t])
  const backTitle = backTargetTitle || title || defaultTitle

  useEffect(() => {
    if (backTarget || backTargetTitle) {
      // Create a new URLSearchParams instance to manipulate query params
      const newParams = new URLSearchParams(location.search)

      // Remove 'backTarget' and 'backTargetTitle' to preserve other query params
      newParams.delete(paramKeys.BACK_TARGET)
      newParams.delete(paramKeys.BACK_TARGET_TITLE)

      setParams(newParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backTarget, backTargetTitle])

  // If no linkTo and no onClick, return null
  if (!linkTo && !onClick) {
    return null
  }

  // Prepare preserved query parameters (excluding 'backTarget' and 'backTargetTitle')
  const preservedParams = new URLSearchParams(location.search)
  preservedParams.delete(paramKeys.BACK_TARGET)
  preservedParams.delete(paramKeys.BACK_TARGET_TITLE)
  const preservedSearch = preservedParams.toString()

  // Construct the full link with preserved query parameters
  const fullLinkTo = preservedSearch ? { pathname: linkTo, search: `?${preservedSearch}` } : linkTo

  return (
    <MoveLeft>
      {!disabled && linkTo ? (
        <Link to={fullLinkTo}>
          <WithMpLoading>
            <BackButton title={backTitle}>{backTitle}</BackButton>
          </WithMpLoading>
        </Link>
      ) : (
        <BackButton disabled={disabled} onClick={onClick} title={backTitle}>
          {backTitle}
        </BackButton>
      )}
    </MoveLeft>
  )
}

export default CustomBackButton
