import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  CarNormal,
  Colors,
  EditInput,
  EditInputText,
  Feedback,
  FormElementWrapper,
  HeaderWithIcon,
  rem,
  Spinner,
} from '@fjordline/styles-v3'
import { TFunction } from 'i18next'

import { VehicleFareCode } from '../../graphql/types'
import { Vehicle } from '../../graphql/types'
import { BookingVehicleNavLink } from '../../routes/navLinkFunctions'
import { MayBe } from '../../types/MayBe'
import { isPastDeadline } from '../../utils/isPastDeadline'
import { useBookingDetails } from '../../providers/BookingDetailsProvider'
import { QueryParamsEnum } from '../../hooks/useQueryParams'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

import { Section } from './SectionComponent'

type getTextsParam = { t: TFunction<string>; length: number; code: string; regNum: string }

/**
 * @description - Render vehicles available, else only the header
 * @param bookingCode
 * @param vehicles - lost of vehicles to render
 * @param isActiveOrFuture - disable edit if not in future
 * @param showHeaderEvenIfNoVehicles - hide header if no vehicles
 * @constructor
 */
export default function SectionVehicles({
  bookingCode,
  vehicles,
  isActiveOrFuture,
  showHeaderEvenIfNoVehicles = false,
}: {
  bookingCode: string
  vehicles: Vehicle[]
  isActiveOrFuture: boolean
  showHeaderEvenIfNoVehicles?: boolean
}): JSX.Element | null {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { flBooking } = useBookingDetails()

  const params = new URLSearchParams(location.search)
  const isUpdatingParam = params.get(QueryParamsEnum.isUpdatingRegNum)
  const updatingBooking = isUpdatingParam !== null

  return (
    <Section title={t('label.vehicle.header')} Icon={CarNormal} data-cy="section-vehicles" classNames={showHeaderEvenIfNoVehicles || vehicles.length > 0 ? '' : 'hidden'}>
      {updatingBooking && (
        <div style={{ marginBottom: '' }}>
          <Feedback type="info" variant="notification" showIcon>
            {t('component.general.updatingBooking')} <Spinner />
          </Feedback>{' '}
        </div>
      )}

      {vehicles.length > 0
        ? vehicles.map((vehicle: Vehicle) => {
            const length = Math.round(MayBe.orElse(vehicle.length ?? null, 0))
            const code = MayBe.orElse(vehicle.code ?? null, '')
            const regNum = MayBe.orElse(vehicle.regNum ?? null, '')
            const rowNumber = vehicle.rowNumber

            if (updatingBooking && rowNumber === parseInt(isUpdatingParam)) {
              return (
                <SkeletonEditInputContainer key={`${bookingCode}-${vehicle.rowNumber}`}>
                  <Skeleton width={100} height={20} />
                  <Skeleton height={20} style={{ marginBottom: '1rem' }} />
                  <Skeleton width={100} height={20} />
                  <Skeleton height={20} />
                </SkeletonEditInputContainer>
              )
            }

            return (
              <FormElementWrapper key={`${bookingCode}-${vehicle.rowNumber}`}>
                {vehicle.code !== VehicleFareCode.Bik && (regNum === '' || regNum === 'TBA') ? (
                  <div style={{ marginBottom: '0.5rem' }}>
                    {' '}
                    <Feedback variant="feedback" type="error">
                      {t('validation.missingRegNum')}
                    </Feedback>
                  </div>
                ) : null}
                <EditInput
                  data-cy={`edit-vehicle-${vehicle.rowNumber}`}
                  onClick={() => navigate(BookingVehicleNavLink({ rowNumber: vehicle.rowNumber }).substring(1))}
                  disabled={
                    updatingBooking ||
                    code === 'BIK' ||
                    !isActiveOrFuture ||
                    isPastDeadline({ modifyBookingDeadline: flBooking?.modificationDeadline })
                  }
                  texts={getTexts({ t, length, code, regNum })}
                />
              </FormElementWrapper>
            )
          })
        : null}
    </Section>
  )
}

function getTexts({ t, length, code, regNum }: getTextsParam): EditInputText[] {
  const texts: EditInputText[] = [
    {
      label: code !== 'BIK' ? t('label.vehicle.category') : '',
      text: getVehicleCodeTranslation(code as VehicleFareCode, t),
    },
    {
      label: code.includes('FL') ? t('label.vehicle.length') : '',
      text: code.includes('FL') ? `${length} m` : '',
    },
  ]

  if (code !== 'BIK') {
    texts.push({
      label: t('label.vehicle.regNum'),
      text: regNum === 'TBA' ? '' : regNum,
    })
  }

  return texts
}

function getVehicleCodeTranslation(code: VehicleFareCode, t): string {
  switch (code) {
    case VehicleFareCode.Bik: {
      return t('label.vehicle.code.bik')
    }
    case VehicleFareCode.FlVehicle_195Cm: {
      return t('label.vehicle.code.vehicle195')
    }

    case VehicleFareCode.FlVehicle_220Cm: {
      return t('label.vehicle.code.vehicle220')
    }

    case VehicleFareCode.FlVehicle_235Cm: {
      return t('label.vehicle.code.vehicle235')
    }

    case VehicleFareCode.FlVehicle_450Cm: {
      return t('label.vehicle.code.vehicle450')
    }

    case VehicleFareCode.Mc: {
      return t('label.vehicle.code.mc')
    }

    case VehicleFareCode.Mcs: {
      return t('label.vehicle.code.mcs')
    }

    default: {
      return code
    }
  }
}

export const SkeletonEditInputContainer = styled.div`
  box-shadow: inset 0 0 0 1px ${Colors.GRAY};
  min-height: 85px;
  pointer-events: none;
  padding: ${rem(40)} ${rem(30)};
  border-radius: 5px;
  background-color: ${Colors.WHITE};
  color: ${Colors.BLACK};
  margin-top: 1rem;
  margin-bottom: 1rem;
`
