import { routerBookingSubPaths, routerFjordClubSubPaths, routerRootPaths } from './appRoutes/routerPaths'

export const navLinks = {
  ADD_EXTRAS_ASHORE: routerBookingSubPaths.ADD_EXTRAS_ASHORE,
  ADD_EXTRAS_ONBOARD: routerBookingSubPaths.ADD_EXTRAS_ONBOARD,
  ADD_MEALS: routerBookingSubPaths.ADD_MEALS,
  BOARDING_CARDS: routerBookingSubPaths.BOARDING_CARDS,
  BOOKING_LIST: routerRootPaths.BOOKING_LIST,
  CABINS: routerBookingSubPaths.EDIT_CABINS,
  CART: routerBookingSubPaths.CART,
  EDIT_MEMBERSHIP: `${routerRootPaths.FJORD_CLUB}/${routerFjordClubSubPaths.EDIT_MEMBERSHIP}`,
  FJORD_CLUB: routerRootPaths.FJORD_CLUB,
  FJORD_CLUB_TRAVELER: `${routerRootPaths.FJORD_CLUB}/${routerFjordClubSubPaths.TRAVELER}`,
  LOGIN: routerRootPaths.LOGIN,
  PAYMENT: routerBookingSubPaths.PAYMENT,
  ROOT: '/',
  SIGN_UP: `${routerRootPaths.FJORD_CLUB}/${routerFjordClubSubPaths.SIGN_UP_ADD}`,
  SIGN_UP_ADD: `${routerRootPaths.FJORD_CLUB}/${routerFjordClubSubPaths.SIGN_UP_ADD}`,
  TICKETS: routerBookingSubPaths.TICKETS,
  VERIFY_EMAIL: routerRootPaths.VERIFY_EMAIL,
  PayWithPoints: routerRootPaths.PAY_WITH_CLUB_POINTS,
  SUPPORT: routerRootPaths.SUPPORT
}
