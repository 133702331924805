import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export enum PAID_STATE {
  NOTHING_PAID = 'NOTHING_PAID',
  PARTLY_PAID = 'PARTLY_PAID',
  FULLY_PAID = 'FULLY_PAID',
  BOOKED = 'BOOKED',
}

export enum PAYMENT_STATUS {
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
}

export enum PAYMENT_PARAMS {
  PAYMENT_STATUS = 'paymentStatus',
  BOOKING_CODE = 'bookingCode',
  PAID_STATE = 'paidState',
  AMOUNT_PAID = 'amountPaid',
  BOOKING_BALANCE = 'bookingBalance',
  REDIRECT_RESULT = 'redirectResult',
}

/**
 * @type queryParams  URLSearchParams object for current path
 * @type deleteQueryKey Delete key from query string and replace history
 */
type useQueryParamType = {
  queryParams: URLSearchParams
  lastStringInPath: string
  pathname: string
}

/**
 * @type useQueryParamType
 * Create a URLSearchParams object for current path
 * Returns two props: queryParams: URLSearchParams and deleteQueryKey
 *
 */
const useQueryParams = (): useQueryParamType => {
  const location = useLocation()
  const pathname = useMemo<string>(() => location.pathname, [location.pathname])
  const queryParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search])
  const lastStringInPath = useMemo<string>(
    () => getStringPartInPath({ path: pathname, positionFromEnd: 1 }),
    [pathname],
  )

  return { lastStringInPath, queryParams, pathname }
}

export default useQueryParams

type getBookingKeyFromPathArgumentType = {
  path: string
  positionFromEnd: number
}
type getBookingKeyFromPathType = (args: getBookingKeyFromPathArgumentType) => string

export const getStringPartInPath: getBookingKeyFromPathType = ({ path, positionFromEnd }) => {
  const parts = path.split('/')
  return parts[parts.length - positionFromEnd]
}

export const getBookingIdInPath: ({ path }) => string = ({ path }) => {
  const parts = path.split('/')
  return parts[2]
}
