import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, FormElementWrapper, HeaderWithIcon, Label, Tax } from '@fjordline/styles-v3'

import { Extra } from '../../graphql/types'

import { MinSideContainer } from './booking.styles'
import { ExtraAshoreLabel } from './sectionExtrasAshore/extrasAshore/extrasAshore.styles'

import { Section, SectionCard } from './SectionComponent'

export default function SectionTaxesAndFees({
  inboundEtax,
  outboundEtax,
}: {
  inboundEtax: Extra | null
  outboundEtax: Extra | null
}): JSX.Element | null {
  const { t } = useTranslation()

  return (
    <Section title={t('component.extras.taxesAndFees.headline')} Icon={Tax} data-cy="section-taxesAndFees">
      {outboundEtax ? (
        <SectionCard>
          <article data-cy="extras-onboard">
            <h4 className='font-semibold tracking-wide mb-5'>{t('component.extras.common.departure')}</h4>

            <p>{t('component.extras.taxesAndFees.envTax')}</p>
            <span className='text-sm'>{t('component.extras.misc.qty', { count: outboundEtax.quantity })}</span>
          </article>
        </SectionCard>
      ): null}

      {inboundEtax ? (
        <SectionCard>
          <article data-cy="extras-onboard">
            <h4 className='font-semibold tracking-wide mb-5'>{t('component.extras.common.arrival')}</h4>

            <p>{t('component.extras.taxesAndFees.envTax')}</p>
            <span className='text-sm'>{t('component.extras.misc.qty', { count: inboundEtax.quantity })}</span>
          </article>
        </SectionCard>
      ): null}
    </Section>
  )
}
