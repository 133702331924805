import React from 'react'
import { TiWarning } from "react-icons/ti"
import { styled } from "styled-components"

const ValidationWrapper = styled.div`
  display: flex;
  margin-top: 1.5em;
`

const ValidationHeader = styled.div`
  background: #C31230;
  color: #ffffff;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;

  > svg {
    display: block!important;
    font-size: 1.5em;
  }
`

const ValidationList = styled.ul`
  padding: 1em 2em;
  margin: 0;
  background: #FFEFF2;
  border-radius: 0 5px 5px 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1em;
`

const ValidationListItem = styled.li`
  color: #C31230;
`

type ValidationFeedbackProps = {
  errors: string[]
}

export const ValidationFeedback = ({ errors }: ValidationFeedbackProps) => {
  if (!errors || errors.length === 0) return null

  return (
    <ValidationWrapper>
      <ValidationHeader>
        <TiWarning />
      </ValidationHeader>
      <ValidationList>
        {errors.map((error) => (
          <ValidationListItem>
            {error}
          </ValidationListItem>
        ))}
      </ValidationList>
    </ValidationWrapper>
  )
}