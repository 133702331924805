import { useQuery } from '@tanstack/react-query'
import { ShipProductCode } from '@fjordline/sanity-types'
import { fetchProductCodeInfo } from '../fetchData'
import { props } from './types'

function useFetchProductCodeInfo({ productCode }: props) {
  return useQuery({
    queryKey: [productCode],
    enabled: productCode ? true : false,
    queryFn: async () => {
      if (productCode && productCode.length > 0) {
        const data = (await fetchProductCodeInfo(productCode)) as ShipProductCode
        return data
      }
    },
  })
}

export default useFetchProductCodeInfo
