import Cookies from 'js-cookie'
import { ENVIRONMENT } from '../config'

const isDevelopment = ENVIRONMENT === 'DEV'
export const FL_LNG_COOKIE_NAME = 'fl-language'

export const setLanguageCookie = (lng: string) => {
  const expirationDate = new Date()
  expirationDate.setSeconds(expirationDate.getSeconds() + 365 * 24 * 60 * 60)

  const options = {
    path: '/',
    httpOnly: false, // Allow client-side access
    sameSite: 'lax',
    expires: expirationDate,
    secure: !isDevelopment, // Secure in production
    domain: isDevelopment ? undefined : '.fjordline.com',
  }

  Cookies.set(FL_LNG_COOKIE_NAME, lng, options)
}

export const getLanguageCookie = (): string | undefined => {
  return Cookies.get(FL_LNG_COOKIE_NAME)
}
