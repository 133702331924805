/* eslint-disable quotes */
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { UAParser } from 'ua-parser-js'
import { useLocation } from 'react-router-dom'
import { FORM_RECEIVER_URL } from '../../config'
import { useKeycloak } from '../../providers/KeycloakProvider'
import { useMyPage } from '../../providers/myPageStateProvider/context'

export enum IssueType {
  MissingBookings = 'missingBookings',
  BookingDetails = 'bookingDetails',
  Other = 'other',
}

export type IssueReport = {
  issue: IssueType
  description?: string
  bookingCode?: string
  email: string
  language: string
  id: string
  shortID: string
}

export const useIssueReporter = () => {
  const { t } = useTranslation()
  const { token: kc_token } = useKeycloak()
  const { customer } = useMyPage()
  const { pathname } = useLocation()

  const IssueTypeDescription: Record<IssueType, string> = {
    [IssueType.MissingBookings]: t('component.reportIssue.issueTypes.missingBookings'),
    [IssueType.BookingDetails]: t('component.reportIssue.issueTypes.bookingDetails'),
    [IssueType.Other]: t('component.reportIssue.issueTypes.otherIssue'),
  }

  const IssueTypeDescriptionEN: Record<IssueType, string> = {
    [IssueType.MissingBookings]: t('component.reportIssue.issueTypes.missingBookings', { lng: 'en' }),
    [IssueType.BookingDetails]: t('component.reportIssue.issueTypes.bookingDetails', { lng: 'en' }),
    [IssueType.Other]: t('component.reportIssue.issueTypes.otherIssue', { lng: 'en' }),
  }

  const IssueTypeTitles: Record<IssueType, string> = {
    [IssueType.MissingBookings]: 'Missing booking',
    [IssueType.BookingDetails]: 'Incorrect/missing booking details',
    [IssueType.Other]: 'Other issue',
  }

  const generateIssueID = async (): Promise<{ guid: string; shortId: string }> => {
    const uuid = crypto.randomUUID()

    const encoder = new TextEncoder()
    const data = encoder.encode(uuid)

    // Compute the hash and return the first 6 characters of the Base64-encoded hash
    const shortId = await crypto.subtle.digest('SHA-256', data).then((hashBuffer) => {
      // Convert the hash buffer to a Base64 string
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashBase64 = btoa(String.fromCharCode(...hashArray))

      // Make the Base64 string URL- and filename-safe
      const safeBase64 = hashBase64.replace(/[+/=]/g, '')

      return safeBase64.substring(0, 6)
    })

    return {
      guid: uuid,
      shortId: shortId,
    }
  }

  const getTelemetry = async () => {
    const uap = new UAParser()
    const results = await uap.getResult().withClientHints()

    const now = new Date()

    const os =
      results.os.name === 'macOS' && results.os.version === '10.15'
        ? `${results.os.name} (${results.os.version} or greater)`
        : `${results.os.name} (${results.os.version})`

    return {
      datetime: now.toString(),
      location: pathname,
      os: os,
      browser: `${results.browser.name} (${results.browser.version})`,
      engine: `${results.engine.name} (${results.engine.version})`,
    }
  }

  const submitIssue = async (report: IssueReport) => {
    const telemetry = await getTelemetry()

    const issueType = IssueTypeTitles[report.issue]

    const message =
      `User reports: ${IssueTypeDescriptionEN[report.issue]} in MyPage<br>` +
      `${report.description ? `---<br><br>${report.description}` : 'No message was provided by the user.'}`

    const metadata = [
      {
        'schemaName': 'Telemetry',
        'schemaVersion': '1.0.0',
        'fields': {
          'Local time': telemetry.datetime,
          'Operating system': telemetry.os,
          'Browser': telemetry.browser,
          'Rendering engine': telemetry.engine,
          'Location': telemetry.location
        }
      },
      {
        'schemaName': 'Logs',
        'schemaVersion': '1.0.0',
        'fields': {
          'AppInsights ID': report.shortID
        }
      }
    ]

    return axios.post(
      FORM_RECEIVER_URL,
      {
        feedbackSource: `mypage-v3${telemetry.location}`,
        eventType: 'ExternalBugReport',
        reason: `${issueType} in MyPage`,
        message: message,
        fullName: customer ? `${customer.firstName} ${customer.lastName}` : '',
        email: report.email,
        phoneNumber: customer ? customer.phoneNumber : '',
        bookingCode: report.bookingCode ?? null,
        customerCode: customer ? customer.customerCode : null,
        language: report.language,
        additionalDetails: issueType,
        id: report.id,
        shortId: report.shortID,
        metadata: metadata
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${kc_token}`,
        },
      },
    )
  }

  return {
    IssueType: IssueType as typeof IssueType,
    IssueTypeDescription,
    submitIssue,
    generateIssueID,
  }
}
