import AdyenCheckout from '@adyen/adyen-web'
import { useEffect } from 'react'
import { BookingResult } from '@fjordline/booking-draft'
import { trackEvent } from '../../providers/TelemetryProvider'
import { useTranslation } from 'react-i18next'
import useQueryParams, { PAYMENT_PARAMS } from '../receipt/useQueryParams'
import { logDev } from '../../components/LogDev'

export const useAdyenSubmitDetails = (bookingResult?: BookingResult) => {
  const paymentConfiguration = bookingResult?.paymentConfiguration
  const { queryParams } = useQueryParams()
  const redirectResult = queryParams.get(PAYMENT_PARAMS.REDIRECT_RESULT)
  const { i18n } = useTranslation()

  let adyenLanguage

  switch (i18n.language) {
    case 'nb':
      adyenLanguage = 'no-NO'
      break
    case 'da':
      adyenLanguage = 'da-DK'
      break
    case 'de':
      adyenLanguage = 'de-DE'
      break
    default:
      adyenLanguage = 'en-US'
      break
  }

  useEffect(() => {
    let ignore = false

    if (ignore || !paymentConfiguration || !redirectResult) return

    const createCheckout = async () => {
      trackEvent(`submitDetails: ${redirectResult}`)
      logDev('submitDetails: ', redirectResult)
      const checkout = await AdyenCheckout({
        clientKey: paymentConfiguration?.clientKey,
        session: {
          id: paymentConfiguration?.sessionId,
          sessionData: paymentConfiguration?.sessionData,
        },
        locale: adyenLanguage,
        environment: paymentConfiguration?.environment,
      })
      checkout.submitDetails({ details: { redirectResult: redirectResult } })
    }

    createCheckout()

    return () => {
      ignore = true
    }
  }, [paymentConfiguration, redirectResult])
}
