import { useParams } from 'react-router-dom'

import { languageTransform } from '../../../utils/currencyConvert'
import UseGetBookingDetails from '../../customerHooks/UseGetBookingDetails'
import { AvailabilityMealItemWithSpec, Currency, useGetAvailableMealsQuery } from '../../types'
import UseRouteExpect from '../route/UseRouteExpect'
import { addCategoryToAttatchedMeals, filterMeals } from './helpers'
import { useMemo } from 'react'

function UseGetinboundMeals(addModalExtrasBookingCode?: string | undefined)
{
  const { bookingCode: bookingCodeFromParams } = useParams()
  const bookingCode = addModalExtrasBookingCode || bookingCodeFromParams
  const { flBooking } = UseGetBookingDetails({ bookingCode: bookingCode ? bookingCode : '' })
  const attatchedMeals = flBooking?.inbound?.meals?.filter((meal) => meal.isAttachment === true)
  const attatchedMealsWithCategory = addCategoryToAttatchedMeals(attatchedMeals)
  const hasAttatchedMeals = attatchedMealsWithCategory && attatchedMealsWithCategory?.length > 0

  const journeyCode = flBooking?.inbound?.journeyCode as string
  const { expectMeals, routeExpectLoading } = UseRouteExpect({
    originPort: flBooking?.inbound?.departurePortInfo?.portCode as string,
    destinationPort: flBooking?.inbound?.arrivalPortInfo?.portCode as string,
  })

  const allowUpgrade = flBooking?.upgradeOptions?.inbound?.canUpgradeMeals && expectMeals

  const { data, loading, refetch } = useGetAvailableMealsQuery({
    variables: {
      requestParams: {
        ticketCode: flBooking?.inbound?.ticket?.ticketCode as string,
        journeyCode: journeyCode,
        bookingCode: bookingCode as string,
        departureDate: flBooking?.inbound?.departureDate as string,
        currency: flBooking?.currency as Currency,
        departureCode: flBooking?.inbound?.departureCode as string,
        destinationPort: flBooking?.inbound?.arrivalPortInfo?.portCode as string,
        hasVehicles: flBooking?.inbound?.vehicles && flBooking?.inbound?.vehicles.length > 0,
        isFjordClubMember:
          flBooking?.outbound?.passengers?.[ 0 ].customerCode &&
            flBooking?.outbound?.passengers[ 0 ].customerCode.length > 0
            ? true
            : false,
        language: languageTransform(flBooking?.language as string),
        originPort: flBooking?.inbound?.departurePortInfo?.portCode as string,
        vesselCode: flBooking?.inbound?.vesselCode as string,
      },
    },
    skip:
      flBooking?.upgradeOptions?.canBuyBookingItems === false ||
      !allowUpgrade ||
      routeExpectLoading ||
      flBooking?.inbound === null ||
      journeyCode === undefined ||
      flBooking?.inbound === undefined ||
      flBooking?.inbound?.departurePortInfo?.carresPortCode === undefined ||
      flBooking.inbound.departureCode === undefined ||
      flBooking.inbound.ticket?.ticketCode === undefined,
  })

  const mealsDataFromGQL = data?.mealAvailability.availableMeals as AvailabilityMealItemWithSpec[]

  const mealsData = useMemo(
    () => (hasAttatchedMeals ? filterMeals(attatchedMealsWithCategory, mealsDataFromGQL) : mealsDataFromGQL),
    [ attatchedMealsWithCategory, hasAttatchedMeals, mealsDataFromGQL ],
  )

  return { data: mealsData as AvailabilityMealItemWithSpec[], loading, refetch }
}

export default UseGetinboundMeals
