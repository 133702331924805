import React, { JSX } from 'react'
import { useTranslation, Trans } from 'react-i18next'

type TranslationRendererProps = {
  i18nKey: string
  isArray?: boolean
  components?: { [key: string]: JSX.Element }
  ParagraphComponent?: React.ElementType
}

const TranslationRenderer: React.FC<TranslationRendererProps> = ({
  i18nKey,
  isArray = false,
  components = {},
  ParagraphComponent = React.Fragment,
}) => {
  const { t } = useTranslation()

  if (isArray) {
    const result = t(i18nKey, { returnObjects: true }) as string[]
    return (
      <>
        {result.map((paragraph, idx) => (
          <ParagraphComponent key={idx}>
            {Object.keys(components).length > 0 ? (
              <Trans i18nKey={`${i18nKey}.${idx}`} components={components} />
            ) : (
              paragraph
            )}
          </ParagraphComponent>
        ))}
      </>
    )
  }

  return (
    <ParagraphComponent>
      {Object.keys(components).length > 0 ? <Trans i18nKey={i18nKey} components={components} /> : t(i18nKey)}
    </ParagraphComponent>
  )
}

export default TranslationRenderer
